import GoogleLogin, { GoogleLoginResponse, GoogleLoginResponseOffline } from "react-google-login";
import {  useState } from "react";
import { Button, makeStyles, Avatar } from "@material-ui/core";
import { useGoogleLogin } from "../../../../mutations";
import AddToDrive from '@material-ui/icons/AssignmentInd';
import shadows from "@material-ui/core/styles/shadows";




const LOGIN_KEY = "google_login_storage";

const useStyles = makeStyles(theme => ({
    buttonGoogleDiv: {
        display: 'flex',
        flexGrow: 1,
        justifyContent: 'center',
        alignItems: 'center',
        boxShadow: "0px 3px 1px -2px rgb(166 200 243/ 20%), 0px 2px 2px 0px rgb(166 200 243 / 14%), 0px 1px 5px 0px rgb(0 0 0 / 12%)",
        backgroundColor: "#fffff", 
        marginBottom: "20px",
        marginTop: "20px",
        
    },
    buttonGoogle: {
        width: "100%"
    },
    avatarGoogle: {
        backgroundColor: "#fffff", 
        width: "100%",
        height: "20px",
    },
}))


export function GoogleButton() {


    const classes = useStyles();
    const googleLogin = useGoogleLogin();


    // const [loginData, setLoginData] = useState(
    //     () => {
    //         const data = localStorage.getItem(LOGIN_KEY);
    //         if (data !== null) {
    //             return JSON.parse(data) as  GoogleLoginResponse | GoogleLoginResponseOffline;
    //         }
    //         return null
    //     }
    // );



    const handleFailure = (result: any) => {
        console.log(JSON.stringify(result));
    };

    const handleResponse = (response: GoogleLoginResponse | GoogleLoginResponseOffline) => {
        if (response.code === undefined) {
            handleLogin(response as GoogleLoginResponse);
        }  
    };

    const handleLogin = (response: GoogleLoginResponse) => {
        console.log(response)
        localStorage.setItem(LOGIN_KEY, JSON.stringify(response))
        googleLogin.mutate({ token: response.tokenId})
    };


    if  (process.env.REACT_APP_GOOGLE_CLIENT_ID !== undefined) {
        return ( 
            <div className={classes.buttonGoogleDiv}> 
                <GoogleLogin 
                    
                    clientId={process.env.REACT_APP_GOOGLE_CLIENT_ID}
                    buttonText="Utilizar conta Google"
                    onSuccess={handleResponse}
                    onFailure={handleFailure} 
                    cookiePolicy={'single_host_origin'}
                    icon={true}
                    
                    render={({ onClick, disabled }) => (
                        <Button
                            onClick={onClick}
                            variant="outlined"
                            color="primary"
                            size="large"
                            fullWidth
                            startIcon={ <Avatar className={classes.avatarGoogle} alt="Google Google" src="https://www.clicksi.com.br/programas/google_icon.png" /> }
                        >
                            Entrar com Google
                        </Button>
                    )}
                />
            </div>
        );
    }
    return null;
}