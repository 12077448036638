import { createContext, useCallback, useContext, useEffect, useState } from 'react';
import { ProviderProps } from '../types/provider';

export type PermissionValues = 'granted' | 'prompt' | 'denied' | 'unread' | 'unknown';

export type Position = {
	latitude: number;
	longitude: number;
}

type GeopositionContextType = {
	geopositionAPIAvailable: boolean;
	permissionsAPIAvailable: boolean;
	geopositionCurrentPermission: PermissionValues;
	getPosition: () => Promise<Position>
}

const GeopositionContext = createContext({} as GeopositionContextType);

const useGeoposition = () => useContext(GeopositionContext);
export default useGeoposition;

export function GeopositionProvider({ children } : ProviderProps) {
	
	const [ geopositionAPIAvailable ] = useState('geolocation' in navigator);
    const [ permissionsAPIAvailable ] = useState('permissions' in navigator);
    const [ geopositionCurrentPermission, setGeopositionCurrentPermission ] = useState<PermissionValues>('unread');

	function updateGeopositionPermission(state: string) {
        switch(state) {
            case 'granted':
            case 'prompt':
            case 'denied':
                setGeopositionCurrentPermission(state);
                break;
            default:
                setGeopositionCurrentPermission('unknown');
        }
    }

	useEffect(() => {
        if (permissionsAPIAvailable) {
            navigator.permissions.query({ name: 'geolocation' }).then(result => {
                updateGeopositionPermission(result.state);
                result.onchange = function() {
                    updateGeopositionPermission(result.state);
                };
            })
        } else {
			updateGeopositionPermission('granted');
		}
    // eslint-disable-next-line
    }, [ ]);

	const getPosition = useCallback(() => (
		new Promise<Position>((resolve, reject) => {
			if (
				!geopositionAPIAvailable || (
					geopositionCurrentPermission !== 'granted' &&
					geopositionCurrentPermission !== 'prompt'
				)
			) {
				updateGeopositionPermission('denied');
				return reject({ error: "Impossible to find geoposition" });
			}
			navigator.geolocation.getCurrentPosition(result => {
				resolve({
					latitude: result.coords.latitude,
					longitude: result.coords.longitude,
				})
			}, (error => reject(error)))
		})
	), [ geopositionCurrentPermission, geopositionAPIAvailable ]);

	return (
	<GeopositionContext.Provider value={{
		geopositionAPIAvailable,
		permissionsAPIAvailable,
		geopositionCurrentPermission,
		getPosition 
	}}>
		{children}
	</GeopositionContext.Provider>
	);
}