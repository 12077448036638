import { ProviderProps } from "../../../../types/provider";
import { createContext, useContext, useState, useEffect, useCallback } from "react";
import { decodeBase64 } from "../../../../utils/format";
import { useLocation } from "react-router";
import { AppModeEnum } from "../../../../constants/app_mode";

type AppModeTableContent = {
    mode: AppModeEnum.TABLE;
    table: number;
}

type AppModeNormalContent = {
    mode: AppModeEnum.NORMAL,
}

type AppModeContent = AppModeTableContent | AppModeNormalContent

const useAppModeProviderState = () => {

    const [ appModeContent, setAppModeContent ] = useState<AppModeContent>({
        mode: AppModeEnum.NORMAL,
    })

    function isAppModeContent(json: any): json is AppModeContent {
        if (json.mode === undefined) {
            return false;
        }
        switch (json.mode) {
            case AppModeEnum.NORMAL:
                return true;
            case AppModeEnum.TABLE:
                if (json.table === undefined) {
                    return false
                }
                if (isNaN(json.table) || json.table < 1) {
                    return false
                }
                return true;
            default:
                return false;
        }
    }

    const location = useLocation();


    const getJSONFromURL = useCallback((): any | null => {
        try {
            if (location.hash.length <= 1) {
                return null;
            };
            const raw = location.hash;
            const jsonDecoded = decodeBase64(raw.substring(1));
            const jsonParsered = JSON.parse(jsonDecoded);

            return jsonParsered;

        } catch (error) {

            return null
        }
    }, [ location.hash ]);


    const parseAppMode = useCallback(() => {
        const json = getJSONFromURL();

        if (json === null || !isAppModeContent(json)){
            setAppModeContent({
                mode: AppModeEnum.NORMAL
            }) 
            return;
        }
        setAppModeContent(json);
    }, [ getJSONFromURL ]);

    useEffect(() => {
        parseAppMode()
    }, [
        parseAppMode,
        location.hash
    ]);

    return {
        appModeContent
    };
}

const AppModeContext = createContext( 
    {} as ReturnType<typeof useAppModeProviderState>
);
export const useAppMode = () => useContext(AppModeContext);

export function AppModeProvider({ children }: ProviderProps) {

    const {
        appModeContent
    } = useAppModeProviderState();

    return (
        <AppModeContext.Provider value={{
            appModeContent
        }}>
            {children}
        </AppModeContext.Provider>
    );
}
