import { Divider, List, makeStyles, Typography } from '@material-ui/core';
import { BagIngredient } from '../bag/types';
import { ProductMenuStateType } from './hooks';
import { ListItemCheckbox } from '../../components/ListItemCheckbox';

const useStyles = makeStyles(theme => ({
	productIngredientsSubtitle: {
		paddingLeft: theme.spacing(1),
		marginTop: theme.spacing(2)
	}
}));

type ProductIngredientsProps = {
	ingredients : BagIngredient[];
	toggle : ProductMenuStateType['toggleIngredient'];
	productIndex : number;
}

export function ProductIngredients({
    productIndex,
    ingredients,
    toggle
}: ProductIngredientsProps) {
	const classes = useStyles();


	if (ingredients.length <= 0) return null;

	return (
	<>
		<Typography
			variant="h5"
			gutterBottom
			color="textSecondary"
			className={classes.productIngredientsSubtitle}
		>
			Ingredientes inclusos
		</Typography>
		<Divider />
		<List>
			{ingredients.map((ingredient, index) => (
			<ListItemCheckbox
				key={index}
				toggle={() => toggle(productIndex, ingredient.id)}
				checked={ingredient.selected}
			>
				{ingredient.name}
			</ListItemCheckbox>
			))}
		</List>
	</>
	);
}