import Form, { InputProps } from '../components/Form';
import { BodyUpdateUserPassword, useUpdateUserPassword } from '../mutations';

const inputs: InputProps<keyof BodyUpdateUserPassword, BodyUpdateUserPassword>[] = [{
    name: 'current_password',
    type: 'current-password',
    label: 'Senha atual',
    maxChar: 30
}, {
    name: 'password',
    type: 'new-password',
    label: 'Nova senha',
    maxChar: 30
}, {
    name: 'password_confirm',
    type: 'new-password',
    label: 'Confirme a nova senha',
    maxChar: 30
}];

export default function UpdateUserPasswordForm() {
    const updateUserPassword = useUpdateUserPassword();
    return (
    <Form
        submitLabel='Alterar senha'
        mutation={updateUserPassword}
        fixedValues={{}}
        inputs={inputs}
    />
    );
}