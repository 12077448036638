import { makeStyles, TextField, TextFieldProps } from "@material-ui/core";
import clsx from "clsx";

const useStyles = makeStyles(theme => ({
    textInputContainer: {
        position: 'relative'
    },
    textInputCharacterCounter: {
        color: theme.palette.text.secondary,
        fontSize: theme.typography.caption.fontSize,
        position: 'absolute',
        right: 0,
        bottom: 0
    },
    textInputCharacterCounterError: {
        color: theme.palette.error.main
    }
}));

type TextInputProps = Omit<
    TextFieldProps,
    'onChange' | 'value'
> & {
    maxChar?: number;
    onChange: (value: string) => void;
    value: string;
}
export function TextInput({
    maxChar,
    onChange,
    value,
    error,
    ...forwardProps
}: TextInputProps) {
    const classes = useStyles();

    if (maxChar === undefined) {
        return (
        <TextField
            value={value}
            onChange={e => onChange(e.currentTarget.value)}
            {...forwardProps}
        />
        );
    }

    return (
    <div className={classes.textInputContainer}>
        <TextField
            value={value}
            onChange={e => onChange(e.currentTarget.value)}
            error={value.length > maxChar || error}
            {...forwardProps}
        />
        <span className={clsx(
            classes.textInputCharacterCounter,
            value.length >= maxChar && classes.textInputCharacterCounterError
        )}>
            {value.length}/{maxChar}
        </span>
    </div>
    );
}