import DeliveryMethod from "../../../constants/delivery_method"
import PaymentMethod from "../../../constants/payment_method"
import { useMutationWithCompany } from "../../../hooks/react-query";
import { invalidateQueries } from "../../../providers/ReactQuery";
import { ResponseMessageID } from "../../../types/domain"
import { QUERY_USER_ACTIVE_ORDERS, QUERY_USER_PREVIOUS_ORDERS, QUERY_USER_PREVIOUS_ORDERS_PAGES } from "../queries"


export type NewOrder = {
    delivery_address_id: number | null;

    need_change: boolean;
    change_for: number;

    delivery_method_id: DeliveryMethod;
    payment_method_id: PaymentMethod;

    table: number;
    note: string;

    items: {
        mixable_id: number | null;
        products: {
            id: number;
            note: string;
            amount: number | null; // send only if mixable_id is null
            exclusions: number[];
            notes: number[];
            questions: {
                id: number;
                answer_id: number;
            }[];
            extras: {
                id: number;
                amount: number;
            }[];
        }[]
    }[]

}

export const useAddOrder = () => useMutationWithCompany<NewOrder, ResponseMessageID>(
    'POST',
    (body, company_id) => ({
        url: `/store/${company_id}/order`,
        body
    }),
    {
        onSuccess: (_, { company_id }) => {
            invalidateQueries([
                QUERY_USER_PREVIOUS_ORDERS.invalidate({ company_id }),
                QUERY_USER_ACTIVE_ORDERS.invalidate({ company_id }),
                QUERY_USER_PREVIOUS_ORDERS_PAGES.invalidate({ company_id })
            ]);
        }
    }
)
