import { OrderProduct } from "../types/domain";

export const formatMoney = (value: number) => {
    if (value === 0) {
        return "Grátis!";
    }
    const str = Math.floor(value).toString().padStart(3, '0');
    return `R$ ${str.substr(0, str.length-2)},${str.substring(str.length-2, str.length)}`
}

export const decodeBase64 = (query: string) => atob(query);

export const encodeBase64 = (query: string) => btoa(query);

export const formatMinutes = (minutes : number) => (
    minutes > 60 ? `${(minutes/60).toFixed(0)}h` : `${(minutes.toFixed(0))}min`
)


export const formatPhone = (phone : string) => {
    switch(phone.length) {
        case 7:
        case 8:
        case 9:
        case 10:
            return `(${phone.substring(0,2)}) ${phone.substring(2, 6)}-${phone.substring(6, 10)}`
        case 11:
            return `(${phone.substring(0,2)}) ${phone.substring(2, 7)}-${phone.substring(7, 11)}`
        case 12:
            return `+${phone.substring(0,2)} (${phone.substring(2, 4)}) ${phone.substring(4, 8)}-${phone.substring(8, 12)}`
        case 13:
            return `+${phone.substring(0,2)} (${phone.substring(2, 4)}) ${phone.substring(4, 9)}-${phone.substring(9, 13)}`
        case 0:
            return '';
        case 1:
        case 2:
            return `(${phone})`;
        case 3:
        case 4:
        case 5:
            return `(${phone.substring(0, 2)}) ${phone.substring(2)}`;
        case 6:
            return `(${phone.substring(0, 2)}) ${phone.substring(2)}-`;
        default:
            return `+${phone.substring(0,2)} (${phone.substring(2, 4)}) ${phone.substring(4, 9)}-${phone.substring(9, 13)}`
        }
}

export const formatZipcode = (zipcode?: string) => {
    if (zipcode === undefined || zipcode.length === 0) {
        return undefined;
    } else if (zipcode.length <= 5) {
        return zipcode;
    } else if (zipcode.length <= 8) {
        return `${zipcode.substring(0, 5)}-${zipcode.substring(5, zipcode.length)}`;
    } else {
        return `${zipcode.substring(0, 5)}-${zipcode.substring(5, 8)}`;
    }
}

export const formatAddress = (
    street?: string,
    number?: string,
    district ?: string,
    city ?: string,
    state ?: string,
    complement ?: string,
    zipcode?: string
) => (
    [
        street,
        number,
        district,
        formatZipcode(zipcode),
        city,
        state,
        complement,
    ].filter(Boolean).join(', ')
)

export const formatCNPJ = (val: string) => {
    switch(val.length) {
        case 13:
        case 14:
            return `${val.substring(0, 2)}.${val.substring(2, 5)}.${val.substring(5, 8)}/${val.substring(8, 12)}-${val.substring(12, val.length)}`;
        case 11:
            return `${val.substring(0,3)}.${val.substring(3,6)}.${val.substring(6,9)}-${val.substring(9, 11)}`
        case 0:
            return '';
        case 1:
            return val;
        case 2:
            return `${val}.`;
        case 3:
        case 4:
            return `${val.substring(0, 2)}.${val.substring(2, val.length)}`;
        case 5:
            return `${val.substring(0, 2)}.${val.substring(2, 5)}.`;
        case 6:
        case 7:
            return `${val.substring(0, 2)}.${val.substring(2, 5)}.${val.substring(5, val.length)}`;
        case 8:
            return `${val.substring(0, 2)}.${val.substring(2, 5)}.${val.substring(5, 8)}/`;
        case 9:
        case 10:
            return `${val.substring(0, 2)}.${val.substring(2, 5)}.${val.substring(5, 8)}/${val.substring(8, val.length)}`;
        case 12:
            return `${val.substring(0, 2)}.${val.substring(2, 5)}.${val.substring(5, 8)}/${val.substring(8, 12)}-`;
        default:
            return `${val.substring(0, 2)}.${val.substring(2, 5)}.${val.substring(5, 8)}/${val.substring(8, 12)}-${val.substring(12, 14)}`;
        }
}

function twoDigit(value: number) {
    return ('0'+value).slice(-2);
}

export const formatTime = (timestamp: number) => {
    const date = new Date(timestamp * 1000);
    return `${twoDigit(date.getHours())}:${twoDigit(date.getMinutes())}`;
}

export const formatDateTime = (timestamp: number) => {
    const date = new Date(timestamp * 1000);
    return `${twoDigit(date.getDay())}/${twoDigit(date.getMonth())} às ${twoDigit(date.getHours())}:${twoDigit(date.getMinutes())}`;
}

export const formatOrderProductDescription = (product : OrderProduct) => (
    `${product.questions.reduce((acc, q) => (
        `${q.answer_description}; `+acc
    ), "")}${product.extras.reduce((acc, e) => (
        `C/ ${e.amount > 1 ? `${e.amount}x ` : ""}${e.name}; `+acc
    ), "")}${product.exclusions.reduce((acc, s) => (
        `S/ ${s.name}; `+acc
    ), "")}${product.notes.reduce((acc, n) => (
        `${n.description}; `+acc
    ), "")}${product.note.length === 0 ? "" : `${product.note};`}`
)