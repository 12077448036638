import { Button, Divider, makeStyles, Tooltip } from "@material-ui/core";
import clsx from "clsx";
import { AvatarPlaceholder } from "../../../placeholders/AvatarPlaceholder";
import { CharactersPlaceholder } from "../../../placeholders/CharactersPlaceholder";
import { placeholderAnimationClass } from "../../../providers/Theme";
import { formatMoney } from "../../../utils/format";

const useStyles = makeStyles(theme => ({
    valueButtonButton: {
        display: 'flex',
        flexGrow: 1,
        flexDirection: 'row',
        borderRadius: theme.shape.borderRadius*4,
        padding: `${theme.spacing(2)}px ${theme.spacing(3)}px`,
        textTransform: 'none',
        lineHeight: '130%',
    },
    valueButtonContent: {
        display: 'flex',
        flexGrow: 1,
        flexDirection: 'column',
    },
    valueButtonTitle: {
        textAlign: 'start',
        opacity: 0.7,
        marginBottom: theme.spacing(1),
        textOverflow: 'ellipsis',
        whiteSpace: 'nowrap',
        overflow: 'hidden',

    },
    valueButtonValue: {
        textAlign: 'start',
        fontSize: theme.typography.h5.fontSize,
        fontWeight: theme.typography.fontWeightBold,
        textOverflow: 'ellipsis',
        whiteSpace: 'nowrap',
        overflow: 'hidden',
    },
    valueButtonActionContainer: {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
    },
    valueButtonActionLabel: {
        opacity: 0.7,
        marginTop: theme.spacing(0.5),
        fontWeight: theme.typography.fontWeightBold,
        fontSize: theme.typography.caption.fontSize,
        textTransform: 'uppercase'
    }
}));

type ValueButtonProps = {
    value: number;
    title: string;
    icon: JSX.Element;
    tooltip: string;
    onClick: () => void;
    disabled?: boolean;
    actionLabel: string;
}

export function ValueButton({
    title,
    value,
    icon,
    tooltip,
    onClick,
    disabled,
    actionLabel
}: ValueButtonProps) {
    const classes = useStyles();

    const button = (
    <Button
        className={classes.valueButtonButton}
        onClick={onClick}
        disabled={disabled}
        color="primary"
        variant="contained"
    >
        <div className={classes.valueButtonContent}>
            <div className={classes.valueButtonTitle}>
                {title}
            </div>
            <div className={classes.valueButtonValue}>
                {formatMoney(value)}
            </div>
        </div>
        <Divider flexItem orientation="vertical" variant="middle" />
        <div className={classes.valueButtonActionContainer}>
            {icon}
            <div className={classes.valueButtonActionLabel}>
                {actionLabel}
            </div>
        </div>
    </Button>
    );

    if (disabled) {
        return button;
    }

    return (
    <Tooltip title={tooltip}>
        {button}
    </Tooltip>
    );
}

export function ValueButtonPlaceholder() {
    const classes = useStyles();
    return (
        <Button
            className={clsx(
                classes.valueButtonButton,
                placeholderAnimationClass
            )}
            disabled
        >
            <div className={classes.valueButtonContent}>
                <div className={classes.valueButtonTitle}>
                    <CharactersPlaceholder n={15} />
                </div>
                <div className={classes.valueButtonValue}>
                    <CharactersPlaceholder n={15} />
                </div>
            </div>
            <AvatarPlaceholder />
        </Button>
    );
}