import { QueryClient, QueryClientProvider } from "react-query";
import { ProviderProps } from "../types/provider";

const queryClient = new QueryClient()

export function ReactQueryProvider({ children }: ProviderProps) {
    return (
    <QueryClientProvider client={queryClient}>
        {children}
    </QueryClientProvider>
    );
}

type QueryParams = Record<string, string | number | boolean>;
type QueryInvalidate<T extends QueryParams> = { url: string, params?: Partial<T> }

function invalidateQuery(
    queryURL: string,
    queryParams: any,
    url: string,
    params: any
) {
    if (url !== queryURL) {
        return false;
    }
    for (let paramKey in params) {
        if (
            params[paramKey] !== undefined &&
            params[paramKey] !== queryParams[paramKey]
        ) {
            return false;
        }
    }
    return true;
}

export function invalidateQueries(
    args: QueryInvalidate<any>[]
) {
    if (args.length > 0) {
        queryClient.invalidateQueries({
            predicate: objQuery => {
                if (!Array.isArray(objQuery.queryKey)) {
                    console.error('query.queryKey isnt array type');
                    return false;
                }
                if (objQuery.queryKey.length < 2) {
                    console.error('query.queryKey.length < 2');
                    return false;
                }
                const [ queryURL, queryParams ] = objQuery.queryKey;
                for (let i=0; i<args.length; i++) {
                    const { url, params } = args[i];
                    if (invalidateQuery(
                        queryURL,
                        queryParams,
                        url,
                        params
                    )) {
                        return true
                    }
                }
                return false;
            }
        });
    }
}