import { makeStyles } from "@material-ui/core";
import clsx from "clsx";
import { memo } from "react";
import { placeholderAnimationClass } from "../providers/Theme";

const useStyles = makeStyles(theme => ({
    container: {
		borderRadius: theme.shape.borderRadius,
    }
}));

type CharactersProps = {
    n: number;
}
function Component({ n }: CharactersProps) {
    const classes = useStyles();;
    return (
    <span className={clsx(placeholderAnimationClass, classes.container)}>
        {"_".repeat(n)}
    </span>
    );    
}

export const CharactersPlaceholder = memo(Component, () => true);