import MuiStoreIcon from '@material-ui/icons/Store';
import MuiLocationOnIcon from '@material-ui/icons/LocationOn';
import MuiPhoneIcon from '@material-ui/icons/Phone';
import MuiMailIcon from '@material-ui/icons/Mail';
import MuiFastfoodIcon from '@material-ui/icons/Fastfood';
import MuiListIcon from '@material-ui/icons/List';
import MuiLocalLibraryIcon from '@material-ui/icons/LocalLibrary';
import MuiSpaIcon from '@material-ui/icons/Spa';
import MuiLocalPizzaIcon from '@material-ui/icons/LocalPizza';
import MuiQuestionAnswerIcon from '@material-ui/icons/QuestionAnswer';
import MuiFindInPageIcon from '@material-ui/icons/FindInPage';
import MuiReceiptIcon from '@material-ui/icons/Receipt';
import MuiPaymentIcon from '@material-ui/icons/Payment';
import MuiMotorcycleIcon from '@material-ui/icons/Motorcycle';
import MuiLockIcon from "@material-ui/icons/Lock";
import MuiPersonIcon from "@material-ui/icons/Person";
import MuiAccountCircleIcon from '@material-ui/icons/AccountCircle';
import MuiExitToAppIcon from '@material-ui/icons/ExitToApp';
import MuiSettingsIcon from '@material-ui/icons/Settings';
import MuiMenuBookIcon from '@material-ui/icons/MenuBook';
import MuiShoppingBasketIcon from '@material-ui/icons/ShoppingBasket';
import MuiWhatsAppIcon from '@material-ui/icons/WhatsApp';
import MuiPeopleAltIcon from '@material-ui/icons/PeopleAlt';
import MuiSportsMotorsportsIcon from '@material-ui/icons/SportsMotorsports';
import LocalAtmIcon from '@material-ui/icons/LocalAtm';

export const WhatsAppIcon = MuiWhatsAppIcon;
export const PasswordIcon = MuiLockIcon;
export const StoreIcon = MuiStoreIcon
export const AddressIcon = MuiLocationOnIcon
export const PhoneIcon = MuiPhoneIcon
export const MailIcon = MuiMailIcon
export const ProductIcon = MuiFastfoodIcon
export const NoteIcon = MuiListIcon
export const SectionIcon = MuiLocalLibraryIcon
export const SupplyIcon = MuiSpaIcon
export const MixableIcon = MuiLocalPizzaIcon
export const QuestionIcon = MuiQuestionAnswerIcon
export const PreviousOrderIcon = MuiFindInPageIcon
export const ActiveOrderIcon = MuiReceiptIcon
export const PaymentMethodIcon = MuiPaymentIcon
export const DeliveryMethodIcon = MuiMotorcycleIcon
export const DeliveryFeeIcon = LocalAtmIcon
export const UserIcon = MuiPersonIcon;
export const ProfileIcon = MuiAccountCircleIcon;
export const LogoutIcon = MuiExitToAppIcon;
export const ConfigIcon = MuiSettingsIcon;
export const MenuIcon = MuiMenuBookIcon;
export const BagIcon = MuiShoppingBasketIcon;
export const ManageAccessIcon = MuiPeopleAltIcon;
export const DeliverymanIcon = MuiSportsMotorsportsIcon;