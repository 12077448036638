import { createRef, UIEvent, useCallback, useLayoutEffect, useRef, useState } from "react";

const scrollStopThreshold = 150;

type ContentInfo = {
    offset: number;
    height: number;
    sum: number;
}

type VerticalScrollParameters = {
    smoothScrolling: boolean;
    sectionsLength: number;
    scrollOffset: number;
}
export const useVerticalScroll = <
    ContainerRefType extends HTMLElement,
    ItemRefType extends HTMLElement
>({
    smoothScrolling,
    sectionsLength,
    scrollOffset
}: VerticalScrollParameters) => {

    const containerRef = useRef<ContainerRefType>(null);
    const [ activeIndex, setActiveIndex ] = useState<number | false>(false);
    const [ contentsRefs, setContentsRefs ] = useState(() => Array.from(
        { length: sectionsLength },
        () => createRef<ItemRefType>()
    ));
    const [ scrollTimerID, setScrollTimerID ] = useState<NodeJS.Timeout | null>(null);
    const [ contentsInfo, setContentsInfo ]  = useState(contentsRefs.map(ref => {
        const { offsetTop: offset, clientHeight: height } = ref?.current || {};
        return { offset, height, sum: (offset || 0)+(height || 0)  };
    }).filter(e => e.offset !== undefined && e.height !== undefined) as ContentInfo[]);

    useLayoutEffect(() => {
        setActiveIndex(sectionsLength > 0 ? 0 : false);
    // eslint-disable-next-line
    }, [ sectionsLength ]);

    useLayoutEffect(() => {
        if (contentsRefs.length !== sectionsLength) {
            setContentsRefs(Array.from(
                {length: sectionsLength},
                () => createRef<ItemRefType>()
            ));
        }
        setContentsInfo(contentsRefs.map(ref => {
            const { offsetTop: offset, clientHeight: height } = ref?.current || {};
            return { offset, height, sum: (offset || 0)+(height || 0)  };
        }).filter(e => e.offset !== undefined && e.height !== undefined) as ContentInfo[]);
    }, [ sectionsLength, contentsRefs ]);

    const getNewIndex = useCallback((currentScroll: number) => {
        let containerHeight = containerRef?.current?.clientHeight;
        if (contentsInfo.length === 0 || sectionsLength === 0) return false;
        if (!containerHeight) return activeIndex;
        if (contentsInfo.length !== sectionsLength) return activeIndex;
        let begin = 0;
        for(;
            begin < contentsInfo.length &&
            contentsInfo[begin].sum < currentScroll;
            begin++
        );
        if (begin === contentsInfo.length - 1) return begin;
        let end = begin;
        for(;
            end < contentsInfo.length &&
            contentsInfo[end].offset < currentScroll+containerHeight;
            end++
        );
        if (begin !== end) end--;
        let currentIndex = begin;
        let currentValue = 0;
        for (let i = begin; i < end+1; i++) {	
            let value = contentsInfo[i].height -
                Math.max(0, currentScroll - contentsInfo[i].offset) -
                Math.max(0, contentsInfo[i].offset + contentsInfo[i].height -currentScroll - containerHeight);
            if (value === contentsInfo[i].height) {
                return i;
            }
            if (value > currentValue) {
                currentValue = value;
                currentIndex = i;
            }
        }
        return currentIndex;
    }, [
        activeIndex,
        contentsInfo,
        sectionsLength
    ]);

    const scrollTimerHandler = useCallback((e: UIEvent<ContainerRefType>) => {
        if (e.currentTarget !== null) {
            const currentScroll = e.currentTarget.scrollTop;
            if (scrollTimerID) clearTimeout(scrollTimerID);
            setScrollTimerID(setTimeout(() => {
                setActiveIndex(getNewIndex(currentScroll));
            }, scrollStopThreshold))
        }
    }, [ getNewIndex, scrollTimerID ]);

    const selectSection = useCallback((index: number) => {
        if (index < contentsInfo.length) {
            containerRef?.current?.scrollTo({
                top: contentsInfo[index].offset - scrollOffset,
                behavior: smoothScrolling ? 'smooth' : 'auto'
            });
            setActiveIndex(index);
        }
    }, [
        contentsInfo,
        scrollOffset,
        smoothScrolling
    ]);

    return {
        scrollHandler: scrollTimerHandler,
        sectionsRefs: contentsRefs,
        containerRef,
        activeIndex,
        selectSection
    }
}