import { Divider, List, makeStyles, Typography } from '@material-ui/core';
import { BagNote } from '../bag/types';
import { ListItemCheckbox } from '../../components/ListItemCheckbox';
import { ProductMenuStateType } from './hooks';
import { TextInput } from '../../../../components/TextInput';
import { useState } from 'react';

const useStyles = makeStyles(theme => ({
	productNotesFreeNoteContainer: {
		padding: theme.spacing(2)
	},
	productNotesSubtitle: {
		paddingLeft: theme.spacing(1),
		marginTop: theme.spacing(2)
	}
}));

type ProductNotesProps = {
	notes: BagNote[];
	productIndex : number;
	freeNote : string;
	setFreeNote: ProductMenuStateType['setFreeNote'];
	toggle: ProductMenuStateType['toggleNote'];
};

export function ProductNotes({
	notes,
	productIndex,
	freeNote,
	setFreeNote,
	toggle,
}: ProductNotesProps) {
	const classes = useStyles();

	const [ freeNoteAux, setFreeNoteAux ] = useState(freeNote);

	return (
	<>
		<Typography
			variant="h5"
			gutterBottom
			color="textSecondary"
			className={classes.productNotesSubtitle}
		>
			Observações
		</Typography>
		<Divider />
		{notes.length > 0 && (
		<List disablePadding>
			{notes.map((note, index) => (
			<ListItemCheckbox
				key={index}
				toggle={() => toggle(productIndex, note.id)}
				checked={note.selected}
			>
				{note.description}
			</ListItemCheckbox>
			))}
		</List>
		)}
		<div className={classes.productNotesFreeNoteContainer}>
			<TextInput
				onBlur={() => setFreeNote(productIndex, freeNoteAux)}
				value={freeNoteAux}
				onChange={value => setFreeNoteAux(value)}
				multiline
                fullWidth
				variant="filled"
				rows={2}
				rowsMax={4}
				maxChar={300}
				label={"Observação livre"}
				placeholder={`Digite suas observações aqui...`}
			/>
		</div>
	</>
	);
}