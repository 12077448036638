import { Divider, List, ListItem, ListItemIcon, ListItemText } from "@material-ui/core";
import { Fragment, ReactNode, useEffect, useState } from "react";
import { ResponsiveFullscreenDialog } from "./ResponsiveFullscreenDialog";
import { AddressIcon } from "./Icons";

type Item = any;

type Props = {
	items : Item[];
	title : string;
	select : (item : Item) => void;
	checkSelected : (item : Item) => boolean;
	children : (item : Item) => ReactNode;
	selectedListItemContent : (item : Item) => ReactNode;
	unselectedContent : ReactNode;
	emptyContent : ReactNode;
	emptyAction : () => void;
	disabled : boolean;
	managementPrimary: string;
	managementSecondary: string;
	managementIcon: JSX.Element;
	managementOnClick: () => void;
}

export default function ListSelect({
	items,
	title,
	select,
	checkSelected,
	children,
	selectedListItemContent,
	unselectedContent,
	emptyContent,
	emptyAction,
	disabled,
	managementPrimary,
	managementSecondary,
	managementIcon,
	managementOnClick
} : Props) {
	const [ visible, setVisible ] = useState(false);
	const [ selected, setSelected ] = useState(items.find(checkSelected));

	useEffect(() => {
		setSelected(items.find(checkSelected));
	}, [ checkSelected, items ]);

	return (
	<>
		<List>
			<ListItem
				button
				disabled={disabled}
				onClick={() => (
					items.length === 0 ?
					emptyAction()  :
					setVisible(true)
				)}
			>
				{(
					items.length === 0 ?
					emptyContent : 
					selected ?
					selectedListItemContent(selected) :
					unselectedContent
				)}
			</ListItem>
		</List>
		<ResponsiveFullscreenDialog
			open={visible}
			onClose={() => setVisible(false)}
			icon={<AddressIcon />}
			title="Selecione um endereço"
			width="sm"
		>
			<List>
				<ListItem button onClick={managementOnClick}>
					<ListItemIcon>
						{managementIcon}
					</ListItemIcon>
					<ListItemText
						primary={managementPrimary}
						secondary={managementSecondary}
					/>
				</ListItem>
				{items.map((item, index) => (
				<Fragment key={index}>
					<Divider />
					<ListItem
						button
						disabled={disabled}
						onClick={() => {
							select(item);
							setVisible(false);
						}}
					>
						{children(item)}
					</ListItem>
				</Fragment>
				))}
				
			</List>
		</ResponsiveFullscreenDialog>
	</>
	);
}
