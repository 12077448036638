import { makeStyles } from '@material-ui/core/styles';
import { ListItem, ListItemAvatar, ListItemText, Typography } from '@material-ui/core';
import { formatMoney } from '../../../../utils/format';
import ProductAvatar from '../../../../components/ProductAvatar';

const useStyles = makeStyles(theme => ({
	listItem: {
		paddingTop: theme.spacing(3),
		paddingBottom: theme.spacing(3),
	},
	price: {
		fontWeight: theme.typography.fontWeightMedium,
		color: theme.palette.success.main
	},
}));


type Props = {
	name: string;
	description?: string;
	price: number | null;
	image_id: string | null;
	onClick: () => void;
}

export function ProductListItem({
    name,
    description,
    price,
    image_id,
    onClick
}: Props) {
	
	const classes = useStyles();

	return (
	<ListItem
		button
		onClick={onClick}
		className={classes.listItem}
		alignItems={description && description?.length > 0 ? "flex-start" : undefined}
	>
		<ListItemAvatar>
			<ProductAvatar
				image_id={image_id}
				name={name}
			/>
		</ListItemAvatar>
		<ListItemText
			primary={name}
			secondaryTypographyProps={{ component: "div" }}
			secondary={(
			<>
				{description}
				<Typography noWrap className={classes.price}>
					{price !== null && formatMoney(price)}
				</Typography>
			</>
			)}
		/>
	</ListItem>
	);
};