import { createContext, useCallback, useContext } from 'react';
import useFeedback from './Feedback';
import { ProviderProps } from '../types/provider';

type Error = {
	message: string;
	error?: any;
}

interface ErrorHandlerContextType {
    report: (err: Error) => void;
}

const ErrorHandlerContext = createContext({} as ErrorHandlerContextType);

const useErrorHandler = () => useContext(ErrorHandlerContext);
export default useErrorHandler;

function sendToStorage(err: Error) {
    console.error(err)
}

export function ErrorHandlerProvider({ children } : ProviderProps) {
	
	const { errorPopup } = useFeedback();

	const report = useCallback((err : Error) => {
		errorPopup(err);
		sendToStorage(err);
	}, [ errorPopup ]);

	return (
	<ErrorHandlerContext.Provider value={{ report }}>
		{children}
	</ErrorHandlerContext.Provider>
	);
}