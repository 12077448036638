import { List, ListItem, ListItemIcon, ListItemText, Radio, ListItemSecondaryAction } from "@material-ui/core";
import { ReactNode } from "react";
import { Repeat } from "../../../../components/Repeat";
import Subtitle from "../../../../components/Subtitle";
import PaymentMethod, { PaymentMethodUtils } from "../../../../constants/payment_method";
import { CharactersPlaceholder } from "../../../../placeholders/CharactersPlaceholder";
import { ListItemPlaceholder } from "../../../../placeholders/ListItemPlaceholder";
import { useGetCompanyFullInfo } from "../../queries";
import { useBag } from "../bag/BagProvider";
import { MoneyChangePicker } from "./MoneyChangePicker";


export function PaymentMethodPicker() {

	const {
		data: company,
		isLoading: companyLoading
	} = useGetCompanyFullInfo();

	const {
		bag,
		selectPaymentMethod,
		updateNeedChange,
		updateChangeFor
	} = useBag();

	if (!company || companyLoading) {
		return (
			<PaymentMethodPickerPlaceholder />
		);
	}

	return (
	<>
		<Subtitle divider>Formas de pagamento</Subtitle>
		<List>
			{PaymentMethodUtils.map(company.payment_methods, {
				[PaymentMethod.MONEY]: (method, id) => (
				<MethodListItem
					key={id}
					onClick={() => selectPaymentMethod(id)}
					label={method.description}
					checked={bag.payment_method_id === id}
					icon={method.icon}
				/>
				),
				[PaymentMethod.CREDIT]: (method, id) => (
				<MethodListItem
					key={id}
					onClick={() => selectPaymentMethod(id)}
					label={method.description}
					checked={bag.payment_method_id === id}
					icon={method.icon}
				/>
				),
				[PaymentMethod.DEBIT]: (method, id) => (
				<MethodListItem
					key={id}
					onClick={() => selectPaymentMethod(id)}
					label={method.description}
					checked={bag.payment_method_id === id}
					icon={method.icon}
				/>
				)
			})}
		</List>
		{PaymentMethodUtils.switch(bag.payment_method_id, {
			[PaymentMethod.MONEY]: (
			<MoneyChangePicker
				needChangeFor={bag.need_change}
				changeFor={bag.change_for}
				onChangeNeedChange={updateNeedChange}
				onChangeChangeFor={value => updateChangeFor(value)}
			/>
			)
		})}
	</>
	);
}

export function PaymentMethodPickerPlaceholder() {
	return (
	<>
		<Subtitle divider><CharactersPlaceholder n={8} /></Subtitle>
		<List>
			<Repeat n={3}>
				<ListItemPlaceholder avatar secondaryAction />
			</Repeat>
		</List>
	</>
	);
}


type ListItemProps = {
	onClick : () => void;
	icon : ReactNode;
	label : string;
	checked : boolean;
}

function MethodListItem({ onClick, icon, label, checked } : ListItemProps) {
	return (
	<ListItem
		button
		onClick={onClick}
	>
		<ListItemIcon>
			{icon}
		</ListItemIcon>
		<ListItemText>
			{label}
		</ListItemText>
		<ListItemSecondaryAction>
			<Radio
				checked={checked}
				onChange={onClick}
			/>
		</ListItemSecondaryAction>
	</ListItem>
	);
}
