import { GlobalAppConfigMenuButton } from "../../../../containers/GlobalAppConfigMenuButton";
import { IconButtonPlaceholder } from "../../../../placeholders/IconButtonPlaceholder";
import { BagDialog } from "../bag/BagDialog";
import { CheckoutDialog } from "../checkout/CheckoutDialog";
import { CompanyInfoCard, CompanyInfoCardPlaceholder } from "../company/CompanyInfoCard";
import { Menu, MenuPlaceholder } from "../menu/Menu";
import { SearchInput, SearchInputPlaceholder } from "../search/SearchInput";
import { MixableDialog } from "../mixable/MixableDialog";
import { ProductMenuDialog } from "../product_menu/ProductMenuDialog";
import { ProfileButton } from "../profile/ProfileButton";
import { TopBar, TopBarPlaceholder } from "./TopBar";
import { SearchDialog } from "../search/SearchDialog";
import { useGetCompanyFullInfo, useGetUserActiveOrders } from "../../queries";
import { useBag } from "../bag/BagProvider";
import { BottomBar } from "./BottomBar";
import { useEffect, useMemo } from "react";
import { BagIcon } from "../../../../components/Icons";
import { BottomBarItem } from "./BottomBar";
import ReceiptIcon from '@material-ui/icons/Receipt';
import { useOrderRoute, useSelectOrderRoute } from "./routes";
import { OrderDialog } from "../orders/OrderDialog";
import { OrderActiveDialog } from "../orders/OrderActiveDialog";
import { PreviousOrdersDialog } from "../orders/PreviousOrdersDialog";
import { CompanyInfoDialog } from "../company/CompanyInfoDialog";
import { useHead } from "../../../../providers/Head";

export function Nav() {

    const {
        data: activeOrders,
    } = useGetUserActiveOrders();

    const {
        data: company,
        isLoading: companyLoading
    } = useGetCompanyFullInfo();

    const {
        setTitle,
        setIconID
    } = useHead();
    
    const selectOrderRoute = useSelectOrderRoute();
    const orderRoute = useOrderRoute();

    const { bag, open: openBag } = useBag();

    useEffect(() => {
        if (company?.logo_image_id) {
            setIconID(company?.logo_image_id);
        }
        if (company?.name) {
            setTitle(company.name)
        }
    }, [
        company?.name,
        company?.logo_image_id,
        setIconID,
        setTitle
    ]);

    const bottomBarItems = useMemo<BottomBarItem[]>(() => ([{
        labelXs: "Sacola",
        label: "Sua sacola",
        icon: <BagIcon />,
        badge: bag.items.length,
        color: "secondary",
        onClick: openBag,
        loading: companyLoading || !company,
    },{
        labelXs: "Pedido",
        label: "Pedido ativo",
        icon: <ReceiptIcon />,
        badge: activeOrders?.length,
        color: "primary",
        onClick: () => {
            if (activeOrders === undefined || activeOrders.length === 0) {
                return;
            }
            if (activeOrders.length === 1) {
                return orderRoute.push({ order_id: activeOrders[0].id });
            }
            return selectOrderRoute.push({})
        },
        loading: companyLoading || !company,
    }]), [
        bag.items.length,
        company,
        companyLoading,
        openBag,
        activeOrders,
        selectOrderRoute,
        orderRoute
    ]);
    
    return (
    <>
        <TopBar>
            <SearchInput />
            <GlobalAppConfigMenuButton />
            <ProfileButton />
        </TopBar>
        <Menu>
            <CompanyInfoCard />
        </Menu>
        <BottomBar items={bottomBarItems} />
        <BagDialog />
        <CheckoutDialog />
        <ProductMenuDialog />
        <MixableDialog />
        <SearchDialog />
        <OrderDialog />
        <OrderActiveDialog />
        <PreviousOrdersDialog />
        <CompanyInfoDialog />
    </>
    );
}

export function NavPlaceholder() {
    return (
    <div>
        <TopBarPlaceholder>
            <SearchInputPlaceholder />
            <IconButtonPlaceholder />
            <IconButtonPlaceholder />
        </TopBarPlaceholder>
        <MenuPlaceholder>
            <CompanyInfoCardPlaceholder />
        </MenuPlaceholder>
    </div>
    );
}