import { useCallback, useEffect, useState } from "react";
import { AppModeEnum } from "../../../../constants/app_mode";
import DeliveryMethod from "../../../../constants/delivery_method";
import MixableType from "../../../../constants/mixable_type";
import PaymentMethod from "../../../../constants/payment_method";
import { useCompany } from "../../../../providers/Company";
import useFeedback from "../../../../providers/Feedback";
import { useSession } from "../../../../providers/Session";
import { ResponseUserAddress, useGetUserAddresses } from "../../../../queries";
import { useGetCompanyStatus } from "../../../../queries/company";
import { formatMoney } from "../../../../utils/format";
import { useAddOrder } from "../../mutations";
import { useGetCompanyFullInfo } from "../../queries";
import { useAppMode } from "../nav/AppModeProvider";
import { useBagRoute, useMenuRoute } from "../nav/routes";
import { useProfile } from "../profile/ProfileProvider";
import { Bag, BagItem } from "./types";

function newEmptyBag(uuid: string, userAddresses: ResponseUserAddress[] | undefined ): Bag {
	return {
		uuid: uuid,
	
		price: 0,
		items: [],
		
		note: "",
	
		payment_method_id: PaymentMethod.NONE,
		delivery_method_id: DeliveryMethod.NONE,
	
		table: 0,
		need_change: false,
		change_for: 0,
		delivery_address_id: userAddresses !== undefined &&  userAddresses.length > 0 ? userAddresses[0].id : null
	};
}

export const useBagProvider = () => {

	const { appModeContent } = useAppMode();

    const addOrderMutation = useAddOrder();
    const { data: company, isLoading: companyLoading } = useGetCompanyFullInfo();
    const { data: userAddresses, isLoading: userAddressesLoading } = useGetUserAddresses();
	const { data: companyStatus, isLoading: companyStatusLoading } = useGetCompanyStatus();

	const menuRoute = useMenuRoute();
	const bagRoute = useBagRoute();

	const { loggedIn } = useSession();

	const { openConnect } = useProfile();

	const { infoPopup } = useFeedback();

    const { companyID } = useCompany() as { companyID: string };

	const BAG_STORAGE_KEY = `bag-${companyID}`;

	const [ bag, setBag ] = useState<Bag>(() => {
        const fromStorage = localStorage.getItem(BAG_STORAGE_KEY);
        if (fromStorage) {
            const newBag: Bag = JSON.parse(fromStorage);
            if (newBag.uuid !== companyID) {
                return newEmptyBag(companyID, userAddresses);
            } else {
                return newBag;
            }
        } else {
            return newEmptyBag(companyID, userAddresses);
        }
    });

	const computeValue = useCallback((newBag: Bag) => {
		let price = newBag.items.reduce((acc, i) => i.price+acc, 0);
		return {
			price,
			change_for: Math.ceil(
				(
					price+(
						newBag.delivery_method_id === DeliveryMethod.DELIVERY ?
						company?.delivery_tax || 0 : 0
					)
				)/1000
			)*1000
		}
	}, [ company?.delivery_tax ]);

	const save = useCallback((callback: (old: Bag) => Bag, updateValue: boolean = false) => setBag(previous => {
        if (previous === null) return previous;
        const newBag = callback({ ...previous});
        if (updateValue) {
            const { price, change_for} = computeValue(newBag);
            newBag.price = price;
            newBag.change_for = change_for;
        }
        localStorage.setItem(
            BAG_STORAGE_KEY,
            JSON.stringify(newBag)
        );
        return newBag;
    }), [ BAG_STORAGE_KEY, computeValue ]);

	const clearBag = useCallback(() => (
		save(() => newEmptyBag(companyID, userAddresses))
	), [ companyID, save, userAddresses ]);

	

	const editFromBag = useCallback((newItem: BagItem, index: number) => {
		save(newBag => {
			newItem = JSON.parse(JSON.stringify(newItem))
			newBag.items[index] = newItem;
			return newBag;
		}, true);
	}, [ save ]);

	const removeFromBag = useCallback((index: number) => {
		save(newBag => {
			newBag.items.splice(index, 1);
			return newBag;
		}, true);
	}, [ save ]);

	const addToBag = useCallback((newItem: BagItem) => {
		save(newBag => {
			newItem = JSON.parse(JSON.stringify(newItem))
			newBag.items.push(newItem);
			return newBag;
		}, true);
	}, [ save ]);

	const selectDeliveryMethod = useCallback((newID: DeliveryMethod) => {
		if (appModeContent.mode === AppModeEnum.TABLE){
			newID = DeliveryMethod.TABLE;
		}
		save(newBag => {
			newBag.delivery_method_id = newID;
			return newBag;
		}, true);
	}, [ save, appModeContent.mode ]);


	const selectPaymentMethod = useCallback((newID: PaymentMethod) => {
		if (appModeContent.mode === AppModeEnum.TABLE){
			newID = PaymentMethod.NONE;
		}
		save(newBag => {
			newBag.payment_method_id = newID;
			return newBag;
		}, true);
	}, [ save, appModeContent.mode ]);

	const updateChangeFor = useCallback((newValue: number) => {
		save(newBag => {
			newBag.change_for = newValue;
			return newBag;
		});
	}, [ save ]);

	const updateNeedChange = useCallback((newValue: boolean) => {
		save(newBag => {
			newBag.need_change = newValue;
			return newBag;
		});
	}, [ save ]);

	const updateAddressID = useCallback((newID: number) => {
		save(newBag => {
			newBag.delivery_address_id = newID;
			return newBag;
		});
	}, [ save ]);

	const updateTable = useCallback((newTable: number) => {
		if (appModeContent.mode === AppModeEnum.TABLE){
			newTable = appModeContent.table;
		}
		save(newBag => {
			newBag.table = newTable;
			return newBag;
		});
	// eslint-disable-next-line
	}, [ save, appModeContent.mode ]);

	const updateNote = useCallback((newNote: string) => {
		save(newBag => {
			newBag.note = newNote;
			return newBag;
		});
	}, [ save ]);

	const sendOrder = useCallback(() => {

		if (companyLoading || !company) {
			return infoPopup({
				message: "Tente novamente",
			});
		}

		if (!companyStatus || companyStatusLoading || !companyStatus.open_store) {
			return infoPopup({
				message: "A loja está fechada"
			});
		}

		if (!loggedIn) {
			openConnect();
			return infoPopup({
				message: "Identifique-se para continuar"
			});
		}

		if (bag.items.length === 0) {
			return infoPopup({
				message: "Sua sacola está vazia"
			});
		}

		if (bag.delivery_method_id === DeliveryMethod.NONE) {
			return infoPopup({
				message: "Você precisa escolher um método de entrega"
			});
		}

		if (bag.delivery_method_id === DeliveryMethod.DELIVERY) {
			if (bag.payment_method_id === PaymentMethod.NONE) {
				return infoPopup({
					message: "Você precisa escolher uma forma de pagamento"
				});
			}

			if (bag.price < company.min_order_price) {
				return infoPopup({
					message: `O pedido mínimo é ${formatMoney(company.min_order_price)} (excluindo a taxa de entrega)`
				});
			}

			if (
				userAddresses === undefined ||
				bag.delivery_address_id === null ||
				userAddresses.find(a => a.id === bag.delivery_address_id) === undefined
			) {
				return infoPopup({
					message: "Você precisa informar um endereço de entrega"
				});
			}
		}

		addOrderMutation.mutate({
			note: bag.note,
			payment_method_id: bag.payment_method_id,
			delivery_method_id: bag.delivery_method_id,
			table: bag.table,
			need_change: bag.need_change,
			change_for: bag.change_for,
			delivery_address_id: bag.delivery_address_id,
			items: bag.items.map(item => ({
				mixable_id: item.mixable?.id || null,
				products: item.products.map(product => ({
					id: product.id,
					note: product.freeNote,
					amount: !item.mixable || item.mixable.type_id === MixableType.NONE || item.mixable.type_id === MixableType.COMBO ? product.amount : null,
					exclusions: product.ingredients.filter(s => !s.selected).map(s => s.id),
					questions: product.questions.map(q => ({
						id: q.id,
						answer_id: q.selected
					})),
					notes: product.notes.filter(n => n.selected).map(n => n.id),
					extras: product.extras.filter(n => n.amount > 0).map(e => ({
						id: e.id,
						amount: e.amount
					}))
				}))
			}))
		}, {
			onSuccess: () => {

				clearBag();
				if (appModeContent.mode === AppModeEnum.TABLE){
					selectDeliveryMethod(DeliveryMethod.TABLE);
					updateTable(appModeContent.table);
				}
				menuRoute.push({}); // change to active orders
			}
		});
	// eslint-disable-next-line
	}, [
		addOrderMutation,
		appModeContent.mode,
		bag.change_for,
		bag.delivery_address_id,
		bag.delivery_method_id,
		bag.items,
		bag.need_change,
		bag.note,
		bag.payment_method_id,
		bag.price,
		bag.table,
		clearBag,
		company,
		companyLoading,
		companyStatus,
		companyStatusLoading,
		infoPopup,
		loggedIn,
		menuRoute,
		openConnect,
		userAddresses
	]);

	const open = useCallback(() => bagRoute.push({}), [ bagRoute ]);

	useEffect(() => {
		if (
			
			!userAddressesLoading &&
			userAddresses &&
			userAddresses.length > 0 && 
			(
				bag.delivery_address_id === null ||
				userAddresses.find(a => a.id === bag.delivery_address_id) === undefined
			)
		) {
			updateAddressID(userAddresses[0].id);  
		}
	}, [
		bag.uuid,
		userAddresses,
		userAddressesLoading,
		updateAddressID,
		bag.delivery_address_id
	]);

	useEffect(() => {
		if (appModeContent.mode === AppModeEnum.TABLE){
			selectDeliveryMethod(DeliveryMethod.TABLE);
			updateTable(appModeContent.table);
		}
	// eslint-disable-next-line
	},[
		appModeContent.mode
	]);

	useEffect(() => {
        if (companyID !== null && companyID !== bag.uuid) {
            setBag(() => {
                const fromStorage = localStorage.getItem(BAG_STORAGE_KEY);
                if (fromStorage) {
                    const newBag: Bag = JSON.parse(fromStorage);
                    if (newBag.uuid !== companyID) {
                        return newEmptyBag(companyID as string, userAddresses);
                    } else {
                        return newBag;
                    }
                } else {
                    return newEmptyBag(companyID, userAddresses);
                }
            });
        }
	}, [
		companyID,
		BAG_STORAGE_KEY,
		bag.uuid,
		userAddresses
	]);

    return {
        bag,
		open,
        addToBag,
		addOrderMutation,
        removeFromBag,
        editFromBag,
        selectDeliveryMethod,
        selectPaymentMethod,
        updateChangeFor,
        updateNeedChange,
        updateAddressID,
        updateTable,
        updateNote,
        sendOrder
    };
}