import { ThemeProvider } from "./providers/Theme";
import { Wrapper } from "./components/Wrapper";
import { OfflineProvider } from "./providers/Offline";
import { FeedbackProvider } from "./providers/Feedback";
import { ErrorHandlerProvider } from "./providers/ErrorHandler";
import useRouter, { especialSubjects, RouterProvider } from "./providers/Router";
import { NotFound } from "./components/NotFound";
import { ConfirmDialogProvider } from "./providers/ConfirmDialogs";
import { GeopositionProvider } from "./providers/Geoposition";
import { lazy, Suspense } from "react";
import { PortalPlaceholder } from "./services/portal/placeholder/PortalPlaceholder";
import { NavPlaceholder as StorePlaceholder } from "./services/store/screens/nav/Nav";
import { SessionProvider } from "./providers/Session";
import { ReactQueryProvider } from "./providers/ReactQuery";
import { CompanyProvider } from "./providers/Company";
import { HeadProvider } from "./providers/Head";


const Store = lazy(() => import("./services/store/Store"));
const Portal = lazy(() => import("./services/portal/Portal"));
const Printables = lazy(() => import("./services/printables/Printables"));
const Session = lazy(() => import("./services/session/Session"));

function Switcher() {
	const { subject } = useRouter()
	switch (subject) {
		case especialSubjects.NONE:
			return <NotFound />;
		case especialSubjects.PRINTABLES:
			return (
			<Suspense fallback={<div>Carregando.....</div>}>
				 <Printables />
			</Suspense>
			);
		case especialSubjects.PORTAL:
			return (
			<Suspense fallback={<PortalPlaceholder />}>
				<Portal />
			</Suspense>
			);
		case especialSubjects.SESSION:
			return <Session />
		default:
			return (
			<Suspense fallback={<StorePlaceholder />}>
				<Store />
			</Suspense>
			);
	}
}

export default function App() {
	return (
	<Wrapper list={[
		GeopositionProvider,
		ThemeProvider,
		FeedbackProvider,
		ErrorHandlerProvider,
		OfflineProvider,
		RouterProvider,
		ReactQueryProvider,
		CompanyProvider,
		HeadProvider,
		SessionProvider,
		ConfirmDialogProvider,
	]}>
		<Switcher />
	</Wrapper>
	);
}