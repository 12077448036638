import { invalidateQueries } from "../providers/ReactQuery";
import { useMutation } from "../hooks/react-query";
import { QUERY_USER_ADDRESS, QUERY_USER_ADDRESSES, QUERY_USER_INFO } from "../queries";
import { AddressID, ResponseMessage, ResponseMessageID, UserAddress } from "../types/domain";

export type BodyUpdateUserPassword = {
    current_password: string;
    password: string;
    password_confirm: string;
};
export const useUpdateUserPassword = () => useMutation<
    BodyUpdateUserPassword,
    ResponseMessage
>(
    'PUT',
    body => ({
        url: `/user/update_password`,
        body
    })
);

export type BodyUpdateUserInfo = {
    name: string;
    phone: string;
};
export const useUpdateUserInfo = () => useMutation<
    BodyUpdateUserInfo,
    ResponseMessage
>(
    'PUT',
    body => ({
        url: `/user/info`,
        body
    }), {
        onSuccess: () => {
            invalidateQueries([
                QUERY_USER_INFO.invalidate()
            ])
        }
    }
)

export const useAddUserAddress = () => useMutation<
    UserAddress,
    ResponseMessageID
>(
    'POST',
    body => ({
        url: `/user/address`,
        body
    }), {
        onSuccess: () => {
            invalidateQueries([
                QUERY_USER_ADDRESSES.invalidate(),
            ])
        }
    }
)

export const useUpdateUserAddress = () => useMutation<
    AddressID & UserAddress,
    ResponseMessage
>(
    'PUT',
    ({ address_id, ...body }) => ({
        url: `/user/address/${address_id}`,
        body
    }), {
        onSuccess: (_, { address_id }) => {
            invalidateQueries([
                QUERY_USER_ADDRESSES.invalidate(),
                QUERY_USER_ADDRESS.invalidate({ address_id }),
            ])
        }
    }
)

export const useRemoveUserAddress = () => useMutation<
    AddressID,
    ResponseMessage
>(
    'DELETE',
    ({ address_id }) => ({
        url: `/user/address/${address_id}`
    }), {
        onSuccess: () => {
            invalidateQueries([
                QUERY_USER_ADDRESSES.invalidate()
            ])
        }
    }
)