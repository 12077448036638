import { ResponsiveFullscreenDialog } from "../../../../components/ResponsiveFullscreenDialog";
import ReceiptIcon from '@material-ui/icons/Receipt';
import { useMenuRoute, usePreviousOrdersRoute } from "../nav/routes";
import { useGetUserPreviousOrders, useGetUserPreviousOrdersPages } from "../../queries";
import { OrderSelectListItem, OrderSelectListItemPlaceholder } from "./OrderSelectListItem";
import { Pagination } from "../../../../components/Pagination";
import { Repeat } from "../../../../components/Repeat";
import { PreviousOrdersEmptyState } from "./PreviousOrdersEmptyState";

export function PreviousOrdersDialog() {

    const previousOrdersRoute = usePreviousOrdersRoute();
    const menuRoute = useMenuRoute();

    const {
        data: previousOrders,
        isLoading: previousOrdersLoading
    } = useGetUserPreviousOrders({
        page: previousOrdersRoute.params.page || 1
    });

    const {
        data: pages,
        isLoading: pagesLoading
    } = useGetUserPreviousOrdersPages();


    return (
    <ResponsiveFullscreenDialog
        open={previousOrdersRoute.matched}
        onClose={() => menuRoute.push({})}
        title="Pedidos anteriores"
        icon={<ReceiptIcon />}
        width="sm"
        bottomContent={(
            previousOrders !== undefined &&
            !previousOrdersLoading &&
            pages !== undefined &&
            !pagesLoading &&
            previousOrders.length !== 0
        ) && (
        <Pagination
            count={pages.pages || 1}
            page={previousOrdersRoute.params.page || 1}
            onChange={page => previousOrdersRoute.push({ page })}
        />
        )}
    >
        {!previousOrders || previousOrdersLoading || !pages || pagesLoading ? (
            <Repeat n={5}>
                <OrderSelectListItemPlaceholder />
            </Repeat>
        ) : previousOrders.length === 0 ? (
            <PreviousOrdersEmptyState />
        ) : previousOrders.map((order, index) => (
            <OrderSelectListItem key={index} order={order} />
        ))}
    </ResponsiveFullscreenDialog>
    );
}