import { useContext, createContext, useState, useEffect, useCallback } from "react";
import { createMuiTheme, ThemeProvider as MuiThemeProvider, lighten } from '@material-ui/core/styles'
import { Dialog, DialogTitle, DialogContent, DialogActions, Button, useMediaQuery, responsiveFontSizes } from "@material-ui/core";
import CssBaseline from '@material-ui/core/CssBaseline';
import { ProviderProps } from '../types/provider';

const type = {
	LIGHT: 'light',
	DARK: 'dark',
	SYSTEM: 'system'
}

const transitionsStorageKey = 'transitions_pref';
const themeTypeStorageKey = 'theme_type_pref';
const themeTypeLightenColor = 0.4;
const placeholderOpacity = 0.05;

const defaultPrimary = process.env.REACT_APP_PRIMARY_COLOR || '#e53935';
const defaultSecondary = process.env.REACT_APP_SECONDARY_COLOR || '#ffc107';

interface ConfirmDialogProps {
	enable: () => void;
	close: () => void;
	open: boolean;
}

export const placeholderAnimationClass = 'placeholder-animation-item';

export interface ThemeContextType {
	transitions: boolean;			
	enableTransitions: () => void;
	disableTransitions: () => void;
	themeType: string;
	enableDarkThemeType: () => void;
	enableLightThemeType: () => void;
	enableSystemThemeType: () => void;
	isDarkThemeType: boolean;
	isLightThemeType: boolean;
	isSystemThemeType: boolean;
	updateColors: (primary: string, secondary: string ) => void;
	resetColors: () => void;
	primary_color: string;
	secondary_color: string;
}

const ThemeContext = createContext({} as ThemeContextType);
const useTheme = () => useContext(ThemeContext);
export default useTheme;

function ConfirmDialog({ enable, close, open }: ConfirmDialogProps) {
	return (
	<Dialog open={open} onClose={() => close()}>
		<DialogTitle>
			Reabilitar animações
		</DialogTitle>
		<DialogContent>
			Para reabilitar as animações, será necessário recarregar a página.
		</DialogContent>
		<DialogActions>
			<Button onClick={() => close()}>
				Fechar
			</Button>
			<Button onClick={() => enable()}>
				Reabilitar
			</Button>
		</DialogActions>
	</Dialog>
	);
}


export function ThemeProvider({ children }: ProviderProps) {
	
	const prefersDarkMode = useMediaQuery('(prefers-color-scheme: dark)');
	const [ themeType, setThemeType ] = useState(localStorage.getItem(themeTypeStorageKey) || type.SYSTEM);
	const [ transitions, setTransitions ] = useState(localStorage.getItem(transitionsStorageKey) !== 'no');
	const [ dialogOpen, setDialogOpen ] = useState(false);
	const [ colors, setColors ] = useState({
		primary: defaultPrimary,
		secondary: defaultSecondary
	});

	const resetColors = useCallback(() => {
		if (colors.primary !== defaultPrimary || colors.secondary !== defaultSecondary) {
			setColors({
				primary: defaultPrimary,
				secondary: defaultSecondary
			})
		}
	}, [ colors.primary, colors.secondary ]);

	const updateColors = useCallback((primary?: string, secondary?: string) => {
		if (primary && secondary) 
			setColors({ primary, secondary });
	}, []);

	const generateTheme = useCallback(() => responsiveFontSizes(createMuiTheme({
		palette: themeType === type.DARK || (themeType === type.SYSTEM && prefersDarkMode) ? {
			type: 'dark',
			primary: {
				main: lighten(colors.primary, themeTypeLightenColor)
			},
			secondary: {
				main: lighten(colors.secondary, themeTypeLightenColor)
			}
		}: {
			type: 'light',
			primary: {
				main: colors.primary
			},
			secondary: {
				main: colors.secondary
			}
		},
		transitions: transitions ? {}: {
			create: () => 'none'
		},
		overrides: {
			MuiCssBaseline: {
				'@global': {
					"@keyframes placeholder-animation": {
						"0%": {
							backgroundPosition: "-468px 0"
						},
						"100%": {
							backgroundPosition: "468px 0"
						}
					},
					[`.${placeholderAnimationClass}`]: {
						animationDuration: "1.25s",
						animationFillMode: "forwards",
						animationIterationCount: "infinite",
						animation: '1.25s linear 0s infinite normal forwards running placeholder-animation',
						animationName: 'placeholder-animation',
						animationTimingFunction: "linear",
						backgroundSize: '936px 1px',
						color:'transparent !important',
						...(themeType === type.DARK || (themeType === type.SYSTEM && prefersDarkMode) ? {
							backgroundColor: `rgba(255, 255, 255, ${placeholderOpacity}) !important`,
							backgroundImage: `linear-gradient(to right, rgba(255, 255, 255, ${placeholderOpacity}) 10%, rgba(255, 255, 255, ${placeholderOpacity+0.1}) 18%, rgba(255, 255, 255, ${placeholderOpacity}) 33%) !important`
						}: {
							backgroundColor: `rgba(0, 0, 0, ${placeholderOpacity}) !important`,
							backgroundImage: `linear-gradient(to right, rgba(0, 0, 0, ${placeholderOpacity}) 10%, rgba(0, 0, 0, ${placeholderOpacity+0.1}) 18%, rgba(0, 0, 0, ${placeholderOpacity}) 33%) !important`
						})

					},
					...(transitions ? {} : {
						'*, *::before, *::after': {
							transition: 'none !important',
							animation: 'none !important',
						}
					})
				}
			}
		},
		props: transitions ? {}: {
			MuiButtonBase: {
				disableRipple: !transitions,
			}
		}
	})), [
		colors.primary,
		colors.secondary,
		prefersDarkMode,
		themeType,
		transitions
	]);

	const [ theme, setTheme ] = useState(generateTheme());

	useEffect(() => {
		localStorage.setItem(transitionsStorageKey, transitions ? 'yes': 'no');
	}, [ transitions ]);

	useEffect(() => {
		localStorage.setItem(themeTypeStorageKey, themeType);
	}, [ themeType ]);

	useEffect(() => {
		setTheme(generateTheme());
	// eslint-disable-next-line
	}, [
		themeType,
		transitions,
		colors
	]);

	return (
	<ThemeContext.Provider
		value={{
			transitions,			
			enableTransitions: () => transitions || setDialogOpen(true),
			disableTransitions: () => setTransitions(false),
			themeType,
			enableDarkThemeType: () => setThemeType(type.DARK),
			enableLightThemeType: () => setThemeType(type.LIGHT),
			enableSystemThemeType: () => setThemeType(type.SYSTEM),
			isDarkThemeType: themeType === type.DARK,
			isLightThemeType: themeType === type.LIGHT,
			isSystemThemeType: themeType === type.SYSTEM,
			updateColors,
			resetColors,
			primary_color: colors.primary,
			secondary_color: colors.secondary
		}}
	>
		<MuiThemeProvider theme={theme}>
			<CssBaseline />
			<ConfirmDialog
				open={dialogOpen}
				close={() => setDialogOpen(false)}
				enable={() => {
					setDialogOpen(false);
					setTransitions(true);
					setTimeout(() => window.location.reload(), 500);
				}}
			/>
			{children}
		</MuiThemeProvider>
	</ThemeContext.Provider>
	);
};
