import { makeStyles } from '@material-ui/core/styles';
import { Avatar } from '@material-ui/core';
import FastfoodIcon from '@material-ui/icons/Fastfood';
import CloseIcon from '@material-ui/icons/Close';
import { ReactElement } from 'react';
import { useImageSource } from '../hooks/gallery';

const useStyles = makeStyles(theme => ({
	avatar: {
		boxShadow: theme.shadows[3],
		color: theme.palette.secondary.contrastText,
		backgroundColor: theme.palette.secondary.main
	},
	amountIcon: {
		fontSize: theme.typography.fontSize*0.75,
	},
	amountOuterContainer: {
		position: 'relative',
	},
	amountContainer: {
		position: 'absolute',
		display: 'flex',
		width: `calc(100% - ${theme.spacing(2)}px)`,
		height: '100%',
		alignItems: 'center',
		justifyContent: 'center',
		backgroundColor: 'rgba(0,0,0,0.5)',
		borderRadius: theme.spacing(3),
		top: 0,
		left: 0,
		fontSize: theme.typography.fontSize*1.2,
		fontWeight: theme.typography.fontWeightBold,
		color: theme.palette.common.white,
	},
	avatarAmountIcon: {
		fontSize: theme.typography.fontSize,
	},
}));

type AmountProps = {
	children: ReactElement<any, any>;
	withAmount?: boolean;
	amount?: number;
}

function Amount({ children, withAmount, amount }: AmountProps) {
	const classes = useStyles();
	if (withAmount) {
		return (
		<div className={classes.amountOuterContainer}>
			{children}
			<div className={classes.amountContainer}>
				{amount}<CloseIcon className={classes.avatarAmountIcon} />
			</div>
		</div>
		);
	}
	return children;
}

type Props = {
	image_id: string | null;
	name: string;
	withAmount?: boolean;
	amount?: number;
}

export default function ProductAvatar({ image_id, name, withAmount, amount }: Props) {
	const classes = useStyles();
	const { small } = useImageSource(image_id);
	return (
	<Amount
		withAmount={withAmount}
		amount={amount}
	>
		<Avatar
			alt={name}
			src={small}
			className={classes.avatar}
		>
			<FastfoodIcon />
		</Avatar>
	</Amount>
	);
}