import { UserOrdersSvg } from '../../../../svg';
import { BaseEmptyState } from '../../../../components/BaseEmptyState';

export function PreviousOrdersEmptyState() {
	return (
	<BaseEmptyState
		message="Sem pedidos anteriores"
		SvgComponent={UserOrdersSvg}
	/>
	);
}
