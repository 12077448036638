import * as React from "react";
import SVGProps from "./interfaces/props";
import { useTheme } from "@material-ui/core";

const SvgEmptyBagSvg = (props: SVGProps): JSX.Element => {
  const theme = useTheme();
  return (
    <svg
      width={710.513}
      height={566.438}
      viewBox="0 0 187.99 149.87"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <ellipse
        cx={108.5}
        cy={216.8}
        rx={80.469}
        ry={27.224}
        opacity={0.47}
        transform="translate(-7.103 -94.155)"
      />
      <g transform="translate(-7.103 -94.155)">
        <path
          d="M113.748 94.653l7.862 4.068-7.85-3.56-26.25 46.57-36.412.201 21.514 68.614 47.855-.006 51.172.006 22.758-68.614-38.62-.202-26.252-46.569-7.85 3.56 7.862-4.068zm7.894 4.084h.002l24.206 42.941-25.115-.131-23.298.129z"
          fill={theme.palette.secondary.main}
          stroke={theme.palette.secondary.main}
          strokeWidth={1.0001124}
        />
        <path
          d="M113.748 94.653l5.125 2.652 4.791-2.652zm10.637 0l-5.489 2.664 2.714 1.404-2.899-1.315-.165.08.154-.085-2.044-.927 1.828 1.012-4.738-2.299L87.51 141.73l-1.827.01-.018.035-34.55.206 21.5 68.565 47.855-.006 46.818.006 21.254-68.56-37.17-.21-24.036-45.618-5.657 2.564 5.507-2.849-.642-1.218zm-5.871 2.85h.002l.652 1.237.927.388 1.073.45.474-.841h.002l24.206 42.941-4.07-.02.033.064-24.17-.136-22.425.133z"
          fill="#fff"
          opacity={0.288}
          stroke="#fff"
          strokeWidth={0.9956145399999999}
        />
        <circle
          cx={120.56}
          cy={173.98}
          r={9.495}
          stroke="#fff"
          strokeWidth={0.265}
        />
      </g>
      <text
        transform="rotate(-25.926 -724.793 48.011) scale(5.6447)"
        fill={theme.palette.text.hint}
        fontFamily="sans-serif"
        fontSize={10.583}
      >
        <tspan x={-10.803} y={68.396}>
          <tspan>{"?"}</tspan>
        </tspan>
      </text>
      <g strokeWidth={0}>
        <path
          d="M34.014 67.054s15.011-4.712 34.522 2.582c19.513 7.294 24.57 21.986 24.57 21.986s14.852 39.734-22.536 42.03c-37.387 2.294-44.73-1.337-48.186-2.312S.131 125.932 0 104.782s11.956-27.645 11.956-27.645 8.092-6.494 19.364-9.361c11.272-2.867 2.693-.722 2.693-.722z"
          fill={theme.palette.primary.main}
        />
        <path
          d="M33.706 66.761s14.856-4.444 34.19 2.51c19.334 6.953 24.368 20.9 24.368 20.9s14.774 37.722-22.244 39.836c-37.018 2.113-44.295-1.344-47.72-2.275-3.424-.93-22.045-5.169-22.21-25.234C-.077 82.433 11.881 76.29 11.881 76.29s8-6.147 19.158-8.848c11.157-2.7 2.665-.68 2.665-.68z"
          fill="#fff"
          opacity={0.295}
        />
        <path d="M26.478 82.727c1.64.068 3.378 1.362 2.855 2.688-3.074 7.803-1.992 14.669-.883 21.528.227 1.406-1.426 2.512-3.066 2.445s-2.698-1.273-2.856-2.688c-.756-6.781-1.073-13.727.884-21.528.346-1.38 1.426-2.512 3.066-2.445zM52.552 84.534c1.64.067 3.38 1.311 2.86 2.58-3.06 7.474-1.967 14.056-.846 20.631.23 1.348-1.422 2.405-3.062 2.338-1.64-.067-2.7-1.225-2.86-2.58-.768-6.501-1.097-13.159.847-20.631.344-1.323 1.421-2.405 3.061-2.338z" />
        <path d="M45.95 80.675c.543-1.146 2.045-1.959 2.89-1.153 4.978 4.743 10.496 6.219 16.017 7.674 1.131.298 1.47 1.837.926 2.983-.544 1.146-1.774 1.504-2.891 1.154-5.356-1.68-10.705-3.729-16.016-7.674-.94-.699-1.471-1.838-.927-2.984zM14.954 85.73c-.304-1.147.274-2.641 1.363-2.583 6.41.342 11.232-1.97 16.043-4.3.986-.477 2.158.412 2.462 1.56.304 1.147-.356 2.152-1.363 2.583-4.83 2.069-9.877 3.871-16.044 4.3-1.091.075-2.157-.412-2.461-1.56z" />
      </g>
    </svg>
  );
};

export default SvgEmptyBagSvg;
