import { TextField } from "@material-ui/core";
import NumberFormat from "react-number-format";

type Props = {
    onChange?: (newValue: number) => void;
    name?: string;
    label: string;
    value?: number;
    disabled?: boolean;
    error?: string;
    onBlur?: (newValue: number) => void;
    defaultValue?: number;
}
export default function IntegerInput({
    onChange,
    name,
    label,
    value,
    disabled,
    error,
    defaultValue,
    onBlur
}: Props) {
    return (
    <NumberFormat
        customInput={TextField}
        isNumericString
        onValueChange={valuer => (
            onChange &&
            valuer.floatValue !== undefined &&
            onChange(valuer.floatValue)
        )}
        allowLeadingZeros={false}
        allowNegative={false}
        thousandSeparator="."
        decimalSeparator=","
        decimalScale={0}

        fullWidth
        defaultValue={defaultValue}
        name={name}
        label={label}
        value={value}
        type="tel"
        color="secondary"
        error={error !== undefined}
        helperText={error}
        disabled={disabled}
        onBlur={e => {
            const strValue = e.currentTarget.value;
            if (onBlur && Number.isFinite(Number(strValue))) {
                onBlur(Number(strValue));
            }
        }}
    />
    );
}