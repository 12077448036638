import { Button, Typography, Dialog, DialogActions, DialogContent, DialogTitle, makeStyles } from "@material-ui/core";
import { Breakpoint } from "@material-ui/core/styles/createBreakpoints";
import clsx from "clsx";
import { ReactNode } from "react";


const useStyles = makeStyles(theme => ({
    content: {
        paddingBottom: theme.spacing(2)
    },
    disabledPadding: {
        padding: 0
    },
    dialogTitle: {
        display: 'flex',
        flexDirection: 'row',
    },
    title: {
        display: 'flex',

    },
    titleIcon: {
        display: 'flex',
        marginRight: theme.spacing(2),
        alignItems: 'center'
    }
}));


type Props = {
    open: boolean;
    onClose?: () => void;
    title: string;
    children: ReactNode;
    noPadding?: boolean;
    action?: ReactNode;
    icon?: JSX.Element;
    maxWidth?: Breakpoint;
}
export default function DomainDialog({
    open,
    onClose,
    title,
    children,
    noPadding=false,
    action,
    icon,
    maxWidth="sm",
}: Props) {
    const classes = useStyles();
    return (
    <Dialog
        open={open}
        onClose={onClose}
        maxWidth={maxWidth}
        fullWidth
    >
        <DialogTitle
            className={classes.dialogTitle}
            disableTypography
        >
            {icon && <div className={classes.titleIcon}>{icon}</div>}
            <Typography
                variant="h5"
                className={classes.title}
                noWrap
            >
                {title}
            </Typography>
        </DialogTitle>
        <DialogContent
            dividers
            className={clsx({
                [classes.content]: true,
                [classes.disabledPadding]: noPadding
            })}
        >
            {children}
        </DialogContent>
        {(onClose !== undefined || action !== undefined) && (
        <DialogActions>
            {onClose !== undefined && <Button onClick={onClose}>Fechar</Button>}
            {action}
        </DialogActions>
        )}
    </Dialog>
    );
}