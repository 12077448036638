import { Container, Slide, makeStyles, Grow, Button, Badge, useTheme, useMediaQuery } from "@material-ui/core";
import clsx from "clsx";
import { useMemo } from "react";
import { placeholderAnimationClass } from "../../../../providers/Theme";

const useStyles = makeStyles(theme => ({
    bottomBar: {
        padding: theme.spacing(1),
        backgroundColor: theme.palette.background.paper,
        boxShadow: theme.shadows[3],
        zIndex: theme.zIndex.appBar,
        position: 'fixed',
        bottom: 0,
        width: '100vw'
    },
    bottomBarContainer: {
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        flexGrow: 1
    },
    bottomBarButton: {
        display: 'flex',
        borderRadius: theme.shape.borderRadius*4,
        flexGrow: 0.5
    },
    bottomBarButtonMarginLeft: {
        marginLeft: theme.spacing(1)
    }
}));

export type BottomBarItem = {
    labelXs: string;
    label: string;
    icon: JSX.Element;
    badge?: number | false;
    color: 'primary' | 'secondary';
    onClick: () => void;
    loading: boolean;
}

type BottomBarProps = {
    items: BottomBarItem[];
}

export function BottomBar({ items }: BottomBarProps) {

    const classes = useStyles();

    const theme = useTheme();
    const xs = useMediaQuery(theme.breakpoints.down('xs'));

    const growIn = useMemo(() => items.reduce((acc, item) => (
        (item.badge !== undefined && item.badge > 0) || acc
    ), false ), [ items ]);

    return (
    <Slide direction="up" in={growIn} unmountOnExit>
        <div className={classes.bottomBar}>
            <Container maxWidth="md" disableGutters className={classes.bottomBarContainer}>
                {items.map((item, index) => (
                <Grow
                    in={item.badge !== undefined && item.badge > 0}
                    key={index}
                    unmountOnExit
                >
                    <Button
                        onClick={item.onClick}
                        className={clsx(
                            classes.bottomBarButton,
                            item.loading && placeholderAnimationClass,
                            index > 0 && classes.bottomBarButtonMarginLeft
                        )}
                        color={item.color}
                        variant="contained"
                        size="large"
                        startIcon={!item.loading && (
                            <Badge
                                badgeContent={item.badge}
                                color={item.color === "primary" ? "secondary" : "primary"}
                            >
                                {item.icon}
                            </Badge>
                        )}
                    >
                        {xs ? item.labelXs : item.label}
                    </Button>
                </Grow>
                ))}
            </Container>
        </div>
    </Slide>
    );
}