import { makeStyles } from "@material-ui/core";
import { ResponsiveFullscreenDialog } from "../../../../components/ResponsiveFullscreenDialog";
import { useBagRoute, useCheckoutRoute } from "../nav/routes";
import { DeliveryMethodPicker } from "./DeliveryMethodPicker";
import { ValueButton } from "../../components/ValueButton";
import { useBag } from "../bag/BagProvider";
import SendIcon from '@material-ui/icons/Send';
import CheckIcon from '@material-ui/icons/Check';
import { useGetCompanyFullInfo } from "../../queries";
import DeliveryMethod from "../../../../constants/delivery_method";
import Subtitle from "../../../../components/Subtitle";
import { TextInput } from "../../../../components/TextInput";
import { useEffect, useState } from "react";
import { useAppMode } from "../nav/AppModeProvider";
import { AppModeEnum } from "../../../../constants/app_mode";

const useStyles = makeStyles(theme => ({
    checkoutDialogFreeNoteContainer: {
        padding: theme.spacing(2)
    }
}));

export function CheckoutDialog() {
    const bagRoute = useBagRoute();
    const checkoutRoute = useCheckoutRoute();

    const classes = useStyles();

    const { sendOrder, addOrderMutation, bag, updateNote } = useBag();

    const {
        data: company,
        isLoading: companyLoading
    } = useGetCompanyFullInfo();

    const deliveryTax = bag.delivery_method_id === DeliveryMethod.DELIVERY ? company?.delivery_tax || 0 : 0

    const [ note, setNote ] = useState(bag.note);

    const { appModeContent } = useAppMode();

    useEffect(() => {
        if (bag.note.length === 0) {
            setNote("");
        }
    }, [ bag.note ]);

    const returnTitle = ()  => {
        if (DeliveryMethod.TABLE === bag.delivery_method_id && bag.table != 0){
            return ("Total do Pedido da Mesa " + bag.table)
        } 
        return("Total do Pedido")
        
    }
    return (
    <ResponsiveFullscreenDialog
        open={checkoutRoute.matched}
        onClose={() => bagRoute.push({})}
        icon={<CheckIcon />}
        title={returnTitle()}
        width="sm"
        bottomContent={(
            <ValueButton
                title="Total do pedido"
                value={bag.price + deliveryTax}
                icon={<SendIcon fontSize="large" />}
                tooltip="Enviar pedido"
                onClick={sendOrder}
                disabled={companyLoading}
                actionLabel="Enviar"
            />
        )}
    >
        {appModeContent.mode !== AppModeEnum.TABLE && (
            <DeliveryMethodPicker addOrderMutation={addOrderMutation} />
        )}
        <Subtitle divider>Observações</Subtitle>
        <div className={classes.checkoutDialogFreeNoteContainer}>
            <TextInput
                onBlur={() => updateNote(note)}
                value={note}
                onChange={value => setNote(value)}
                multiline
                fullWidth
                variant="filled"
                rows={2}
                maxChar={300}
                rowsMax={4}
                label={"Observações do pedido"}
                placeholder={`Digite suas observações aqui...`}
                error={addOrderMutation.error?.validationErrors.note !== undefined}
                helperText={addOrderMutation.error?.validationErrors.note}
            />
        </div>
    </ResponsiveFullscreenDialog>
    );
}