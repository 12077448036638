import { createContext, useCallback, useContext, useState } from "react";
import { HelmetProvider, Helmet } from "react-helmet-async";
import { useImageSource } from "../hooks/gallery";
import { ProviderProps } from "../types/provider";
import useTheme from "./Theme";

const defaultIcon = `${process.env.PUBLIC_URL}/default-logo-svg.svg`;

const useHeadProviderState = () => {

    const { primary_color } = useTheme();

    const [ title, setTitle ] = useState<string>(process.env.REACT_APP_NAME || '');

    const [ iconID, setIconID ] = useState<string | null>(null);

    const {
        small
    } = useImageSource(iconID);

    const internalSetTitle = useCallback((newTitle: string) => {
        setTitle(
            `${newTitle}${
                process.env.REACT_APP_NAME ?
                ` - ${process.env.REACT_APP_NAME}` : ''
            }`)
    }, [])

    const internalSetIconID = useCallback((image_id: string) => {
        setIconID(image_id);
    }, [ ]);

    return {
        setTitle: internalSetTitle,
        title,
        themeColor: primary_color,
        iconPath: small === '' ? defaultIcon : small,
        setIconID: internalSetIconID
    };
}


const HeadContext = createContext({} as Omit<
    ReturnType<typeof useHeadProviderState>,
    'title' | 'themeColor' | 'iconPath'
>)
export const useHead = () => useContext(HeadContext);

export function HeadProvider({ children }: ProviderProps) {

    const {
        setTitle,
        title,
        themeColor,
        iconPath,
        setIconID
    } = useHeadProviderState();

    return (
    <HeadContext.Provider value={{
        setTitle,
        setIconID
    }}>
        <HelmetProvider>
            <Helmet>
                <title>{title}</title>
                <link id="favicon" rel="icon" href={iconPath} />
                <meta name="theme-color" content={themeColor} />
                <link rel="apple-touch-icon" href={iconPath} />
            </Helmet>
            {children}
        </HelmetProvider>
    </HeadContext.Provider>
    );
}