export default function generateConstantsUtils<
    PropertieKey extends number,
    Propertie
>(
    properties: { [key in PropertieKey]: Propertie }
) {
    return {
        ...properties,
        stdMap: <T>(
            idList : PropertieKey[],
            mapper : (properties : Propertie, id : PropertieKey) => T
        ) : T[] => (
            idList.map(id => mapper(properties[id], id))
        ),
        map: <T>(
            idList : PropertieKey[],
            mapper : { [key in PropertieKey]?: (properties : Propertie, id : PropertieKey) => T}
        ) : T[] => (
            idList.map(id => {
                const func = mapper[id];
                if (func) return func(properties[id], id);
                return null;
            })
            .filter((el : T | null) => el !== null) as T[]
        ),
        switch: <T>(
            id: PropertieKey,
            mapper:  { [key in PropertieKey]?: T | ((properties : Propertie, id : PropertieKey) => T)}
        ) : T | null => {
            if (!id) return null;
            const mappedValue = mapper[id] as T | ((properties : Propertie, id : PropertieKey) => T) | undefined;
            if (mappedValue === undefined)
                return null
            if (mappedValue instanceof Function) 
                return mappedValue(properties[id], id)
            return mappedValue;
        }
    }
}

