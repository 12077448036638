import { createContext, useContext, useState, useEffect, ReactNode } from 'react';
import { BrowserRouter, useRouteMatch } from 'react-router-dom';

type SubjectMatch = {
	subject : string;
}

export interface RouterContextType {
	pathPrefix : string;
	subject : string;
}

export const especialSubjects = {
	NONE: '',
	PORTAL: process.env.REACT_APP_PORTAL_SUBJECT || "portal",
	SESSION: process.env.REACT_APP_SESSION_SUBJECT || "session",
	PRINTABLES: process.env.REACT_APP_PRINTABLES_SUBJECT || "printables",
}

// function getSubdomain() : string {
// 	if (
// 		process.env.NODE_ENV === 'development' ||
// 		window.location.hostname === 'localhost' ||
// 		window.location.hostname === '[::1]' ||
// 		window.location.hostname === 'penguin.linux.test' ||
// 		window.location.hostname.match(
// 			/^127(?:\.(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)){3}$/
// 		)
// 	) {
// 		return especialSubjects.NONE;
// 	}

// 	if (!process.env.REACT_APP_DOMAIN) return especialSubjects.NONE;

// 	const subdomains = window.location.hostname.replace(
// 		process.env.REACT_APP_DOMAIN,
// 		''
// 	).split(".").filter(p => p.length > 0 && p !== "www");

// 	return subdomains.length >= 1 ? subdomains[0] : especialSubjects.NONE;
// }

const RouterContext = createContext({} as RouterContextType); 
const useRouter = () => useContext(RouterContext);
export default useRouter;

function InnerRouterProvider({ children } : { children: ReactNode }) {

	const match = useRouteMatch<SubjectMatch>({
		path: "/:subject",
		strict: false,
		sensitive: false
	});

	const [ subject, setSubject ] = useState(match?.params.subject || especialSubjects.NONE);
	const [ pathPrefix, setPathPrefix ] = useState(especialSubjects.NONE ? `/${subject}` : '');

	useEffect(() => {
		setPathPrefix(`/${subject}`);
	// eslint-disable-next-line
	}, [ subject ]);

	useEffect(() => {
		setSubject(match?.params.subject || especialSubjects.NONE)
	// eslint-disable-next-line
	}, [ match ]);

	return (
	<RouterContext.Provider value={{ subject, pathPrefix }}>
		{children}
	</RouterContext.Provider>
	);
}

export function RouterProvider({ children } : { children: ReactNode }) {
	return (
	<BrowserRouter>
		<InnerRouterProvider>
			{children}
		</InnerRouterProvider>
	</BrowserRouter>
	)
}
