import { newQuery, useQuery } from "../hooks/react-query";
import {  AddressID, UserAddress, UserInfo, WithID } from "../types/domain";


// USER INFO
export type ResponseUserInfo = UserInfo;
export const QUERY_USER_INFO = newQuery('/user/info')<{}, ResponseUserInfo>();
export const useGetUserInfo = () => useQuery(
    QUERY_USER_INFO,
    {}
);


// USER_ADDRESSES
export type ResponseUserAddress = WithID<UserAddress>;
export const QUERY_USER_ADDRESSES = newQuery('/user/address')<{}, ResponseUserAddress[]>()
export const useGetUserAddresses = () => useQuery(
    QUERY_USER_ADDRESSES,
    {}
);

export const QUERY_USER_ADDRESS = newQuery('/user/address/:address_id')<
    AddressID,
    ResponseUserAddress
>()
export const useGetUserAddress = (params: AddressID) => useQuery(
    QUERY_USER_ADDRESS,
    params
);