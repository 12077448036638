import { Box, Button, Divider, makeStyles, Typography } from "@material-ui/core";
import { createContext, useContext, useEffect, useState } from "react";
import { ProviderProps } from "../../../../types/provider";
import AssignmentIndIcon from '@material-ui/icons/AssignmentInd';
import { ResponsiveFullscreenDialog } from "../../../../components/ResponsiveFullscreenDialog";
import LinkIcon from '@material-ui/icons/Link';
import LoginForm from "../../../../forms/LoginForm";
import RegisterForm from "../../../../forms/RegisterForm";
import SendPasswordRecoverForm from "../../../../forms/SendPasswordRecoverForm";
import LockIcon from '@material-ui/icons/Lock';
import { useSession } from "../../../../providers/Session";
import { GoogleButton } from "./GoogleButton";

<script src="https://apis.google.com/js/platform.js" async defer></script>

const useProfileProviderState = () => {

    const { loggedIn } = useSession();

    const [ registerVisible, setRegisterVisible ] = useState(false);
    const [ loginVisible, setLoginVisible ] = useState(false);
    const [ connectVisible, setConnectVisible ] = useState(false);
    const [ passwordRecoverVisible, setPasswordRecoverVisible ] = useState(false);

    useEffect(() => {
        if (loggedIn) {
            if (registerVisible) {
                setRegisterVisible(false);
            }
            if (loginVisible) {
                setLoginVisible(false);
            }
            if (connectVisible) {
                setConnectVisible(false);
            }
            if (passwordRecoverVisible) {
                setPasswordRecoverVisible(false);
            }
        }
    }, [
        loggedIn,
        registerVisible,
        loginVisible,
        connectVisible,
        passwordRecoverVisible,
    ]);

    return {
        registerVisible: !loggedIn && registerVisible,
        loginVisible: !loggedIn && loginVisible,
        connectVisible: !loggedIn && connectVisible,
        passwordRecoverVisible: !loggedIn && passwordRecoverVisible,
        openRegister: () => setRegisterVisible(true),
        closeRegister: () => {
            setConnectVisible(true);
            setRegisterVisible(false);
        },
        openLogin: () => setLoginVisible(true),
        closeLogin: () => {
            setConnectVisible(true);
            setLoginVisible(false);
        },
        openPasswordRecover: () => {
            setLoginVisible(false);
            setPasswordRecoverVisible(true);
        },
        closePasswordRecover: () => {
            setLoginVisible(true);
            setPasswordRecoverVisible(false);
        },
        openConnect: () => setConnectVisible(true),
        closeConnect: () => setConnectVisible(false)
    };
}

const ProfileContext = createContext(
    {} as Pick<
        ReturnType<typeof useProfileProviderState>,
        'openRegister' | 'openLogin' | 'openConnect' | 'openPasswordRecover'
    >
);
export const useProfile = () => useContext(ProfileContext);
export function ProfileProvider({ children }: ProviderProps) {

    const {
        registerVisible,
        loginVisible,
        connectVisible,
        passwordRecoverVisible,
        openRegister,
        closeRegister,
        openLogin,
        closeLogin,
        openConnect,
        closeConnect,
        openPasswordRecover,
        closePasswordRecover,
    } = useProfileProviderState();


    return (
    <ProfileContext.Provider value={{
        openRegister,
        openLogin,
        openConnect,
        openPasswordRecover
    }}>
        <ConnectDialog
            visible={connectVisible}
            close={closeConnect}
        />
        <RegisterDialog
            visible={registerVisible}
            close={closeRegister}
        />
        <LoginDialog
            visible={loginVisible}
            close={closeLogin}
        />
        <PasswordRecoverDialog
            visible={passwordRecoverVisible}
            close={closePasswordRecover}
        />
        {children}
    </ProfileContext.Provider>
    );
}

const useStyles = makeStyles(theme => ({
    connectDialogDivider: {
        marginTop: theme.spacing(1),
        marginBottom: theme.spacing(2)
    },
    dialogPadding: {
        padding: theme.spacing(2)
    },
    connectDialogButton: {
        marginBottom: theme.spacing(3),
        marginTop: theme.spacing(1)
    }
}));

type DialogProps = {
    visible: boolean;
    close: () => void;
}

function ConnectDialog({ visible, close }: DialogProps) {
    const classes = useStyles();

    const { openRegister, openLogin } = useProfile();


    
    return (

    <ResponsiveFullscreenDialog
        open={visible}
        onClose={close}
        title="Conecte-se"
        icon={<LinkIcon />}
        width="sm"
        withPadding
    >
        
        <Typography variant="h5" gutterBottom>
            Novo por aqui? 
        </Typography>
        <Divider className={classes.connectDialogDivider} />
        
        <Typography gutterBottom>
            Clique aqui para entrar com a sua conta Google!            
        </Typography>
        <Typography gutterBottom>
        *Para entrar com o Google, por gentileza utilize o navegador de seu celular, sendo ele Google Chrome ou Safari.*
        </Typography>
        <GoogleButton  />

        <Divider className={classes.connectDialogDivider} />


        <Typography gutterBottom>
            Ou então, clique aqui para criar uma nova conta
        </Typography>
        <Button
            variant="outlined"
            color="primary"
            size="large"
            fullWidth
            className={classes.connectDialogButton}
            startIcon={<AssignmentIndIcon />}
            onClick={() => {
                close();
                openRegister();
            }}
        >
            Cadastrar
        </Button>

        <Typography variant="h5" gutterBottom>
            Já é cadastrado?
        </Typography>
        <Divider className={classes.connectDialogDivider} />
        <Typography gutterBottom>
            Insira seu e-mail e senha e prossiga para finalizar seu pedido!
        </Typography>
        <Button
            variant="outlined"
            color="primary"
            size="large"
            fullWidth
            className={classes.connectDialogButton}
            startIcon={<LinkIcon />}
            onClick={() => {
                close();
                openLogin();
            }}
        >
            Conectar
        </Button>

        

 


  
    </ResponsiveFullscreenDialog>
    );
}

function RegisterDialog({ visible, close }: DialogProps) {

    return (
    <ResponsiveFullscreenDialog
        open={visible}
        onClose={close}
        title="Cadastro"
        icon={<AssignmentIndIcon />}
        width="sm"
        withPadding
    >
        <RegisterForm
            onSuccess={() => {
                close();
            }}
        />
    </ResponsiveFullscreenDialog>
    );
}

function LoginDialog({ visible, close }: DialogProps) {
    const { openPasswordRecover } = useProfile();

    return (
    <ResponsiveFullscreenDialog
        open={visible}
        onClose={close}
        title="Fazer login"
        icon={<LinkIcon />}
        width="sm"
        withPadding
    >
        <LoginForm
            onSuccess={() => {
                close();
            }}
        />
        <Box mt={1}>
            <Button
                fullWidth
                color="primary"
                onClick={() => openPasswordRecover()}
            >
                Esqueceu sua senha?
            </Button>
        </Box>
    </ResponsiveFullscreenDialog>
    );
}

function PasswordRecoverDialog({ visible, close }: DialogProps) {
    return (
    <ResponsiveFullscreenDialog
        open={visible}
        onClose={close}
        title="Recuperar senha"
        icon={<LockIcon />}
        width="sm"
        withPadding
    >
        <SendPasswordRecoverForm />
    </ResponsiveFullscreenDialog>
    );
}