import Form, { InputProps } from '../components/Form';
import { useLogin, BodyLogin } from '../mutations';
import { MutationOptions } from '../hooks/react-query';
import { ResponseMessage } from '../types/domain';

const inputs: InputProps<keyof BodyLogin, BodyLogin>[] = [{
    name: 'email',
    type: 'email',
    label: 'E-mail',
}, {
    name: 'password',
    type: 'current-password',
    label: 'Senha',
}];


type Props = MutationOptions<BodyLogin, ResponseMessage>
export default function LoginForm({
    ...options
}: Props) {
    const login = useLogin();
    return (
    <Form
        submitLabel='Conectar-se'
        mutation={login}
        fixedValues={{}}
        inputs={inputs}
        {...options}
    />
    )
}