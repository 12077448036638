import { Box, Chip, Grow, List, ListItem, ListItemIcon, ListItemSecondaryAction, ListItemText, makeStyles, Radio, Typography } from "@material-ui/core";
import Subtitle from "../../../../components/Subtitle";
import DeliveryMethod, { DeliveryMethodUtils } from "../../../../constants/delivery_method";
import { useGetCompanyFullInfo } from "../../queries";
import AddIcon from '@material-ui/icons/Add';
import { useBag } from "../bag/BagProvider";
import { formatAddress, formatMoney } from "../../../../utils/format";
import { CharactersPlaceholder } from "../../../../placeholders/CharactersPlaceholder";
import { ListItemPlaceholder } from "../../../../placeholders/ListItemPlaceholder";
import { Repeat } from "../../../../components/Repeat";
import IntegerInput from "../../../../components/IntegerInput";
import ListSelect from "../../../../components/ListSelect";
import { useGetUserAddresses } from "../../../../queries";
import InfoIcon from '@material-ui/icons/Info';
import { CompanyAddress, WithID } from "../../../../types/domain";
import { PaymentMethodPicker } from "./PaymentMethodPicker";
import AssignmentIndIcon from '@material-ui/icons/AssignmentInd';
import { useSession } from "../../../../providers/Session";
import { useProfile } from "../profile/ProfileProvider";
import useUser from "../../../../providers/User";
import { AddressIcon } from "../../../../components/Icons";
import { useAddOrder } from "../../mutations";
import { useAppMode } from "../nav/AppModeProvider";
import { AppModeEnum } from "../../../../constants/app_mode";


type MutationProp = {
	addOrderMutation: ReturnType<typeof useAddOrder>;
}

export function DeliveryMethodPicker({ addOrderMutation }: MutationProp) {

	const {
		data: company,
		isLoading: companyLoading
	} = useGetCompanyFullInfo();

	const {
		bag,
		selectDeliveryMethod,
	} = useBag();



	if (!company || companyLoading) {
		return (
			<DeliveryMethodPickerPlaceholder />
		);
	}

	const companyWithdrawAddress = company.addresses.find(({ id }) => (
		id === company.withdraw_address_id
	)); 

	return (<>
	<Subtitle divider>Método de entrega</Subtitle>
	<List>
		{DeliveryMethodUtils.map(company.delivery_methods, {
				[DeliveryMethod.DELIVERY]: (method, id) => (
				<MethodListItem
					key={id}
					onClick={() => selectDeliveryMethod(id)}
					checked={bag.delivery_method_id === id}
					icon={method.icon}
					label={method.description}
					value={company.delivery_tax}
				/>
				),
				[DeliveryMethod.LOCAL]: (method, id) => (
				<MethodListItem
					key={id}
					onClick={() => selectDeliveryMethod(id)}
					checked={bag.delivery_method_id === id}
					icon={method.icon}
					label={method.description}
				/>
				),
				[DeliveryMethod.TABLE]: (method, id) => (
				<MethodListItem
					key={id}
					onClick={() => selectDeliveryMethod(id)}
					checked={bag.delivery_method_id === id}
					icon={method.icon}
					label={method.description}
				/>
				)
			})}
	</List>
	{DeliveryMethodUtils.switch(bag.delivery_method_id, {
		[DeliveryMethod.TABLE]: (
			<TableAdditionalOptions
				addOrderMutation={addOrderMutation}
			/>
		),
		[DeliveryMethod.LOCAL]: (
			<LocalAdditionalOptions companyWithdrawAddress={companyWithdrawAddress} />
		),
		[DeliveryMethod.DELIVERY]: (
			<DeliveryAdditionalOptions />
		) 
		})}
	</>);
}

export function DeliveryMethodPickerPlaceholder() {
	return (
	<>
		<Subtitle divider><CharactersPlaceholder n={8} /></Subtitle>
		<List>
			<Repeat n={2}>
				<ListItemPlaceholder avatar secondaryAction />
			</Repeat>
		</List>
	</>);
}

const useStyles = makeStyles(theme => ({
	deliveryContainer: {
		display: 'flex',
		justifyContent: 'space-between',
		alignItems: 'center',
	},
	deliveryChip: {
		borderColor: theme.palette.success.main,
		color: theme.palette.success.main,
		marginRight: theme.spacing(1)
	},
	deliveryChipIcon: {
		color:  theme.palette.success.main
	}
}))

type MethodListItemProps = {
	onClick : () => void;
	icon : JSX.Element;
	label : string;
	checked : boolean;
	value? : number;
};
function MethodListItem({ onClick, icon, label, checked, value } : MethodListItemProps) {
	const classes = useStyles();
	return (
	<ListItem
		button
		onClick={onClick}
	>
		<ListItemIcon>
			{icon}
		</ListItemIcon>
		<ListItemText
			primaryTypographyProps={{
				component: "div"
			}}
		>
			{value === 0 || value === undefined ? label : (
			<div className={classes.deliveryContainer}>
				<Typography>
					{label}
				</Typography>
				<Chip
					variant="outlined"
					size="small"
					icon={<AddIcon
						fontSize="small"
						className={classes.deliveryChipIcon}
					/>}
					className={classes.deliveryChip}
					label={formatMoney(value || 0)}
				/>
			</div>
			)}
		</ListItemText>
		<ListItemSecondaryAction>
			<Radio
				checked={checked}
				onChange={onClick}
			/>
		</ListItemSecondaryAction>
	</ListItem>
	);
}


function TableAdditionalOptions({ addOrderMutation }: MutationProp) {
	const { bag, updateTable } = useBag();

	const {	appModeContent} = useAppMode();


	return (
	<Grow in>
		<div>
			<Subtitle divider>Informe a mesa</Subtitle>
			<Box p={2}>
				<IntegerInput
					disabled={appModeContent.mode=== AppModeEnum.TABLE}
					onBlur={(value) => updateTable(value)}
					defaultValue={bag.table || 0}
					label="Qual o número da sua mesa?"
					error={addOrderMutation.error?.validationErrors.table}
				/>
			</Box>
		</div>
	</Grow>
	);
}

type LocalAdditionalOptionsProps = {
	companyWithdrawAddress: WithID<CompanyAddress> | undefined;
}
function LocalAdditionalOptions({
	companyWithdrawAddress
}: LocalAdditionalOptionsProps) {
	
	return (
		<Grow in>
			<div>
				<Subtitle divider>Endereço para retirada</Subtitle>
				<List>
					<ListItem>
						<ListItemIcon>
							{DeliveryMethodUtils[DeliveryMethod.DELIVERY].icon}
						</ListItemIcon>
						<ListItemText
							primary={companyWithdrawAddress ? (
								`${companyWithdrawAddress.street}, ${companyWithdrawAddress.number}`
							) : "Sem endereço para retirada"}
							secondary={companyWithdrawAddress ? formatAddress(
								undefined,
								undefined,
								companyWithdrawAddress.district,
								companyWithdrawAddress.city,
								companyWithdrawAddress.state,
								companyWithdrawAddress.complement,
								companyWithdrawAddress.zipcode
							) : 'Isto é uma falha no cadastro da loja'}
						/>
					</ListItem>
				</List>
			</div>
		</Grow>
	)
}

function DeliveryAdditionalOptions() {

	const {
		data: userAddresses,
		isLoading: userAddressesLoading
	} = useGetUserAddresses();

	const {
		updateAddressID,
		bag
	} = useBag();

	const { loggedIn } = useSession();
	const { openConnect } = useProfile();
	const { openUserAddresses } = useUser();

	const icon = DeliveryMethodUtils[DeliveryMethod.DELIVERY].icon;

	return (
	<Grow in>
		<div>
			<Subtitle divider>Endereço para entrega</Subtitle>
			<ListSelect
				items={(
					userAddressesLoading || !userAddresses || !loggedIn ?
						[] :
						userAddresses
				)}
				title={"Selecione um endereço"}
				disabled={loggedIn && (userAddressesLoading || !userAddresses)}
				select={item => updateAddressID(item.id)}
				checkSelected={item => (
					bag.delivery_address_id !== null &&
					item?.id === bag.delivery_address_id
				)}
				emptyAction={() => loggedIn ? openUserAddresses() : openConnect()}
				emptyContent={(loggedIn ? (<>
					<ListItemIcon><InfoIcon /></ListItemIcon>
					<ListItemText secondary="Clique para cadastrar seu endereço">
						Nenhum endereço cadastrado
					</ListItemText>
					</>) : (<>
					<ListItemIcon><AssignmentIndIcon /></ListItemIcon>
					<ListItemText secondary="Clique para se conectar ou cadastrar">
						Identifique-se para continuar
					</ListItemText>
					</>)
				)}
				unselectedContent={(<>
					<ListItemIcon>{icon}</ListItemIcon>
					<ListItemText secondary="Clique para selecionar">
						Selecione um endereço
					</ListItemText>
				</>)}
				selectedListItemContent={item => (<>
					<ListItemIcon>{icon}</ListItemIcon>
					<ListItemText
						secondary={`${item?.district}, ${item?.city} - ${item?.state}`}
					>
						{item?.street}, {item?.number}
					</ListItemText>
				</>)}
				managementPrimary="Gerenciar seus endereços"
				managementSecondary="Adicione ou atualize seus endereços"
				managementIcon={<AddressIcon />}
				managementOnClick={openUserAddresses}
			>
				{item => (
				<ListItemText
					primary={`${item?.street}, ${item?.number}`}
					secondary={`${item?.district}, ${item?.city} - ${item?.state}`}
				/>
				)}
			</ListSelect>
			<PaymentMethodPicker />
		</div>
	</Grow>
	);
}