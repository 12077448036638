import { useCompanyQuery, newCompanyQuery } from "../../../hooks/react-query"
import {  OrderWrapper, Page, Pages } from "../../../types/domain"

export const QUERY_USER_PREVIOUS_ORDERS = newCompanyQuery('/store/:company_id/user/order/previous/:page')<
    Page,
    OrderWrapper[]
>()
export const useGetUserPreviousOrders = (params: Page) => useCompanyQuery(
    QUERY_USER_PREVIOUS_ORDERS,
    params
);

export const QUERY_USER_PREVIOUS_ORDERS_PAGES = newCompanyQuery('/store/:company_id/user/order/previous/pages')<
    {},
    Pages
>()
export const useGetUserPreviousOrdersPages = () => useCompanyQuery(
    QUERY_USER_PREVIOUS_ORDERS_PAGES,
    {}
);

export const QUERY_USER_ORDER = newCompanyQuery('/store/:company_id/user/order/:order_id')<
    { order_id: number },
    OrderWrapper
>()
export const useGetUserOrder = (order_id?: number) => useCompanyQuery(
    QUERY_USER_ORDER,
    { order_id: order_id || 0 },
    {
        enabled: order_id !== undefined
    }
);

export const QUERY_USER_ACTIVE_ORDERS = newCompanyQuery('/store/:company_id/user/order/active')<
    {},
    OrderWrapper[]
>()
export const useGetUserActiveOrders = () => useCompanyQuery(
    QUERY_USER_ACTIVE_ORDERS,
    {}
);