import LocalAtmIcon from '@material-ui/icons/LocalAtm';
import CreditCardIcon from '@material-ui/icons/CreditCard';
import ErrorIcon from '@material-ui/icons/Error';
import generateConstantsUtils from './wrapper';

const PaymentMethod = {
    NONE: 1,
    MONEY: 2,
    CREDIT: 3,
    DEBIT: 4
} as const

// eslint-disable-next-line
type PaymentMethod = typeof PaymentMethod[keyof typeof PaymentMethod];

export default PaymentMethod;

type PaymentMethodProperties = {
    description: string,
    icon: JSX.Element
}

const properties = {
    
    [PaymentMethod.NONE]: {
        description: "Nenhuma forma de pagamento",
        icon: <ErrorIcon />
    } as PaymentMethodProperties,

    [PaymentMethod.MONEY]: {
        description: "Dinheiro",
        icon: <LocalAtmIcon />
    } as PaymentMethodProperties,

    [PaymentMethod.CREDIT]: {
        description: "Cartão de Crédito",
        icon: <CreditCardIcon />
    } as PaymentMethodProperties,

    [PaymentMethod.DEBIT]: {
        description: "Cartão de Débito",
        icon: <CreditCardIcon />
    } as PaymentMethodProperties,

} as { [key in PaymentMethod ]: PaymentMethodProperties}

export const PaymentMethodUtils = generateConstantsUtils(properties);