import { makeStyles } from '@material-ui/core/styles';
import { Divider, List, Typography } from '@material-ui/core';
import { BagExtra } from '../bag/types';
import { formatMoney } from '../../../../utils/format';
import { ListItemCounter } from '../../components/ListItemCounter';
import { ProductMenuStateType } from './hooks';

const useStyles = makeStyles(theme => ({
	container: {
		display: 'flex',
		flexWrap: 'nowrap',
		maxWidth: '100%',
		flexDirection: 'row',
		justifyContent: 'space-between'
	},
	price: {
		fontWeight: 'bold',
		color: theme.palette.success.main
	},
	productExtrasSubtitle: {
		paddingLeft: theme.spacing(1),
		marginTop: theme.spacing(2)
	}
}));

type ProductExtrasProps = {
	extras : BagExtra[];
	productIndex : number;
	changeAmount : ProductMenuStateType['changeExtraAmount'];
	amount : number;
}

export function ProductExtras({
    extras,
    changeAmount,
    productIndex,
    amount
}: ProductExtrasProps) {

	const classes = useStyles();

	if (extras.length <= 0) {
		return null;
	}

	return (
	<>
		<Typography
			variant="h5"
			gutterBottom
			color="textSecondary"
			className={classes.productExtrasSubtitle}
		>
			Adicionais
		</Typography>
		<Divider />
		<List disablePadding>
			{extras.map((extra, index) => (
			<ListItemCounter
				key={index}
				amount={extra.amount}
				changeAmount={newAmount => changeAmount(productIndex, extra.id, newAmount)}
				primary={extra.name}
				secondary={(
				<Typography noWrap className={classes.price}>
					+ {formatMoney(extra.price*amount)}
				</Typography>
				)}
			/>
			))}
		</List>
	</>
	);
}