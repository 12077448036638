import { FormControl, FormHelperText, FormLabel, InputBase, makeStyles } from "@material-ui/core";


const useStyles = makeStyles(theme => ({
    input: {
        '-webkit-appearance': 'none',
        padding: 0,
        cursor: 'pointer',
        ["&::-webkit-color-swatch"]: {// eslint-disable-line no-useless-computed-key
            height: theme.spacing(3),
            padding: 0,
            border: 'none',
            borderRadius: theme.shape.borderRadius,
            boxShadow: theme.shadows[1]
        },
    },
}))

type Props = {
    name: string;
    label: string;
    onChange: (newColor: string) => void;
    value: string;
    error?: string;
    disabled?: boolean;
};
export default function ColorInput({
    name,
    label,
    onChange,
    error,
    disabled,
    value
}: Props) {
    const classes = useStyles();

    return (
    <FormControl
        fullWidth
        error={error !== undefined}
    >
        <FormLabel filled component="legend">{label}</FormLabel>
        <InputBase
            name={name}
            value={value}
            type="color"
            disabled={disabled}
            onChange={e => onChange(e.currentTarget.value)}
            classes={{
                input: classes.input,
            }}
        />
        {error !== undefined && <FormHelperText>{error}</FormHelperText>}
    </FormControl>
    )
}