import * as React from "react";
import SVGProps from "./interfaces/props";
import { useTheme } from "@material-ui/core";

const SvgStoreNotFoundSvg = (props: SVGProps): JSX.Element => {
  const theme = useTheme();
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={660.639}
      height={559}
      viewBox="0 0 174.794 147.902"
      {...props}
    >
      <defs>
        <path
          id="store-not-found-svg_svg__a"
          d="M246.865 425.842h24.686v35.795h-24.686z"
        />
      </defs>
      <ellipse
        cx={87.397}
        cy={126.51}
        rx={87.397}
        ry={21.392}
        fill="gray"
        fillOpacity={0.659}
      />
      <rect
        x={29.006}
        y={54.04}
        width={116.64}
        height={72.295}
        ry={0}
        fill={theme.palette.secondary.main}
      />
      <g
        fill={theme.palette.primary.main}
        stroke="#000"
        transform="translate(-35.463 -111.05)"
      >
        <ellipse
          cx={176.92}
          cy={163.22}
          rx={12.66}
          ry={8.279}
          strokeWidth={3.583}
        />
        <path
          d="M154.28 113.66l8.944 44.718 25.713.373-20.496-45.464z"
          strokeWidth={0.265}
        />
        <ellipse
          cx={123.35}
          cy={162.48}
          rx={12.395}
          ry={8.296}
          strokeWidth={3.55}
        />
        <path
          d="M114.78 113.29l-4.1 46.209 24.969.745-2.981-46.209z"
          strokeWidth={0.265}
        />
        <ellipse
          cx={70.431}
          cy={163.22}
          rx={12.395}
          ry={8.296}
          strokeWidth={3.55}
        />
      </g>
      <ellipse
        cx={61.799}
        cy={52.17}
        rx={12.395}
        ry={8.296}
        fill="#fff"
        stroke="#000"
        strokeWidth={3.55}
      />
      <path
        d="M43.167 1.49L23.416 48.444l24.595-.745 8.944-42.855z"
        fill={theme.palette.primary.main}
        stroke="#000"
        strokeWidth={0.265}
      />
      <g fill="#fff" stroke="#000" transform="translate(-35.463 -111.05)">
        <path
          d="M93.163 114.4l-8.944 45.464 25.34-.745 4.1-47.327z"
          strokeWidth={0.265}
        />
        <ellipse
          cx={149.43}
          cy={162.85}
          rx={12.395}
          ry={8.296}
          strokeWidth={3.55}
        />
        <path
          d="M133.41 114.03l3.354 45.836 26.458-.373-8.571-45.464z"
          strokeWidth={0.265}
        />
      </g>
      <g strokeWidth={0} transform="translate(-35.463 -111.05)">
        <rect x={75.276} y={111.05} width={94.654} height={4.845} ry={2.422} />
        <rect
          transform="matrix(.38591 .92254 -.99912 -.04203 0 0)"
          x={114.76}
          y={-126.09}
          width={53.42}
          height={4.554}
          ry={2.277}
        />
        <rect
          transform="matrix(-.37029 .92892 -.71178 -.7024 0 0)"
          x={27.365}
          y={-125.98}
          width={55.749}
          height={4.559}
          ry={2.28}
        />
        <rect
          transform="matrix(.19903 .97999 -.97409 -.22615 0 0)"
          x={81.01}
          y={-143.65}
          width={49.571}
          height={4.589}
          ry={2.294}
        />
        <rect
          transform="matrix(-.20668 .97841 -.80408 -.59453 0 0)"
          x={38.96}
          y={-128.05}
          width={49.571}
          height={4.589}
          ry={2.294}
        />
        <rect
          transform="matrix(-.07384 .99727 -.89038 -.45522 0 0)"
          x={53.423}
          y={-133.77}
          width={46.627}
          height={4.528}
          ry={2.264}
        />
      </g>
      <g transform="translate(-35.463 -111.05)">
        <rect
          x={106.58}
          y={176.64}
          width={33.166}
          height={61.488}
          ry={2.579}
          fill="#441b00"
          stroke="#000"
          strokeWidth={1.294}
        />
        <g strokeWidth={0}>
          <rect x={61.488} y={234.4} width={123.73} height={5.59} ry={2.422} />
          <rect
            x={72.667}
            y={181.11}
            width={26.086}
            height={26.086}
            ry={2.422}
            fill="#6fffff"
          />
          <rect
            x={147.15}
            y={180.9}
            width={26.086}
            height={26.086}
            ry={2.422}
            fill="#6fffff"
          />
          <rect
            x={57.388}
            y={157.07}
            width={132.95}
            height={4.845}
            ry={2.422}
          />
          <rect
            transform="matrix(.08388 .99648 -.95088 -.30956 0 0)"
            x={72.612}
            y={-135.28}
            width={46.627}
            height={4.528}
            ry={2.264}
          />
        </g>
      </g>
      <g
        fill={theme.palette.secondary.main}
        strokeWidth={0}
        transform="translate(-35.463 -111.05)"
      >
        <rect x={83.474} y={177.76} width={4.472} height={33.911} ry={0} />
        <rect x={158.38} y={178.31} width={4.472} height={33.911} ry={0} />
        <rect
          transform="rotate(89.814)"
          x={192.6}
          y={-177.47}
          width={4.472}
          height={33.911}
          ry={0}
        />
        <rect
          transform="rotate(89.814)"
          x={192.19}
          y={-102.41}
          width={4.472}
          height={33.911}
          ry={0}
        />
      </g>
      <rect x={29.006} y={59.25} width={4.472} height={66.332} ry={2.236} />
      <rect x={141.917} y={58.88} width={4.472} height={66.332} ry={2.236} />
      <g
        fill="none"
        stroke="#000"
        strokeWidth={1.294}
        transform="rotate(180 -17.732 -55.525)"
      >
        <rect
          x={-83.486}
          y={-191.88}
          width={11.215}
          height={10.693}
          ry={0.993}
        />
        <rect
          x={-98.917}
          y={-191.88}
          width={11.215}
          height={10.693}
          ry={0.993}
        />
        <rect
          x={-83.486}
          y={-206.85}
          width={11.215}
          height={10.693}
          ry={0.993}
        />
        <rect
          x={-98.917}
          y={-206.86}
          width={11.215}
          height={10.693}
          ry={0.993}
        />
        <rect
          x={-173.6}
          y={-191.88}
          width={11.215}
          height={10.693}
          ry={0.993}
        />
        <rect
          x={-173.6}
          y={-206.86}
          width={11.215}
          height={10.693}
          ry={0.993}
        />
        <rect
          x={-158.17}
          y={-191.88}
          width={11.215}
          height={10.693}
          ry={0.993}
        />
        <rect
          x={-158.17}
          y={-206.86}
          width={11.215}
          height={10.693}
          ry={0.993}
        />
      </g>
      <g stroke="#000">
        <g transform="rotate(1.585 3896.689 -1150.861)">
          <rect
            transform="matrix(.95436 .29865 -.34422 .93889 0 0)"
            x={164.9}
            y={151.1}
            width={42.663}
            height={14.285}
            ry={2.222}
            fill="#803300"
            strokeWidth={1.001}
          />
          <ellipse cx={105.69} cy={198.44} rx={0.704} ry={0.563} fill="none" />
          <ellipse cx={141.3} cy={209.41} rx={0.704} ry={0.563} fill="none" />
        </g>
        <g transform="rotate(-33.257 -105.084 200.626)">
          <rect
            transform="matrix(.95436 .29865 -.34422 .93889 0 0)"
            x={164.9}
            y={151.1}
            width={42.663}
            height={14.285}
            ry={2.222}
            fill="#803300"
            strokeWidth={1.001}
          />
          <ellipse cx={105.69} cy={198.44} rx={0.704} ry={0.563} fill="none" />
          <ellipse cx={141.3} cy={209.41} rx={0.704} ry={0.563} fill="none" />
        </g>
        <g transform="rotate(-24.224 -105.888 242.77)">
          <rect
            transform="matrix(.95436 .29865 -.34422 .93889 0 0)"
            x={164.9}
            y={151.1}
            width={42.663}
            height={14.285}
            ry={2.222}
            fill="#803300"
            strokeWidth={1.001}
          />
          <ellipse cx={105.69} cy={198.44} rx={0.704} ry={0.563} fill="none" />
          <ellipse cx={141.3} cy={209.41} rx={0.704} ry={0.563} fill="none" />
        </g>
      </g>
      <g strokeWidth={0}>
        <path
          d="M38.827 93.992s10.752-3.375 24.726 1.85c13.976 5.223 17.598 15.746 17.598 15.746s10.637 28.458-16.14 30.103c-26.779 1.643-32.037-.958-34.513-1.656-2.475-.697-15.938-3.873-16.032-19.021-.094-15.148 8.563-19.8 8.563-19.8s5.795-4.652 13.869-6.705c8.073-2.053 1.929-.517 1.929-.517z"
          fill={theme.palette.primary.main}
        />
        <path
          d="M38.607 93.782s10.64-3.183 24.488 1.797c13.847 4.98 17.452 14.97 17.452 14.97s10.582 27.017-15.931 28.531c-26.513 1.514-31.725-.962-34.178-1.629-2.452-.666-15.79-3.702-15.908-18.073-.119-14.371 8.446-18.77 8.446-18.77s5.73-4.404 13.721-6.338c7.991-1.934 1.91-.487 1.91-.487z"
          fill="#fff"
          opacity={0.295}
        />
        <path d="M33.431 105.221c1.175.048 2.42.976 2.046 1.925-2.203 5.59-1.428 10.507-.633 15.42.162 1.006-1.022 1.799-2.197 1.75-1.174-.048-1.932-.911-2.045-1.924-.542-4.857-.768-9.833.633-15.42.248-.989 1.021-1.799 2.196-1.75zM52.107 106.515c1.175.048 2.42.94 2.048 1.849-2.192 5.353-1.409 10.066-.606 14.776.164.965-1.018 1.723-2.193 1.675-1.175-.049-1.934-.878-2.049-1.849-.55-4.656-.785-9.424.607-14.776.246-.948 1.018-1.723 2.193-1.675z" />
        <path d="M47.377 103.751c.39-.82 1.466-1.403 2.071-.826 3.565 3.397 7.517 4.455 11.472 5.497.81.213 1.053 1.316.664 2.137-.39.82-1.271 1.077-2.072.826-3.836-1.204-7.667-2.67-11.47-5.497-.674-.5-1.054-1.316-.665-2.137zM25.177 107.372c-.218-.822.197-1.892.977-1.85 4.59.245 8.044-1.411 11.49-3.08.707-.342 1.546.295 1.764 1.117.218.822-.255 1.541-.977 1.85-3.46 1.482-7.074 2.773-11.49 3.08-.783.054-1.546-.295-1.764-1.117z" />
      </g>
      <text
        transform="scale(3.52128) rotate(-22.69 -890.972 840.256)"
        fontWeight={400}
        fontSize={10.583}
        fontFamily="sans-serif"
        fill={theme.palette.text.hint}
      >
        <tspan x={246.865} y={435.497}>
          <tspan>{"?"}</tspan>
        </tspan>
      </text>
    </svg>
  );
};

export default SvgStoreNotFoundSvg;
