import StoreIcon from '@material-ui/icons/Store';
import LocalLibraryIcon from '@material-ui/icons/LocalLibrary';
import LocationOnIcon from '@material-ui/icons/LocationOn';
import ErrorIcon from '@material-ui/icons/Error';
import generateConstantsUtils from './wrapper';

const MixableType = {
    NONE: 1,
    FAIR_PRICE: 2,
    UNFAIR_PRICE: 3,
    FIXED_PRICE: 4,
    COMBO: 5
} as const

// eslint-disable-next-line
type MixableType = typeof MixableType[keyof typeof MixableType];

export default MixableType


type MixableTypeProperties = {
    description: string,
    icon: JSX.Element
}

const properties = {
    
    [MixableType.NONE]: {
        description: "Nenhum meio de misturar produtos",
        icon: <ErrorIcon />
    } as MixableTypeProperties,

    [MixableType.FAIR_PRICE]: {
        description: "Meio-a-meio preço justo",
        icon: <StoreIcon />
    } as MixableTypeProperties,

    [MixableType.UNFAIR_PRICE]: {
        description: "Meio-a-meio preço do produto mais caro",
        icon: <StoreIcon />
    } as MixableTypeProperties,

    [MixableType.FIXED_PRICE]: {
        description: "Meio-a-meio preço fixo",
        icon: <LocationOnIcon />
    } as MixableTypeProperties,

    [MixableType.COMBO]: {
        description: "Combo",
        icon: <LocalLibraryIcon />
    } as MixableTypeProperties,

} as { [key in MixableType ]: MixableTypeProperties}

export const MixableTypeUtils = generateConstantsUtils(properties);