import { BagIcon } from "../../../../components/Icons";
import { ResponsiveFullscreenDialog } from "../../../../components/ResponsiveFullscreenDialog";
import { useBagRoute, useCheckoutRoute, useMenuRoute } from "../nav/routes";
import { BagItemList } from "./BagItemList";
import { ValueButton } from "../../components/ValueButton";
import { useBag } from "./BagProvider";
import ArrowForwardIosIcon from '@material-ui/icons/ArrowForwardIos';

export function BagDialog() {
    const bagRoute = useBagRoute();
    const menuRoute = useMenuRoute();
    const checkoutRoute = useCheckoutRoute();

    const { bag } = useBag();

    return (
    <ResponsiveFullscreenDialog
        open={bagRoute.matched}
        onClose={() => menuRoute.push({})}
        icon={<BagIcon />}
        title="Sua sacola"
        width="sm"
        bottomContent={(
            <ValueButton
                title="Total do pedido"
                value={bag.price}
                icon={<ArrowForwardIosIcon fontSize="large" />}
                tooltip="Finalizar pedido"
                onClick={() => checkoutRoute.push({})}
                disabled={bag.items.length === 0}
                actionLabel="Finalizar"
            />
        )}
    >
        <BagItemList />
    </ResponsiveFullscreenDialog>
    );
}