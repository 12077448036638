import { makeStyles } from '@material-ui/core/styles';
import { Typography } from '@material-ui/core';
import { CSSProperties } from '@material-ui/core/styles/withStyles';
import { useImageSource } from '../../../../hooks/gallery';

const useStyles = makeStyles(theme => ({
	productDetailsContainer: {
		backgroundPosition: 'center',
		backgroundRepeat: 'no-repeat',
		backgroundSize: 'cover',
		height: '20vh',
		minHeight: theme.spacing(30),
		position: 'relative',
		display: 'flex',
		flexGrow: 1,
		boxShadow: theme.shadows[1]
	},
	productDetailsTitle: {
		position: 'absolute',
		bottom: 0,
		left: 0,
		color: theme.palette.grey[100],
		paddingLeft: theme.spacing(2),
		paddingRight: theme.spacing(2),
		paddingBottom: theme.spacing(1)
	},
}));


type ProductDetailsProps = {
	title: string;
	image_id: string | null;
}

export function ProductDetails({ title, image_id }: ProductDetailsProps) {
   
	const classes = useStyles();

	const { medium } = useImageSource(image_id);

	const backgroundImage: CSSProperties = {
		backgroundImage: `linear-gradient(to top, rgba(0,0,0,0.75), transparent), url("${medium}")`
	};

	return (
	<div
		className={classes.productDetailsContainer}
		style={backgroundImage}
	>
		<Typography className={classes.productDetailsTitle} component='div' variant="h4">
			{title}
		</Typography>
	</div>
	);
}
