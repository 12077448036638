import { Theme, makeStyles, AppBar, Toolbar, List, Divider, Drawer, Hidden, ListSubheader } from "@material-ui/core";
import { Fragment } from "react";
import { Repeat } from "../../../components/Repeat";
import { CharactersPlaceholder } from "../../../placeholders/CharactersPlaceholder";
import { IconButtonPlaceholder } from "../../../placeholders/IconButtonPlaceholder";
import { ListItemPlaceholder } from "../../../placeholders/ListItemPlaceholder";
import { ParagraphPlaceholder } from "../../../placeholders/ParagraphPlaceholder";

const drawerWidth = (theme : Theme) => ({
    [theme.breakpoints.down('md')]: {
        width: 250
    },
    [theme.breakpoints.up('lg')]: {
        width: 300
    },
});


const useStyles = makeStyles(theme => ({
    container: {
        width: '100%',
        height: '100vh',
        overflow: 'hidden',
        position: 'relative'
    },
    appBar: {
        backgroundColor: theme.palette.background.paper,
        zIndex: theme.zIndex.drawer+1
    },
    iconButtonLeft: {
        marginRight: theme.spacing(2)
    },
    iconButtonRight: {
        marginLeft: theme.spacing(2)
    },
    drawerPaper: {
        ...drawerWidth(theme)
    },
    drawer: {
        ...drawerWidth(theme),
        zIndex: theme.zIndex.drawer,
        flexShrink: 0,
    },
}));

export function PortalPlaceholder() {
    const classes = useStyles();

    const sections = [ 2, 6, 6];

    return (
    <div className={classes.container}>
        <AppBar className={classes.appBar} position="fixed">
            <Toolbar>
                <IconButtonPlaceholder className={classes.iconButtonLeft} />
                <IconButtonPlaceholder className={classes.iconButtonLeft} />
                <ParagraphPlaceholder lines={1} />
                <IconButtonPlaceholder className={classes.iconButtonRight} />
                <IconButtonPlaceholder className={classes.iconButtonRight} />
            </Toolbar>
        </AppBar>
        <Hidden smDown implementation="css">
            <Drawer
                className={classes.drawer}
                classes={{
                    paper: classes.drawerPaper
                }}
                variant="permanent"
            >
                <Toolbar />
                <List>
                    {sections.map((section, index) => (
                    <Fragment key={index}>
                        <ListSubheader><CharactersPlaceholder n={16} /></ListSubheader>
                        <Repeat n={section}>
                            <ListItemPlaceholder avatar />
                        </Repeat>
                        {index !== sections.length-1 && <Divider />}
                    </Fragment>
                    ))}
                </List>
            </Drawer>
        </Hidden>
    </div>
    );
}