import { OptionsObject, SnackbarProvider, useSnackbar } from 'notistack';
import { IconButton, makeStyles } from '@material-ui/core';
import CloseIcon from '@material-ui/icons/Close';
import { ProviderProps } from '../types/provider';
import { useCallback, useMemo } from 'react';

type PopupMessage = {
    message: string;
}

export default function useFeedback() {
    const { enqueueSnackbar, closeSnackbar } = useSnackbar();

    const defaultConfig : OptionsObject = useMemo(() => ({
        anchorOrigin: {
            vertical: 'bottom',
            horizontal: 'right',
        },
        autoHideDuration: 1500,
        variant: 'default', 
        action: (key: any) => (
            <IconButton
                color="inherit"
                onClick={() => closeSnackbar(key)}
            >
                <CloseIcon />
            </IconButton>
        )
    }), [ closeSnackbar ]);

    const successPopup = useCallback((obj: PopupMessage) => {
        enqueueSnackbar(obj.message, {
            ...defaultConfig,
        })
    }, [ enqueueSnackbar, defaultConfig ]);
    const errorPopup = useCallback((obj: PopupMessage) => {
        enqueueSnackbar(obj.message, {
            ...defaultConfig,
        })
    }, [ enqueueSnackbar, defaultConfig ]);

    const infoPopup = useCallback((obj: PopupMessage) => {
        enqueueSnackbar(obj.message, {
            ...defaultConfig,
        })
    }, [ enqueueSnackbar, defaultConfig ]);

    return {
        successPopup,
        errorPopup,
        infoPopup
    }
}
const useStyles = makeStyles(theme => ({
    containerRoot: {
        bottom: theme.spacing(8)
    }
}));

export function FeedbackProvider({ children } : ProviderProps) {
    const classes = useStyles();
	return (
    <SnackbarProvider
        maxSnack={2}
        classes={{
            containerRoot: classes.containerRoot
        }}
    >
        {children}
    </SnackbarProvider>
	);
}
