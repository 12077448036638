import {
    Container,
    List,
    ListSubheader,
    makeStyles,
    Paper,
    useTheme as useMuiTheme,
    Typography,
    Divider
} from "@material-ui/core";
import { Fragment, ReactNode } from "react";
import { Repeat } from "../../../../components/Repeat";
import { CharactersPlaceholder } from "../../../../placeholders/CharactersPlaceholder";
import { ListItemPlaceholder } from "../../../../placeholders/ListItemPlaceholder";
import useTheme from "../../../../providers/Theme";
import { useGetMenu } from "../../queries";
import MenuEmptyState from "./MenuEmptyState";
import { ProductListItem } from "./ProductListItem";
import { ScrollTabs } from "../../components/ScrollTabs";
import { useOpenSingleProductMenu } from "../product_menu/hooks";
import { useOpenMixableDialog } from "../mixable/hooks";
import MixableType from "../../../../constants/mixable_type";
import { useWindowVerticalScroll } from "../../../../hooks/windowVerticalScroll";

const useStyles = makeStyles(theme => ({
    menuContainer: {
        padding: `0px ${theme.spacing(1)}px`
    },
    menuOuterContainer: {
        display: 'flex',
		flexDirection: 'column',
        paddingBottom: '50vh'
    },
    menuTabsContainer: {
        zIndex: theme.zIndex.appBar-1
    },
    menuSectionName: {
        marginTop: theme.spacing(6),
        paddingLeft: theme.spacing(1)
    },
    scrollTabsAppBar: {
        top: theme.spacing(8),
        [theme.breakpoints.down('xs')]: {
            top: theme.spacing(7)
        },
    }
}));

type MenuProps = {
    children?: ReactNode;
}
export function Menu({ children }: MenuProps) {
    const classes = useStyles();
    
    const { transitions } = useTheme();

    const theme = useMuiTheme();
    
    const { openSingleProductMenu } = useOpenSingleProductMenu();

    const { openMixableDialog } = useOpenMixableDialog();

    const {
        data: menu,
        isLoading: menuLoading
    } = useGetMenu();

    const {
        sectionsRefs,
        selectSection,
        activeIndex
    } = useWindowVerticalScroll<HTMLDivElement>({
        smoothScrolling: transitions,
        sectionsLength: menu?.sections.length || 0,
        scrollOffset: theme.spacing(17)
    });

    if (!menu || menuLoading) {
        return (
        <MenuPlaceholder>
            {children}
        </MenuPlaceholder>
        );
    }

    return (
    <div className={classes.menuOuterContainer}>
        {children}
        <ScrollTabs
            appBarClassName={classes.scrollTabsAppBar} // Atualizar isso aqui caso o tamanho da appbar principal mudar de tamanho
            maxWidth="md"
            value={activeIndex}
            onChange={newIndex => selectSection(newIndex)}
            items={menu.sections.map(section => ({ label: section.name }))}
        />
        <Container maxWidth="md" component="main" className={classes.menuContainer}>
            {menu.sections.length === 0 ? (
                <MenuEmptyState />
            ) : menu.sections.map((section, section_index) => (
            <div
                key={`sec-${section_index}`}
                ref={sectionsRefs[section_index]}
            >
                <Typography
                    className={classes.menuSectionName}
                    color="textSecondary"
                    gutterBottom
                    variant="h5"
                >
                    {section.name}
                </Typography>
                <Paper variant="outlined">
                    <List disablePadding>
                        {section.mixables.map((mixable, index) => (
                        <Fragment key={`${section_index}-mix-${index}`}>
                            <ProductListItem
                                onClick={() => openMixableDialog(mixable.id)}
                                name={mixable.name}
                                description={mixable.description}
                                price={(
                                    mixable.type_id === MixableType.FIXED_PRICE ||
                                    mixable.type_id === MixableType.COMBO ?
                                    mixable.price : null
                                )}
                                image_id={mixable.image_id}
                            />
                            <Divider />
                        </Fragment>
                        ))}
                        {section.products.map((product, index) => (
                        <Fragment key={`${section_index}-${index}`}>
                            <ProductListItem
                                onClick={() => openSingleProductMenu(section.id, product.id)}
                                name={product.name}
                                description={product.description}
                                price={product.price}
                                image_id={product.image_id}
                            />
                            {index !== section.products.length-1 && <Divider />}
                        </Fragment>
                        ))}
                    </List>
                </Paper>
            </div>
            ))}
        </Container>
    </div>
    );
}

export function MenuPlaceholder({ children }: MenuProps) {
    const classes = useStyles();

    return (
    <div className={classes.menuOuterContainer}>
        {children}
        <ScrollTabs
            maxWidth="md"
            value={false}
            alwaysVisible
            items={Array(5).fill(null).map(() => ({
                label: <CharactersPlaceholder n={8} />,
                disabled: true
            }))}
        />
        <Container maxWidth="md" component="main">
            <Repeat n={2}>
                <List>
                    <ListSubheader><CharactersPlaceholder n={16} /></ListSubheader>
                    <Repeat n={5}>
                        <ListItemPlaceholder avatar secondaryLines={2} />
                    </Repeat>
                </List>
            </Repeat>
        </Container>
    </div>
    );
}