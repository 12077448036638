import { useCallback, useEffect, useState } from "react";
import useErrorHandler from "../../../../providers/ErrorHandler";
import { decodeBase64, encodeBase64 } from "../../../../utils/format";
import { MenuSection, useGetMenu } from "../../queries";
import { useMenuRoute, useSearchRoute } from "../nav/routes";

export const useOpenSearch = () => {
	const searchRoute = useSearchRoute();
	const openSearch = useCallback((query: string) => {
		searchRoute.push({ query: encodeBase64(query) });
	}, [ searchRoute ]);
	return { openSearch };
}

const QUERY_MAX_LENGTH = 50;

export const useSearchDialogState = () => {

	const searchRoute = useSearchRoute();
	const menuRoute = useMenuRoute();

    const { report } = useErrorHandler();

	const { data: menu, isLoading: menuLoading } = useGetMenu();
	
	const [ searchResults, setSearchResults ] = useState<MenuSection[] | null>(null);
	const [ query, setQuery ] = useState<string | null>(null);

	useEffect(() => {
		if (
			!menu ||
			menuLoading ||
			!searchRoute.params?.query ||
			searchRoute.params.query.length === 0 ||
			searchRoute.params.query.length > QUERY_MAX_LENGTH
		) return;
		
		var parsedQuery: string;
		try {
			parsedQuery = decodeBase64(searchRoute.params.query);
		} catch(e: any) {
			return report({
				message: "Erro interno do aplicativo",
				error: "query = decodeBase64(location.search);"
			});
		}
		setQuery(parsedQuery);
		const upperCaseQuery = parsedQuery.toUpperCase();
		setSearchResults(menu.sections.map(section => ({
			...section,
			products: section.products.filter(product => (
				product.name.toUpperCase().includes(upperCaseQuery)
			)),
			mixables: section.mixables.filter(mixable => (
				mixable.name.toUpperCase().includes(upperCaseQuery)
			))
		})).filter(section => section.products.length > 0 || section.mixables.length > 0));
	}, [
		menu,
		menuLoading,
		searchRoute.params?.query,
		report
	]);

	const visible =
		searchRoute.matched &&
		query !== null &&
		searchResults !== null;


	const close = useCallback(() => {
		menuRoute.push({});
	}, [ menuRoute ]);

	const clear = useCallback(() => {
		setSearchResults(null);
		setQuery(null);
	}, [ ]);

    return {
        searchResults,
		visible,
		close,
		query,
		clear
	}
}