import * as React from "react";
import SVGProps from "./interfaces/props";
import { useTheme } from "@material-ui/core";

const SvgProductsSearchSvg = (props: SVGProps): JSX.Element => {
  const theme = useTheme();
  return (
    <svg
      width={616.28}
      height={397.48}
      viewBox="0 0 163.06 105.16"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <g strokeWidth={0}>
        <ellipse
          transform="matrix(.99644 -.0843 .07281 .99735 0 0)"
          cx={107.07}
          cy={92.042}
          rx={49.843}
          ry={11.146}
          opacity={0.47}
        />
        <path
          d="M44.541 28.876s18.667-14.447 48.047-10.551c29.38 3.896 38.632 24.835 40.256 28.731 1.623 3.896 4.058 10.226 3.409 21.751-.65 11.525-7.63 18.505-18.342 21.426-10.713 2.922-24.997 3.733-24.997 3.733s-28.406.812-39.12-2.921c-10.712-3.734-23.05-5.357-25.321-20.452-2.273-15.096 1.298-24.348 5.356-30.516 4.058-6.169 10.713-11.2 10.713-11.2z"
          fill={theme.palette.primary.main}
        />
        <path
          d="M43.48 29.016s17.875-13.953 46.007-10.19c28.133 3.763 36.992 23.986 38.547 27.749 1.554 3.763 3.886 9.877 3.264 21.007-.622 11.131-7.305 17.872-17.564 20.694-10.258 2.822-23.936 3.606-23.936 3.606s-27.2.784-37.459-2.822-22.071-5.174-24.247-19.753c-2.176-14.58 1.243-23.516 5.13-29.473 3.885-5.957 10.257-10.817 10.257-10.817z"
          fill="#fff"
          opacity={0.39}
        />
        <path d="M58.58 34.905c1.919 0 4.01 1.429 3.464 3.002-3.217 9.26-1.624 17.227 0 25.185.333 1.63-1.545 3.002-3.464 3.002s-3.212-1.357-3.464-3.002c-1.208-7.883-1.911-15.98 0-25.185.338-1.63 1.545-3.002 3.464-3.002zM89.118 35.765c1.919 0 4.01 1.37 3.464 2.877-3.217 8.875-1.624 16.509 0 24.135.333 1.563-1.545 2.877-3.464 2.877s-3.212-1.301-3.464-2.877c-1.208-7.555-1.911-15.315 0-24.135.338-1.561 1.545-2.877 3.464-2.877z" />
        <path d="M81.222 31.575c.58-1.364 2.296-2.386 3.322-1.486 6.04 5.3 12.555 6.76 19.073 8.195 1.335.294 1.806 2.075 1.225 3.44-.58 1.364-2 1.842-3.322 1.486-6.336-1.706-12.68-3.842-19.073-8.195-1.131-.771-1.806-2.076-1.225-3.44zM45.265 38.965c-.41-1.326.194-3.099 1.468-3.083 7.502.093 13.023-2.839 18.531-5.79 1.129-.605 2.54.377 2.95 1.702.41 1.326-.312 2.531-1.468 3.083-5.543 2.648-11.35 4.995-18.531 5.79-1.272.141-2.54-.376-2.95-1.702z" />
      </g>
      <g strokeWidth={0}>
        <rect
          transform="rotate(-40) skewX(.016)"
          x={-23.778}
          y={69.376}
          width={7.806}
          height={50.062}
          ry={3.903}
          fill={theme.palette.secondary.main}
        />
        <rect
          transform="matrix(.99972 -.02348 .64306 .76581 0 0)"
          x={-28.641}
          y={84.705}
          width={7.721}
          height={47.601}
          ry={3.711}
          fill="#fff"
          opacity={0.24}
        />
        <ellipse
          cx={19.768}
          cy={54.375}
          rx={19.768}
          ry={19.226}
          fill={theme.palette.secondary.main}
        />
        <ellipse
          cx={19.801}
          cy={54.233}
          rx={18.225}
          ry={17.725}
          fill="#fff"
          opacity={0.24}
        />
      </g>
      <ellipse cx={19.981} cy={54.236} rx={15.576} ry={15.15} fill="#999" />
      <path
        d="M26.147 41.496a14.256 12.781 0 00-19.042 5.848 14.256 12.781 0 00.002 11.283 15.066 13.507 0 011.25-8.628 15.066 13.507 0 0120.168-6.16 15.066 13.507 0 012.997 1.744 14.256 12.781 0 00-5.333-4.07 14.256 12.781 0 00-.042-.018z"
        fill="#f9f9f9"
      />
      <text
        transform="rotate(-23.378 -306.11 -874.836) scale(3.865)"
        fill={theme.palette.text.hint}
        fontFamily="sans-serif"
        fontSize={10.584}
      >
        <tspan x={-94.248} y={23.833}>
          <tspan>{"?"}</tspan>
        </tspan>
      </text>
    </svg>
  );
};

export default SvgProductsSearchSvg;
