import { UseQueryOptions } from "react-query";
import { newCompanyQuery, useCompanyQuery } from "../hooks/react-query";
import { CompanyID } from "../types/domain";
import HTTPError from "../types/httperror";

export type ResponseStatus = {
    open_store: boolean;
    menu_updated_at: number;
    app_version: number;
}
export const QUERY_STATUS = newCompanyQuery(
    '/store/:company_id/status',
    { open: true }
)<
    {},
    ResponseStatus
>();
export const useGetCompanyStatus = (
    options?: UseQueryOptions<unknown, HTTPError<CompanyID>, ResponseStatus>
) => useCompanyQuery(
    QUERY_STATUS,
    {},
    options
);
