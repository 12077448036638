import { Fragment } from "react";

type RepeatProps = {
    n: number;
    children: JSX.Element | JSX.Element[];
}
export function Repeat({ children, n }: RepeatProps) {
    return (<>
        {Array.from({length: n}, (_, i) => (
        <Fragment key={i}>
            {children}
        </Fragment>
        ))}
    </>);
}