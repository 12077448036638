import {
    Divider,
    List,
    ListSubheader,
    makeStyles,
    Typography,
    useTheme as useMuiTheme
} from "@material-ui/core";
import { MenuIcon } from "../../../../components/Icons";
import { ResponsiveFullscreenDialog } from "../../../../components/ResponsiveFullscreenDialog";
import { ValueButton, ValueButtonPlaceholder } from "../../components/ValueButton";
import { useMixableDialogState } from "./hooks";
import AddIcon from '@material-ui/icons/Add';
import { useVerticalScroll } from "../../../../hooks/verticalScroll";
import useTheme from "../../../../providers/Theme";
import { ScrollTabs } from "../../components/ScrollTabs";
import { ProductMixableListItem } from "./ProductMixableListItem";
import MixableType from "../../../../constants/mixable_type";
import { CharactersPlaceholder } from "../../../../placeholders/CharactersPlaceholder";
import { ListItemPlaceholder } from "../../../../placeholders/ListItemPlaceholder";
import { Repeat } from "../../../../components/Repeat";
import { Fragment } from "react";


const useStyles = makeStyles(theme => ({
    mixableSectionName: {
        marginTop: theme.spacing(6),
        paddingLeft: theme.spacing(1)
    },
}))

export function MixableDialog() {
    const classes = useStyles();

    const { transitions } = useTheme();

    const { spacing: themeSpacing } = useMuiTheme();

    const {
        title,
        visible,
        close,
        clear,
        submit,
        mixable,
        mixableSections,
        changeAmount,
        totalAmount,
        totalPrice
    } = useMixableDialogState();

    const {
        scrollHandler,
        sectionsRefs,
        containerRef,
        selectSection,
        activeIndex
    } = useVerticalScroll<HTMLDivElement, HTMLDivElement>({
        smoothScrolling: transitions,
        sectionsLength: mixableSections?.length || 0,
        scrollOffset: themeSpacing(13)
    });


    return (
    <ResponsiveFullscreenDialog
        ref={containerRef}
        onScroll={scrollHandler}
        open={visible}
        onClose={close}
        onExited={clear}
        icon={<MenuIcon />}
        title={title}
        width="sm"
        bottomContent={mixable === null || mixableSections === null ? <ValueButtonPlaceholder /> : (
            <ValueButton
                onClick={submit}
                title="Total do item"
                value={totalPrice}
                icon={<AddIcon />}
                actionLabel="Adicionar"
                tooltip="Adicionar item à sacola"
            />
        )}
    >
        {mixable === null || mixableSections === null ? <MixableDialogPlaceholder /> : (
        <>
            <ScrollTabs
                value={activeIndex}
                onChange={newIndex => selectSection(newIndex)}
                items={mixableSections.map(section => ({ label: section.name }))}
            />
            {mixableSections.map((section, section_index) => (
            <div
                key={`sec-${section_index}`}
                ref={sectionsRefs[section_index]}
            >
                <Typography
                    className={classes.mixableSectionName}
                    color="textSecondary"
                    gutterBottom
                    variant="h5"
                >
                    {section.name}
                </Typography>
                <Divider />
                <List disablePadding>
                    {section.products.map((product, index) => (
                    <Fragment key={`sec-${section_index}-prod-${index}`}>
                        <ProductMixableListItem
                            changeAmount={newAmount => changeAmount(section_index, index, newAmount)}
                            amount={product.amount}
                            disabledIncrease={!mixable || totalAmount >= mixable.amount}
                            name={product.name}
                            price={product.displayPrice}
                            description={product.description}
                            image_id={product.image_id}
                            plus={mixable && mixable.type_id === MixableType.FAIR_PRICE}
                        />
                        <Divider />
                    </Fragment>
                    ))}
                </List>
            </div>
            ))}
        </>
        )}
        
    </ResponsiveFullscreenDialog>
    );
}

function MixableDialogPlaceholder() {
    return (
    <>
        <ScrollTabs
            value={false}
            alwaysVisible
            items={Array(5).fill(null).map(() => ({
                label: <CharactersPlaceholder n={8} />,
                disabled: true
            }))}
        />
        <Repeat n={2}>
            <List>
                <ListSubheader disableSticky><CharactersPlaceholder n={10} /></ListSubheader>
                <Divider />
                <Repeat n={5}>
                    <ListItemPlaceholder avatar secondaryLines={2} />
                </Repeat>
            </List>
        </Repeat>
    </>
    );
}