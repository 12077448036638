import { makeStyles } from '@material-ui/core/styles';
import { List } from '@material-ui/core';
import { CharactersPlaceholder } from '../../../../placeholders/CharactersPlaceholder';
import { ListItemPlaceholder } from '../../../../placeholders/ListItemPlaceholder';
import { ProductAmountPlaceholder } from './ProductAmount';
import Subtitle from '../../../../components/Subtitle';
import clsx from 'clsx';
import { placeholderAnimationClass } from '../../../../providers/Theme';

const useStyles = makeStyles(theme => ({
	productDetails: {
		height: '20vh',
		minHeight: theme.spacing(25),
		position: 'relative',
		display: 'flex',
		flexGrow: 1,
	},
}));

export function ProductMenuPlaceholder() {
	const classes = useStyles();
	return (
	<>
		<div className={clsx(placeholderAnimationClass, classes.productDetails)} />
		<ProductAmountPlaceholder />
		<Subtitle divider><CharactersPlaceholder n={8} /></Subtitle>
		<List>
			<ListItemPlaceholder secondaryAction />
			<ListItemPlaceholder secondaryAction />
			<ListItemPlaceholder secondaryAction />
		</List>
		<Subtitle divider><CharactersPlaceholder n={8} /></Subtitle>
		<List>
			<ListItemPlaceholder secondaryAction />
			<ListItemPlaceholder secondaryAction />
			<ListItemPlaceholder secondaryAction />
		</List>
		<Subtitle divider><CharactersPlaceholder n={8} /></Subtitle>
		<List>
			<ListItemPlaceholder secondaryAction />
			<ListItemPlaceholder secondaryAction />
			<ListItemPlaceholder secondaryAction />
			<ListItemPlaceholder secondaryAction />
			<ListItemPlaceholder secondaryAction />
			<ListItemPlaceholder secondaryAction />
		</List>
	</>
	);
}
