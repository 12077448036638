import { createContext, useContext, useState } from "react";
import { ProviderProps } from "../types/provider";

const useCompanyProviderState = () => {
    const [ companyID, setCompanyID ] = useState<string | null>(null);

    console.log(companyID)

    return {
        companyID,
        setCompanyID: (newID: string) => setCompanyID(newID),
        clearCompanyID: () => setCompanyID(null)
    }
}

const CompanyContext = createContext({} as ReturnType<typeof useCompanyProviderState>);
export const useCompany = () => useContext(CompanyContext);

export function CompanyProvider({ children }: ProviderProps) {
    const companyProviderValue = useCompanyProviderState();
    return (
    <CompanyContext.Provider value={companyProviderValue}>
        {children}
    </CompanyContext.Provider>
    )
}