import * as React from "react";
import SVGProps from "./interfaces/props";
import { useTheme } from "@material-ui/core";

const SvgUserOrdersSvg = (props: SVGProps): JSX.Element => {
  const theme = useTheme();
  return (
    <svg
      width="329.03mm"
      height={1326.35}
      viewBox="0 0 329.03 350.93"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <g stroke="#050000">
        <rect
          transform="matrix(.99657 -.0828 .15362 .98813 0 0)"
          x={57.897}
          y={17.924}
          width={144.27}
          height={146.69}
          ry={10.083}
          fill="#bd8e00"
          strokeWidth={3.571}
        />
        <path
          d="M24.12 2.171c-12.27 0-22.148 8.595-22.148 19.27v236.94c0 10.675 9.878 19.27 22.148 19.27h170.3c1.079 0 2.139-.068 3.175-.196-10.023-1.849-17.499-9.56-17.499-18.847V21.668c0-9.736 8.218-17.74 18.972-19.073a25.618 25.618 0 00-4.648-.423z"
          fill="#ffbf00"
          strokeWidth={3.922}
        />
        <g
          transform="translate(193.32 -.812)"
          fill={theme.palette.secondary.main}
          strokeWidth={3.287}
        >
          <rect
            x={-110.2}
            y={96.644}
            width={84.504}
            height={1.264}
            ry={0.632}
          />
          <rect
            x={-110.2}
            y={141.64}
            width={84.504}
            height={1.265}
            ry={0.632}
          />
          <rect
            x={-110.2}
            y={186.64}
            width={84.504}
            height={1.264}
            ry={0.632}
          />
          <rect
            x={-110.2}
            y={231.64}
            width={84.504}
            height={1.264}
            ry={0.632}
          />
        </g>
        <g
          transform="translate(193.32 -.812)"
          fill="#bd8e00"
          strokeWidth={4.316}
        >
          <rect
            x={-176.31}
            y={81.047}
            width={47.335}
            height={29.306}
            ry={9.912}
          />
          <rect
            x={-176.31}
            y={125.1}
            width={47.335}
            height={29.306}
            ry={9.912}
          />
          <rect
            x={-176.31}
            y={168.18}
            width={47.335}
            height={29.306}
            ry={9.912}
          />
          <rect
            x={-176.31}
            y={213.37}
            width={47.335}
            height={29.306}
            ry={9.912}
          />
        </g>
        <rect
          x={3.14}
          y={32.73}
          width={175.8}
          height={1.184}
          ry={0.592}
          fill="none"
          strokeWidth={3.949}
        />
      </g>
      <g transform="translate(194.3 -26.3) scale(1.0159)" strokeWidth={0}>
        <path
          d="M-23.13 177.2s37.584-11.796 86.435 6.466c48.854 18.262 61.518 55.047 61.518 55.047s37.183 99.48-56.425 105.23c-93.608 5.745-111.99-3.347-120.64-5.786-8.653-2.44-55.716-13.54-56.045-66.494-.328-52.952 29.935-69.217 29.935-69.217s20.259-16.259 48.481-23.437c28.223-7.178 6.744-1.807 6.744-1.807z"
          fill={theme.palette.primary.main}
        />
        <path
          d="M-23.149 177.35s37.277-11.49 86.127 5.344c48.85 16.834 61.892 51.507 61.892 51.507s38.193 93.747-54.929 100.02c-93.122 6.267-111.53-2.166-120.18-4.396-8.644-2.23-55.628-12.309-56.579-62.382-.951-50.07 28.985-65.722 28.985-65.722s19.976-15.556 47.987-22.596c28.012-7.04 6.692-1.77 6.692-1.77z"
          fill="#fff"
          opacity={0.295}
        />
        <path d="M-42 216.453c4.106.168 8.457 3.41 7.15 6.73-7.699 19.536-4.99 36.726-2.213 53.9.569 3.519-3.57 6.29-7.677 6.12s-6.755-3.187-7.15-6.729c-1.894-16.978-2.687-34.37 2.212-53.9.868-3.458 3.57-6.29 7.677-6.121zM23.283 220.976c4.107.169 8.463 3.283 7.16 6.462-7.663 18.711-4.925 35.19-2.12 51.653.576 3.374-3.558 6.022-7.665 5.853-4.107-.168-6.76-3.066-7.16-6.461-1.924-16.276-2.746-32.945 2.12-51.654.86-3.312 3.558-6.022 7.665-5.853z" />
        <path d="M6.75 211.315c1.363-2.87 5.123-4.906 7.24-2.888 12.461 11.874 26.277 15.57 40.1 19.214 2.833.746 3.683 4.6 2.32 7.47-1.362 2.868-4.442 3.765-7.24 2.888-13.41-4.208-26.802-9.336-40.1-19.215-2.353-1.749-3.682-4.6-2.32-7.47zM-70.854 223.972c-.762-2.873.687-6.615 3.413-6.47 16.048.859 28.121-4.93 40.169-10.764 2.468-1.195 5.401 1.03 6.163 3.903.762 2.874-.89 5.39-3.413 6.47-12.095 5.179-24.73 9.692-40.169 10.764-2.733.19-5.401-1.03-6.163-3.903z" />
        <path
          d="M19.588 224.82a34.682 33.731 0 00-34.545 33.732 34.682 33.731 0 0034.682 33.732 34.682 33.731 0 0034.682-33.732 34.682 33.731 0 00-34.682-33.732 34.682 33.731 0 00-.138 0zm.185 2.384a31.975 31.098 0 01.01 0 31.975 31.098 0 0131.976 31.098A31.975 31.098 0 0119.783 289.4a31.975 31.098 0 01-31.974-31.098 31.975 31.098 0 0131.964-31.098z"
          fill={theme.palette.secondary.main}
        />
        <ellipse
          cx={19.688}
          cy={257.9}
          rx={31.861}
          ry={30.99}
          fill="#999"
          opacity={0.565}
        />
        <path
          d="M31.561 233.57a26.505 23.364 0 00-35.403 10.69 26.505 23.364 0 00.004 20.626 28.011 24.691 0 012.322-15.771 28.011 24.691 0 0137.498-11.262 28.011 24.691 0 015.572 3.19 26.505 23.364 0 00-9.915-7.44 26.505 23.364 0 00-.078-.033z"
          fill="#f9f9f9"
        />
        <g fill={theme.palette.secondary.main}>
          <circle cx={56.354} cy={262.74} r={4.998} />
          <circle cx={60.817} cy={269.41} r={4.998} />
          <circle cx={66.766} cy={276.42} r={4.998} />
          <circle cx={90.468} cy={278.96} r={4.998} />
          <circle cx={82.301} cy={282.26} r={4.998} />
          <circle cx={98.201} cy={273.55} r={4.998} />
          <circle cx={73.88} cy={282.23} r={4.998} />
          <circle cx={104.77} cy={267.16} r={4.998} />
          <circle cx={121.69} cy={249.3} r={3.727} />
          <circle cx={117.11} cy={254.64} r={4.489} />
          <circle cx={111.49} cy={261.06} r={4.998} />
          <circle cx={124.48} cy={244.47} r={2.965} />
          <circle cx={125.75} cy={240.66} r={1.949} />
        </g>
      </g>
      <g fill="#bd8e00" stroke="#000">
        <g strokeWidth={4}>
          <path d="M2 32.789v34.673l.687 1.153 21.098-35.826zM67.06 34.16l21.335 35.827 21.098-35.826zM112.17 34.16l20.11 33.77 19.886-33.77zM24.67 33.475L45.188 67.93l20.29-34.455z" />
        </g>
        <path
          d="M158.115 34.845l20.11 33.768 1.38-2.344V34.845z"
          strokeWidth={3.99992044}
        />
      </g>
      <g transform="matrix(.86343 -.40276 .40276 .86343 212.83 -13.848)">
        <rect
          x={-233.84}
          y={149.49}
          width={21.944}
          height={148.8}
          ry={10.972}
          fill={theme.palette.primary.main}
          stroke="#050000"
          strokeWidth={4}
        />
        <path
          d="M-233.61 291.05l10.81 22.807 10.689-22.807z"
          fill="#bd8e00"
          stroke="#000"
          strokeWidth={4}
        />
        <g transform="translate(-177.61 19.201)" strokeWidth={0}>
          <rect
            x={-56.529}
            y={113.19}
            width={24.314}
            height={22.256}
            ry={1.641}
            fill="#fff"
          />
          <rect
            x={-56.53}
            y={113.19}
            width={24.314}
            height={22.256}
            ry={1.641}
            fill={theme.palette.primary.main}
            opacity={0.474}
          />
        </g>
        <rect
          x={-234.88}
          y={133.7}
          width={24.314}
          height={22.256}
          ry={1.641}
          fill="none"
          stroke="#050000"
          strokeWidth={4}
        />
      </g>
      <text
        transform="rotate(17.914 28530.034 -5812.88) scale(9.8555)"
        fill={theme.palette.text.hint}
        fontFamily="sans-serif"
        fontSize={10.583}
      >
        <tspan x={348.23} y={866.53}>
          <tspan>{"?"}</tspan>
        </tspan>
      </text>
    </svg>
  );
};

export default SvgUserOrdersSvg;
