import { Divider, makeStyles } from "@material-ui/core"
import clsx from "clsx";
import { ReactNode } from "react"

const useStyles = makeStyles(theme => ({
    container: {
        display: 'flex',
        flexGrow: 1,
        color: theme.palette.text.secondary,
        fontWeight: 'bold',
        padding: theme.spacing(1)
    }
}))

type Props = {
    children: ReactNode;
    className?: string;
    divider?: boolean;
}
export default function Subtitle({
    children,
    className,
    divider=false
}: Props) {
    const classes = useStyles();
    return (
    <>
        <div className={clsx(classes.container, className)}>
            {children}
        </div>
        {divider && <Divider />}
    </>
    )
}