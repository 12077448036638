import { Hidden, List, ListItem, ListItemIcon, ListItemText } from "@material-ui/core";
import { AddressIcon } from "../../../../components/Icons";
import DeliveryMethod, { DeliveryMethodUtils } from "../../../../constants/delivery_method";
import PaymentMethod, { PaymentMethodUtils } from "../../../../constants/payment_method";
import { OrderWrapper } from "../../../../types/domain";
import { formatAddress, formatMoney } from "../../../../utils/format";
import ErrorIcon from '@material-ui/icons/Error';
import MonetizationOnIcon from '@material-ui/icons/MonetizationOn';

type OrderHeaderProps = {
    order: OrderWrapper;
}
export function OrderHeader({ order }: OrderHeaderProps) {

    const deliveryMethod = DeliveryMethodUtils[order.order.delivery_method_id];

    const returnTextMethodAndTable = ()  => {
        if (DeliveryMethod.TABLE === order.order.delivery_method_id){
            return (deliveryMethod.description + ":  " + order.order.table)
        } 
        return(deliveryMethod.description)
        
    }


    return (
    <List disablePadding>
        <ListItem dense>
            <Hidden xsDown><ListItemIcon>{deliveryMethod.icon}</ListItemIcon></Hidden>
            <ListItemText
                primary={returnTextMethodAndTable()}
                secondary="Método de entrega"
            />
        </ListItem>
        {DeliveryMethodUtils.switch(order.order.delivery_method_id, {
            [DeliveryMethod.DELIVERY]: () => (
            <>
                <ListItem dense>
                    <Hidden xsDown>
                        <ListItemIcon>
                            <AddressIcon />
                        </ListItemIcon>
                    </Hidden>
                    {order.order.address !== null ? (
                        <ListItemText
                            primary={formatAddress(
                                order.order.address.street,
                                order.order.address.number,
                                undefined,
                                undefined,
                                undefined,
                                undefined,
                                undefined
                            )}
                            secondary={formatAddress(
                                undefined,
                                undefined,
                                order.order.address.district,
                                order.order.address.city,
                                order.order.address.state,
                                order.order.address.complement,
                                order.order.address.zipcode
                            )}
                        />
                    ) : (
                        <ListItemText
                            primary="Endereço não encontrado"
                            secondary="Isso é um erro"
                        />
                    )}
                </ListItem>
                {order.order.payment_method_id !== null ? (
                    <ListItem dense>
                        <Hidden xsDown>
                            <ListItemIcon>
                                {PaymentMethodUtils[order.order.payment_method_id].icon}
                            </ListItemIcon>
                        </Hidden>
                        <ListItemText
                            primary={
                                `${PaymentMethodUtils[order.order.payment_method_id].description}${(
                                    order.order.payment_method_id === PaymentMethod.MONEY && order.order.need_change ? (
                                        ` (troco p/ R$ ${formatMoney(order.order.change_for)})`
                                    ) : ``
                                )}`
                            }
                            secondary="Forma de pagamento"
                        />
                    </ListItem>
                ): (
                    <ListItem dense>
                        <Hidden xsDown>
                            <ListItemIcon><ErrorIcon /></ListItemIcon>
                        </Hidden>
                        <ListItemText
                            primary="Forma de pagamento não fornecida"
                            secondary="Isso é um erro do aplicativo, contate o suporte"
                        />
                    </ListItem>
                )}
            </>
        )})}
        <ListItem dense>
            <Hidden xsDown><ListItemIcon><MonetizationOnIcon /></ListItemIcon></Hidden>
            <ListItemText
                secondary={formatMoney(order.order.total)}
                primary={`${order.order.items_count} item(s)`}
            />
        </ListItem>
    </List>
    );
}