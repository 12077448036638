import {ListItem, ListItemAvatar, ListItemSecondaryAction, ListItemText, makeStyles } from "@material-ui/core";
import clsx from "clsx";
import { memo } from 'react';
import { AvatarPlaceholder } from "./AvatarPlaceholder";
import { IconButtonPlaceholder } from "./IconButtonPlaceholder";
import { ParagraphPlaceholder } from "./ParagraphPlaceholder";


const useStyles = makeStyles(theme => ({
    secondaryActionPadding: {
        maxWidth: `calc(100% - ${theme.spacing(3)}px)`,
    },
    primaryMargin: {
        marginBottom: theme.spacing(0.5)
    },
}));

type Props = {
    secondaryLines?: number;
    avatar?: boolean;
    secondaryAction?: boolean;
    className?: string;
}
function Component({
    secondaryLines = 0,
    avatar = false,
    secondaryAction = false,
    className = undefined
}: Props) {

    const classes = useStyles();

    return (
        <ListItem
            className={className}
        >
            {avatar && (
            <ListItemAvatar>
                <AvatarPlaceholder />
            </ListItemAvatar>
            )}
            <ListItemText
                primaryTypographyProps={{
                    className: clsx(
                        secondaryLines !== 0 && classes.primaryMargin,
                        secondaryAction && classes.secondaryActionPadding
                    )
                }}
                secondaryTypographyProps={{
                    className: clsx(
                        secondaryAction && classes.secondaryActionPadding
                    )
                }}
                secondary={secondaryLines !== 0 && (
                    <ParagraphPlaceholder lines={secondaryLines} />
                )}
            >
                <ParagraphPlaceholder lines={1} />
            </ListItemText>
            {secondaryAction && (
            <ListItemSecondaryAction>
                <IconButtonPlaceholder />
            </ListItemSecondaryAction>
            )}
        </ListItem>
    );
}

export const ListItemPlaceholder = memo(Component, () => true)