import Form, { InputProps } from '../components/Form';
import { BodyRegister, useRegister } from '../mutations';
import { MutationOptions } from '../hooks/react-query';
import { ResponseMessage } from '../types/domain';

const inputs: InputProps<keyof BodyRegister, BodyRegister>[] = [{
    name: 'email',
    type: 'email',
    label: 'E-mail',
    maxChar: 50
},{
    name: 'name',
    type: 'text',
    label: 'Nome',
    maxChar: 50
},{
    name: 'phone',
    type: 'phone',
    label: 'Telefone/Celular'
},{
    name: 'password',
    type: 'new-password',
    label: 'Senha',
    grid: {
        xs: 6
    },
    maxChar: 30
},{
    name: 'password_confirm',
    type: 'new-password',
    label: 'Confirme a senha',
    grid: {
        xs: 6
    },
    maxChar: 30
}];

type Props = MutationOptions<BodyRegister, ResponseMessage>
export default function RegisterForm({
    ...options
}: Props) {
    const register = useRegister();
    return (
    <Form
        submitLabel='Cadastrar'
        mutation={register}
        fixedValues={{}}
        inputs={inputs}
        {...options}
    />
    );
}