import { InputBase, makeStyles, Paper, Tooltip } from "@material-ui/core";
import { useState } from "react";
import { fade } from '@material-ui/core/styles/colorManipulator';
import SearchIcon from '@material-ui/icons/Search';
import clsx from "clsx";
import { placeholderAnimationClass } from "../../../../providers/Theme";
import { useOpenSearch } from "./hooks";

const useStyles = makeStyles(theme => ({
    searchInputContainer: {
        display: 'flex',
        flexGrow: 1
    },
    searchInputPaper: {
        display: 'flex',
        flexGrow: 1,
        paddingLeft: theme.spacing(1),
        paddingRight: theme.spacing(1),
        alignItems: 'center',
        width: '100%',
        maxWidth: '300px',
        backgroundColor: fade(theme.palette.background.paper, 0.8),
        '&:focus-within': {
            backgroundColor: theme.palette.background.paper
        },
    },
    searchInputIcon: {
        color: theme.palette.text.hint
    }
}));

export function SearchInput() {
    const classes = useStyles();

    const [ query, setQuery ] = useState('');

    const { openSearch } = useOpenSearch();

    return (
        <div className={classes.searchInputContainer}>
            <Tooltip title="Pesquisar produtos no cardápio">
                <Paper className={classes.searchInputPaper}>
                    <InputBase
                        placeholder="Pesquisar..."
                        type="search"
                        fullWidth
                        value={query}
                        onKeyDown={e => {
                            if (e.key === 'Enter' && query.length > 0) {
                                openSearch(query);
                                setQuery('');
                                e.currentTarget.blur();
                            }
                        }}
                        onChange={e => {
                            setQuery(e.currentTarget.value);
                        }}
                    />
                    <SearchIcon className={classes.searchInputIcon} />
                </Paper>
            </Tooltip>
        </div>
    );
}

export function SearchInputPlaceholder() {
    const classes = useStyles();

    return (
    <div className={classes.searchInputContainer}>
        <Paper className={clsx(
            classes.searchInputPaper,
            placeholderAnimationClass
        )}>
            <InputBase disabled />
        </Paper>
    </div>
    );
}

