import {
    Card as MuiCard,
    List,
    ListItem,
    ListItemText,
    CardActionArea,
    makeStyles,
    Step,
    StepLabel,
    Stepper,
    Typography,
    CardHeader,
    CardContent,
    Divider,
    Avatar,
    ListItemAvatar,
    CardActions,
    Tooltip
} from "@material-ui/core";
import DeliveryMethod from "../constants/delivery_method";
import { formatMoney, formatTime } from "../utils/format";
import { OrderItem, OrderWrapper } from "../types/domain";
import { StoreIcon } from "./Icons";
import EventSeatIcon from '@material-ui/icons/EventSeat';
import ErrorIcon from '@material-ui/icons/Error';
import { useLayoutEffect, useState } from "react";
import MotorcycleIcon from '@material-ui/icons/Motorcycle';
import { Map } from './Map';
import Subtitle from "./Subtitle";
import MixableType, { MixableTypeUtils } from "../constants/mixable_type";
import ProductAvatar from "./ProductAvatar";
import { formatOrderProductDescription } from "../utils/format";
import FiberManualRecordIcon from '@material-ui/icons/FiberManualRecord';
import { CharactersPlaceholder } from "../placeholders/CharactersPlaceholder";

const useStyles = makeStyles(theme => ({
    orderCardContent: {
        height: theme.spacing(38),
        overflow: 'auto',
        padding: 0,
        display: 'flex'
    },
    verticalOrderStepperContentContainer: {
        padding: 0,
        display: 'flex',
        flexDirection: 'row',
        flexGrow: 1,
        justifyContent: 'space-between',
        '&:last-child': {
            paddingBottom: 0
        },
        width: '100%'
    },
    verticalOrderStepperContentChildren: {
        display: 'flex',
        flexGrow: 1,
        flexDirection: 'column',
        overflow: 'auto',
        width: '100%'
    },
    orderCardActions: {
        padding: 0
    },
    orderCardActionArea: {
        display: 'relative'
    },
    stepperRoot: {
        alignSelf: 'center',
        flexGrow: 0,
    },
    orderCardNumber: {
        position: 'absolute',
        top: 0,
        right: theme.spacing(1),
        color: theme.palette.secondary.contrastText,
        backgroundColor: theme.palette.secondary.main,
        padding: `${theme.spacing(0.5)}px ${theme.spacing(1)}px`,
        borderRadius: `0px 0px ${theme.shape.borderRadius}px ${theme.shape.borderRadius}px`,
        boxShadow: theme.shadows[1],
        fontWeight: theme.typography.fontWeightBold,
        fontSize: theme.typography.h5.fontSize
    },
    orderCardStatus: {
        position: 'absolute',
        bottom: 0,
        right: theme.spacing(1),
        color: theme.palette.secondary.contrastText,
        backgroundColor: theme.palette.secondary.main,
        padding: `${theme.spacing(0.5)}px ${theme.spacing(1)}px`,
        borderRadius: `${theme.shape.borderRadius}px ${theme.shape.borderRadius}px 0px 0px `,
        boxShadow: theme.shadows[1]
    },
    orderCardHeader: {
        backgroundColor: theme.palette.primary.main,
        color: theme.palette.primary.contrastText,
    },
    orderCardSubheader: {
        color: theme.palette.primary.contrastText
    },
    orderCardHeaderAvatar: {
        color: theme.palette.secondary.contrastText,
        backgroundColor: theme.palette.secondary.main,
        boxShadow: theme.shadows[1]

    },
    orderCardHeaderTitle: {
        fontWeight: theme.typography.fontWeightBold
    },
    orderItemPrimaryContainer: {
        display: 'flex',
		flexWrap: 'nowrap',
		maxWidth: '100%',
		flexDirection: 'row',
		justifyContent: 'space-between'
    },
    orderItemName: {
        fontWeight: 'bold'
    },
    orderItemPrice: {
        fontWeight: 'bold',
		color: theme.palette.success.main,
        paddingLeft: theme.spacing(1)
    },
    orderItemMixableDescription: {
		fontSize: theme.typography.fontSize

    },
    orderItemMixableTitle: {
        color: theme.palette.text.primary,
		fontWeight: theme.typography.fontWeightRegular,
		fontSize: theme.typography.fontSize
    },
    orderItemMixableDescriptionIcon: {
		fontSize: theme.typography.fontSize/2
    }
}));


export function useOrderStatus(order?: OrderWrapper) {
    if (!order) {
        return {}
    }
    return {
        completed: order.finished_at !== null || order.expired_at !== null || order.rejected_at !== null,
        accepted: order.accepted_at !== null,
        delivered: order.delivered_at !== null,
        created: order.created_at !== null,
    };
}

export function useOrderTitle(order?: OrderWrapper) {
    if (!order) return {
        title: 'Pedido inválido',
        description: '',
        icon: <ErrorIcon />,
        status: 'Inválido'
    }

    switch(order.order.delivery_method_id) {
        case DeliveryMethod.DELIVERY:
            return {
                title: order.order.address?.district,
                description: `${order.order.address?.street} - ${order.order.address?.number}`,
                icon: <MotorcycleIcon />,
                status: getStatusTitle(order)
            };
        case DeliveryMethod.LOCAL:
            return {
                title: "Retirada no local",
                description: order.order.user?.name,
                icon: <StoreIcon />,
                status: getStatusTitle(order)
            };
        case DeliveryMethod.TABLE:
            return {
                title: `Mesa ${order.order.table}`,
                description: "Consumo local",
                icon: <EventSeatIcon />,
                status: getStatusTitle(order)
            };
        case DeliveryMethod.NONE:
            return {
                title: "Pedido invalido",
                description: "Contate o suporte",
                icon: <ErrorIcon />,
                status: getStatusTitle(order)
            };
    }
}

export function getStatusTitle(order?: OrderWrapper) {
    if (!order) return 'Inválido';

    if (order.finished_at !== null) {
        return 'Finalizado';
    }

    if (order.expired_at !== null) {
        return 'Expirado';
    }

    if (order.rejected_at !== null) {
        return 'Rejeitado';
    }

    if (order.delivered_at !== null) {
        return 'Enviado'
    }

    if (order.accepted_at !== null) {
        return 'Preparação'
    }

    if (order.created_at !== null) {
        return 'Criado'
    }

    return 'Inválido';
}

type OrderCardBaseProps = {
    actions?: JSX.Element | boolean;
    onClick?: () => void;
    order: OrderWrapper;
    children: JSX.Element | JSX.Element[];
};
export function OrderCardBase({
    order,
    onClick,
    actions,
    children
}: OrderCardBaseProps) {
    const classes = useStyles();

    const {
        title,
        description,
        icon,
        status
    } = useOrderTitle(order);

    return (
    <MuiCard>
        <Tooltip title="Ver detalhes do pedido">
            <CardActionArea onClick={onClick} className={classes.orderCardActionArea}>
                <div className={classes.orderCardNumber}>{`#${order.id}`}</div>
                <div className={classes.orderCardStatus}>{status}</div>
                <CardHeader
                    className={classes.orderCardHeader}
                    avatar={(
                        <Avatar className={classes.orderCardHeaderAvatar}>{icon}</Avatar>
                    )}
                    title={title}
                    subheader={description}
                    subheaderTypographyProps={{
                        className: classes.orderCardSubheader,
                    }}
                    titleTypographyProps={{
                        className: classes.orderCardHeaderTitle
                    }}
                />
            </CardActionArea>
        </Tooltip>
        <CardContent className={classes.orderCardContent}>
            <VerticalOrderStepperContent order={order}>
                {children}
            </VerticalOrderStepperContent>
        </CardContent>
        {actions !== undefined && (<>
            <Divider />
            <CardActions disableSpacing className={classes.orderCardActions}>
                {actions}
            </CardActions>
        </>)}
    </MuiCard>
    );
}

type OrderDeliveryMapProps = {
    order: OrderWrapper;
}
export function OrderDeliveryMap({ order }: OrderDeliveryMapProps) {
    if (order.order.delivery_method_id !== DeliveryMethod.DELIVERY) {
        return null;
    }

    return (<>
    <Subtitle>Localização</Subtitle>
    <Divider />
    <Map
        latitude={order.order.address?.latitude}
        longitude={order.order.address?.longitude}
    />
    </>);
}


type VerticalOrderStepperContentProps = {
    children: JSX.Element | JSX.Element[];
    order: OrderWrapper;
}
export function VerticalOrderStepperContent({
    children,
    order
}: VerticalOrderStepperContentProps) {
    const classes = useStyles();
    return (
    <div className={classes.verticalOrderStepperContentContainer}>
        <div className={classes.verticalOrderStepperContentChildren}>
            {children}
        </div>
        <Divider flexItem orientation="vertical" />
        <OrderStepper order={order} orientation="vertical" />
    </div>
    );
}

type VerticalOrderStepperContentPlaceholderProps = {
    children: JSX.Element | JSX.Element[];
}
export function VerticalOrderStepperContentPlaceholder({ children }: VerticalOrderStepperContentPlaceholderProps) {
    const classes = useStyles();
    return (
    <div className={classes.verticalOrderStepperContentContainer}>
        <div className={classes.verticalOrderStepperContentChildren}>
            {children}
        </div>
        <Divider flexItem orientation="vertical" />
        <OrderStepperPlaceholder orientation="vertical" />
    </div>
    );
} 

type OrderStepperProps = {
    order: OrderWrapper;
    orientation?: 'vertical' | 'horizontal';
};
export function OrderStepper({
    order,
    orientation = "horizontal",
}: OrderStepperProps) {
    const classes = useStyles();

    const [ activeIndex, setActiveIndex ] = useState(0);

    const {
        completed: finished,
        accepted,
        delivered,
        created
    } = useOrderStatus(order);

    useLayoutEffect(() => {
        if (finished) {
            setActiveIndex(3);
        } else if (delivered) {
            setActiveIndex(2);
        } else if (accepted) {
            setActiveIndex(1);
        } else {
            setActiveIndex(0);
        }
    }, [
        setActiveIndex,
        finished,
        accepted,
        delivered,
        created,
    ]);

    return (
    <Stepper
        activeStep={activeIndex}
        orientation={orientation}
        className={classes.stepperRoot}
    >
        {[{
            date: order.created_at,
            label: "Criado",
        },{
            date: order.accepted_at,
            label: "Preparação",
        },{
            date: order.delivered_at,
            label: order.order.delivery_method_id === DeliveryMethod.LOCAL ? "Aguar. Retirada" : "Enviado",
        },{
            date: [
                order.finished_at,
                order.rejected_at,
                order.expired_at
            ].find(e => e !== null) || null,
            label: (
                order.rejected_at !== null ? 'Rejeitado' :
                order.expired_at !== null ? 'Expirado' : 
                'Finalizado'
            ),
        }].map(({ date, label }, index) => (
            <Step
                completed={date !== null && activeIndex >= index}
                disabled={date === null}
                key={index}
            >
                <StepLabel
                    optional={date !== null && (
                    <Typography variant="caption">
                        {label}
                    </Typography>
                    )}
                >
                    {date === null ? label : formatTime(date)}
                </StepLabel>
            </Step>
        ))}
    </Stepper>
    );
}

type OrderStepperPlaceholderProps = {
    orientation: 'vertical' | 'horizontal';
}
export function OrderStepperPlaceholder({ orientation }: OrderStepperPlaceholderProps) {

    const classes = useStyles();

    return (
    <Stepper
        activeStep={-1}
        orientation={orientation}
        className={classes.stepperRoot}
    >
        <Step disabled>
            <StepLabel
                optional={(
                <Typography variant="caption">
                    <CharactersPlaceholder n={10} />
                </Typography>
                )}
            >
                <CharactersPlaceholder n={6} />
            </StepLabel>
        </Step>
        <Step disabled>
            <StepLabel
                optional={(
                <Typography variant="caption">
                    <CharactersPlaceholder n={10} />
                </Typography>
                )}
            >
                <CharactersPlaceholder n={6} />
            </StepLabel>
        </Step>
        <Step disabled>
            <StepLabel
                optional={(
                <Typography variant="caption">
                    <CharactersPlaceholder n={10} />
                </Typography>
                )}
            >
                <CharactersPlaceholder n={6} />
            </StepLabel>
        </Step>
        <Step disabled>
            <StepLabel
                optional={(
                <Typography variant="caption">
                    <CharactersPlaceholder n={10} />
                </Typography>
                )}
            >
                <CharactersPlaceholder n={6} />
            </StepLabel>
        </Step>
    </Stepper>
    );
}

type OrderItemProp = {
    item: OrderItem;
}
function OrderMixableItem({ item }: OrderItemProp) {
    const classes = useStyles();
    return (
    <ListItem>
        <ListItemAvatar>
            <ProductAvatar
                image_id={null}
				name={item.mixable?.name || 'Não identificado'}
				withAmount
				amount={1}
            />
        </ListItemAvatar>
        <ListItemText
            primaryTypographyProps={{
                component: 'div',
                className: classes.orderItemPrimaryContainer
            }}
            secondary={<>
		    {item.products.map((product, index) => {
                const description = formatOrderProductDescription(product);
                return (
                    <Typography
                        key={index}
                        component="div"
                        className={classes.orderItemMixableDescription}
                    >
                        <Typography component="span" className={classes.orderItemMixableTitle}>
                            <FiberManualRecordIcon
                                className={classes.orderItemMixableDescriptionIcon}
                            />
                            {item.mixable !== null && MixableTypeUtils.switch(item.mixable.type_id, {
                                [MixableType.FAIR_PRICE]: ` 1/${item.products.length} - `,
                                [MixableType.FIXED_PRICE]: ` 1/${item.products.length} - `,
                                [MixableType.UNFAIR_PRICE]: ` 1/${item.products.length} - `,
                                [MixableType.COMBO]: ` 1 - `,
                            })}
                            {product.name}
                            {description.length === 0 ? "" : " -"}
                            {" "}
                        </Typography>
                        {description}
                    </Typography>
                    )
                })}
            </>}
			secondaryTypographyProps={{
				component: "div"
			}}
        >
                <Typography noWrap className={classes.orderItemName}>
                    {item.mixable?.name || 'Não identificado'}
                </Typography>
                <Typography noWrap className={classes.orderItemPrice}>
                    {formatMoney(item.total)}
                </Typography>
        </ListItemText>
    </ListItem>
    );
}

function OrderProductItem({ item }: OrderItemProp) {
    const classes = useStyles();
    const {
        products: [
            product
        ]
    } = item;
    return (
        <ListItem>
            <ListItemAvatar>
                <ProductAvatar
                    image_id={null}
                    name={product.name}
                    withAmount
                    amount={product.amount}
                />
            </ListItemAvatar>
            <ListItemText
                secondary={formatOrderProductDescription(product)}
                secondaryTypographyProps={{
                    component: "div"
                }}
                primaryTypographyProps={{
                    component: 'div',
                    className: classes.orderItemPrimaryContainer
                }}
            >
                    <Typography noWrap className={classes.orderItemName}>
                        {product.name}
                    </Typography>
                    <Typography noWrap className={classes.orderItemPrice}>
                        {formatMoney(item.total)}
                    </Typography>
            </ListItemText>
        </ListItem>
        );
}

type OrderItemListProps = {
    order: OrderWrapper;
}
export function OrderItemList({ order }: OrderItemListProps) {
    return (
    <List>
        {order.order.items.map((item, index) => (
            item.mixable === null || item.mixable.type_id === MixableType.NONE ? (
            <OrderProductItem item={item} key={index} />
            ) : (
            <OrderMixableItem item={item} key={index} />
            )
        ))}
    </List>
    );
}