import { TextField } from "@material-ui/core";
import NumberFormat from "react-number-format";

type Props = {
    onChange?: (newValue: number) => void;
    name?: string;
    label: string;
    value?: number;
    disabled?: boolean;
    error?: string;
}
export default function MoneyInput({
    onChange,
    name,
    label,
    value,
    disabled,
    error,
}: Props) {
    return (
    <NumberFormat
        customInput={TextField}
        fullWidth
        onValueChange={valuer => (
            onChange &&
            valuer.floatValue &&
            onChange(Math.round(valuer.floatValue*100))
        )}
        allowLeadingZeros={false}
        thousandSeparator="."
        decimalSeparator=","
        decimalScale={2}
        fixedDecimalScale={true}
        allowNegative={false}

        name={name}
        label={label}
        value={value && value/100}
        type="tel"
        color="secondary"
        error={error !== undefined}
        helperText={error}
        disabled={disabled}
    />
    );
}