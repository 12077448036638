import Form, { InputProps } from '../components/Form';
import { BodySendPasswordRecover, useSendPasswordRecover } from '../mutations';

const inputs: InputProps<keyof BodySendPasswordRecover, BodySendPasswordRecover>[] = [{
    name: 'email',
    type: 'email',
    label: 'E-mail',
}];

export default function SendPasswordRecoverForm() {
    const sendPasswordRecover = useSendPasswordRecover();
    return (
    <Form
        submitLabel='Enviar e-mail de recuperação'
        mutation={sendPasswordRecover}
        fixedValues={{}}
        inputs={inputs}
    />
    );
}