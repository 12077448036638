import {
    Avatar,
    ButtonBase,
    Divider,
    IconButton,
    List,
    ListItem,
    ListItemAvatar,
    ListItemIcon,
    ListItemSecondaryAction,
    ListItemText,
    makeStyles,
    Switch,
    Tooltip
} from "@material-ui/core";
import { ListItemPlaceholder } from "../placeholders/ListItemPlaceholder";
import PlaylistAddIcon from '@material-ui/icons/PlaylistAdd';
import DeleteIcon from '@material-ui/icons/Delete';
import AddCircleIcon from '@material-ui/icons/AddCircle';
import { formatMoney } from "../utils/format";
import { useConfirmDialog } from "../providers/ConfirmDialogs";
import { ProductIcon } from "./Icons";
import EditIcon from '@material-ui/icons/Edit';
import { Container as DNDContainer, Draggable } from "react-smooth-dnd";
import DragHandleIcon from "@material-ui/icons/DragHandle";
import { Repeat } from "./Repeat";
import { useImageSource } from "../hooks/gallery";

const useStyles = makeStyles(theme => ({
    list: {
        width: '100%',
        padding: 0
    },
    item: {
        backgroundColor: theme.palette.background.paper,
        "&:hover > $itemSecondaryAction": {
            opacity: 1 
        },
        "&:hover": {
            backgroundColor: theme.palette.action.hover
        }
    },
    itemSecondaryAction: {
        opacity: 0,
        height: '100%',
        right: 0,
        paddingLeft: theme.spacing(10),
        alignItems: 'center',
        display: 'flex',
        backgroundImage: `linear-gradient(to left, ${theme.palette.background.paper} 75%,  transparent);`
    },
    price: {
        color: theme.palette.success.main,
        fontWeight: theme.typography.fontWeightMedium
    },
    addIcon: {
        color: theme.palette.success.main
    },
    avatarContainer: {
        position: 'relative',
        display: 'flex',
        height: theme.spacing(5),
        width: theme.spacing(5),
        borderRadius: '50%',
        overflow: 'hidden',
        boxShadow: theme.shadows[2]
    },
    avatar: {
        position: 'absolute',
        top: 0,
        left: 0,
        backgroundColor: theme.palette.secondary.main,
        color: theme.palette.secondary.contrastText,
        zIndex: 1,
        boxShadow: 'none'
    },
    avatarHover: {
        position: 'absolute',
        top: 0,
        left: 0,
        height: theme.spacing(5),
        width: theme.spacing(5),
        zIndex: 2,
        backgroundColor: 'rgba(0,0,0,0.7)',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        color: 'white',
        opacity: 0,
        transition: `opacity ${theme.transitions.duration.shortest}ms ${theme.transitions.easing.easeInOut}`,
        cursor: 'pointer',
        "&:hover": {
            opacity: 1
        }
    },
    avatarInput: {
        display: 'none'
    }
}));

const DNDClass = 'drag-handle';

type EnableCallback<ID extends number | string> = (id: ID, enable: boolean) => void;
type EditCallback<ID extends number | string> = (id: ID) => void;
type AddCallback = () => void;
type RemoveCallback<ID extends number | string> = (id: ID) => void;
type AvatarOnClickCallback<ID extends number | string> = (id: ID, imageID: string | null) => void;
type MoveCallback<ID extends number | string> = (product_id: ID, target_product_id: ID) => void;

type AvatarProps = {
    image_id: string | null;
    onClick: (image_id: string | null) => void;
    title: string;
};
function AvatarUploader({
    image_id,
    onClick,
    title
}: AvatarProps) {
    const classes = useStyles();
    const { small } = useImageSource(image_id);
    return (
    <div className={classes.avatarContainer} onClick={() => onClick(image_id)}>
        <Avatar
            alt={title}
            src={small}
            className={classes.avatar}
        >
            <ProductIcon />
        </Avatar>
        <ButtonBase className={classes.avatarHover}>
            <EditIcon />
        </ButtonBase>
    </div>
    );
} 

// Item component
type Item<ID extends number | string> = {
    id: ID;
    title: string;
    description?: string;
    price?: number;
    enabled: boolean;
    icon?: JSX.Element;
    image_id?: string | null;
}
type ItemProps<ID extends number | string> =
    Item<ID> & {
        loading: boolean;
        enable?: EnableCallback<ID>;
        openEdit?: EditCallback<ID>;
        remove?: RemoveCallback<ID>;
        avatarOnClick?: AvatarOnClickCallback<ID>;
        move?: MoveCallback<ID>;
    }
function ItemComponent<ID extends number | string>({
    id,
    loading,
    title,
    description,
    price,
    icon,
    image_id,
    enable,
    enabled,
    openEdit,
    remove,
    avatarOnClick,
    move
}: ItemProps<ID>) {
    const classes = useStyles();
    return (
        <ListItem
            disabled={!enabled}
            classes={{ container: classes.item }}
        >
            {avatarOnClick && image_id !== undefined && (
            <ListItemAvatar>
                <AvatarUploader
                    image_id={image_id}
                    onClick={image_id => avatarOnClick(id, image_id)}
                    title={title}              
                />
            </ListItemAvatar>
            )}
            {icon && <ListItemIcon>{icon}</ListItemIcon>}
            <ListItemText
                primary={title}
                secondaryTypographyProps={{ component: "div" }}
                secondary={(
                <div>
                    {description}
                    {price !== undefined && (
                    <div className={classes.price}>
                        {formatMoney(price)}{' '}
                    </div>
                    )}
                </div>
                )}
            />
            <ListItemSecondaryAction className={classes.itemSecondaryAction}>
                {openEdit !== undefined && (
                <Tooltip
                    title="Visualizar e editar item"
                    leaveTouchDelay={0}
                >
                    <span>
                        <IconButton
                            disabled={loading}
                            onClick={() => openEdit(id)}
                        >
                            <PlaylistAddIcon />
                        </IconButton>
                    </span>
                </Tooltip>)
                }
                {enable !== undefined && (
                <Tooltip
                    title="Habilitar/desabilitar item"
                    leaveTouchDelay={0}
                >
                    <span>
                        <Switch
                            disabled={loading}
                            color="secondary"
                            size="small"
                            checked={enabled}
                            onChange={() => enable(id, enabled)}
                        />
                    </span>
                </Tooltip>)}
                {remove !== undefined && (
                <Tooltip
                    title="Deletar item"
                    leaveTouchDelay={0}
                >
                    <span>
                        <IconButton
                            disabled={loading}
                            onClick={e => remove(id)}
                        >
                            <DeleteIcon />
                        </IconButton>
                    </span>
                </Tooltip>)}
                {move !== undefined && (
                <Tooltip
                    title="Arraste para reordenar"
                    leaveTouchDelay={0}
                >
                    <span>
                        <IconButton
                            disableRipple
                            className={DNDClass}
                            disabled={loading}
                        >
                            <DragHandleIcon />
                        </IconButton>
                    </span>
                </Tooltip>
                )}
                
            </ListItemSecondaryAction>
        </ListItem>
    );
}


// Add list item
type AddItemProps = {
    openAdd: AddCallback;
    addLabel: string;
    disabled?: boolean;
}
function AddItem({
    openAdd,
    addLabel,
    disabled = false
}: AddItemProps) {
    const classes = useStyles();
    return (
    <Tooltip title="Clique para abrir o formulário">
        <ListItem
            dense
            button
            disabled={disabled}
            onClick={() => !disabled && openAdd()}
        >
            <ListItemIcon>
                <AddCircleIcon className={classes.addIcon} />
            </ListItemIcon>
            <ListItemText
                primary={addLabel}
                secondary={"Clique para abrir o formulário"}
            />
        </ListItem>
    </Tooltip>
    );
}

// Main component
type Props<ID extends number | string> = {
    loading: boolean;
    controlsLoading: boolean;
    addLabel?: string;
    disabledAdd?: boolean;
    subject: string;
    subjectPrefix: string;
    openAdd?: AddCallback;
    openEdit?: EditCallback<ID>;
    enable?: EnableCallback<ID>;
    move?: MoveCallback<ID>;
    remove: RemoveCallback<ID>;
    avatarOnClick?: AvatarOnClickCallback<ID>;
    items: Item<ID>[] | undefined;
}
export default function CRUDList<ID extends number | string>({
    loading,
    controlsLoading,
    disabledAdd=false,
    openAdd,
    openEdit,
    avatarOnClick,
    addLabel,
    enable,
    remove,
    subject,
    move,
    subjectPrefix,
    items
}: Props<ID>) {
    const classes = useStyles();

    const { openConfirmDialog } = useConfirmDialog();

    return (
        <List className={classes.list}>
            {openAdd && addLabel && <AddItem
                openAdd={openAdd}
                addLabel={addLabel}
                disabled={disabledAdd}
            />}
            {loading || items === undefined ? (
            <Repeat n={5}>
                <ListItemPlaceholder avatar secondaryLines={1} />
            </Repeat>
                
            ) : items.length === 0 ? undefined : (
            <DNDContainer
                lockAxis="y"
                dragHandleSelector={`.${DNDClass}`}
                dragBeginDelay={0}
                onDrop={({ removedIndex, addedIndex }) => {
                    if (
                        removedIndex !== addedIndex &&
                        move &&
                        items &&
                        addedIndex !== null &&
                        removedIndex !== null &&
                        items.length > removedIndex &&
                        items.length > addedIndex
                    ) {
                        move(items[removedIndex].id, items[addedIndex].id);
                    }
                }}
            >
                {items.map((item, index) => (
                    <Draggable key={`${item.id}-${index}`}>
                        <Divider />
                        <ItemComponent
                            {...item}
                            avatarOnClick={avatarOnClick}
                            loading={controlsLoading || loading}
                            enable={enable}
                            move={move}
                            remove={id => openConfirmDialog({
                                variant: "remove",
                                title: `Remover ${subject}`,
                                message: `Você tem certeza que deseja remover ${subjectPrefix} ${subject}? Isso não poderá ser desfeito.`,
                                buttonLabel: `Remover ${subject}`,
                                submit: () => remove(id)
                            })}
                            openEdit={openEdit}
                        />
                    </Draggable>
                ))}
            </DNDContainer>
            )}
            
        </List>
    );
}