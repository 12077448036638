import { makeStyles } from "@material-ui/core";
import clsx from "clsx";
import { placeholderAnimationClass } from "../providers/Theme";

const useStyles = makeStyles(theme => ({
    container: {
        display: 'flex',
        width: '100%',
        height: theme.spacing(4),
        borderRadius: theme.shape.borderRadius
    }
}));


export function TextFieldPlaceholder() {
    const classes = useStyles();
    return (
    <div className={clsx(placeholderAnimationClass, classes.container)} />
    )
}