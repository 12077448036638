import { useCallback, useEffect, useState } from "react";
import { useLocation } from "react-router";
import MixableType, { MixableTypeUtils } from "../../../../constants/mixable_type";
import { useConfirmDialog } from "../../../../providers/ConfirmDialogs";
import useErrorHandler from "../../../../providers/ErrorHandler";
import useFeedback from "../../../../providers/Feedback";
import { decodeBase64, encodeBase64 } from "../../../../utils/format";
import { MenuProduct, MenuSection, useGetMenu } from "../../queries";
import { useBag } from "../bag/BagProvider";
import { useBagEditRoute, useBagRoute, useAddRoute, useMixableRoute, useMenuRoute } from "../nav/routes";
import { isBagItem, isProductMenuInput, ProductMenuInput, ProductMenuItem, ProductMenuProduct } from "./types";

function validateFreeNote(product: ProductMenuProduct): string | undefined {
	if (product.freeNote.length > 300) {
		return "A observação deve ter no máximo 300 caracteres";
	}
	return undefined;
}

function validate(item: ProductMenuItem): string | undefined {
	if (item.products.length === 0) {
		return "Não há produtos no item";
	}
	for (let i=0; i<item.products.length; i++) {
		let validation = validateFreeNote(item.products[i]);
		if (validation !== undefined) {
			return validation;
		}
	}
	return undefined;
}

export const useOpenSingleProductMenu = () => {
	const addRoute = useAddRoute();
	const openSingleProductMenu = useCallback((section_id: number, product_id: number) => {
		addRoute.push({
			payload: encodeBase64(JSON.stringify({
				mixable_id: null,
				products: [{
					section_id,
					product_id,
					amount: 1
				}]
			}))
		})
	}, [ addRoute ]);

	return { openSingleProductMenu };
}

export const useOpenMixableProductMenu = () => {
	const addRoute = useAddRoute();

	const openMixableProductMenu = useCallback((input: ProductMenuInput) => {
		addRoute.push({
			payload: encodeBase64(JSON.stringify(input))
		});
	}, [ addRoute ]);

	return { openMixableProductMenu };
}

export const useProductMenuDialog = () => {

    const { report } = useErrorHandler();

	const [ item, setItem ] = useState<ProductMenuItem | null>(null);

    const { data: menu, isLoading: menuLoading } = useGetMenu();

	const { bag, addToBag, editFromBag, removeFromBag } = useBag();
    const { infoPopup } = useFeedback();
	const { openConfirmDialog } = useConfirmDialog();

	const [ questionsNotAnswered, setQuestionsNotAnswered ] = useState(-1);
	const [ totalQuestions, setTotalQuestions ] = useState(0);

	const addRoute = useAddRoute();
	const editRoute = useBagEditRoute();
	const menuMixableRoute = useMixableRoute();
	const menuRoute = useMenuRoute();
	const bagRoute = useBagRoute();

	const location = useLocation();

	const open =
		(addRoute.matched && addRoute.exact) ||
		(editRoute.matched && editRoute.exact);

	const isEditing = !addRoute.matched && editRoute.matched && editRoute.exact;
	const isAdding = !editRoute.matched && addRoute.matched && addRoute.exact;

	const close = useCallback(() => {
		if (isEditing) {
			bagRoute.push({});
		} else if (item !== null) {
			if (item.mixable === null || item.mixable.type_id === MixableType.NONE) {
				menuRoute.push({});
			} else {
				menuMixableRoute.push({ mixable_id: item.mixable.id });
			}
		} else {
			menuRoute.push({})
		}
	}, [
		menuRoute,
		bagRoute,
		isEditing,
		item,
		menuMixableRoute,
	]);

	const updateTotalQuestions = useCallback((item?: ProductMenuItem) => {
		if (item) {
			setTotalQuestions(item.products.reduce((acc, product) => (
				acc + product.questions.length
			), 0))
		}
	}, [ ]);

	const computeValue = useCallback((item: ProductMenuItem) => {
        var baseValue: number;
        switch(item.mixable.type_id) {
            
            case MixableType.FIXED_PRICE:
            case MixableType.COMBO:
                baseValue = item.mixable.price;
                break;
            case MixableType.UNFAIR_PRICE:
                baseValue = item.products.reduce((acc, p) => p.price > acc ? p.price: acc, 0);
                break;
            case MixableType.NONE:
            case MixableType.FAIR_PRICE:
            default:
                baseValue = item.products.reduce((acc, p) => p.price*p.amount+acc, 0)
        }

        const extras = item.products.reduce((acc, p) => (
            acc + p.amount * p.extras.reduce((acc, e) => (
                acc + e.amount*e.price
            ), 0)
        ), 0);
        return baseValue + extras;
	}, [ ]);

	const saveItem = useCallback((item: ProductMenuItem) => {
        item.price = computeValue(item);
        updateTotalQuestions(item);
        setItem(item);
	}, [ computeValue, updateTotalQuestions ]);

	const saveProduct = useCallback((product_index: number, callback: (newProduct: ProductMenuProduct) => ProductMenuProduct) => {
        setItem(previous => {
            if (!previous) {
                report({
                    message: "Erro interno do aplicativo",
                    error: "previous is null on saving product"
                });
                return previous;
            }
            if (previous.products.length <= product_index) {
                report({
                    message: "Erro interno do aplicativo",
                    error: "previous.products.length <= product_index on saving product"
                });
                return previous;
            }
            const newItem = { ...previous };
            newItem.products[product_index] = callback(newItem.products[product_index]);
            newItem.price = computeValue(newItem);
            return newItem;
        })
	}, [ computeValue, report ]);

	const getMixable = useCallback((mixable_id: number, sectionIDList: number[]): ProductMenuItem['mixable'] | null => {
        if (!menu || menuLoading) return null;
		const foundSections = menu.sections.filter(section => sectionIDList.includes(section.id));
		const foundMixables = foundSections.map(section => (
			section.mixables.find(mixable => mixable.id === mixable_id)
		)).filter(el => el !== undefined) as ProductMenuItem['mixable'][];
		if (foundSections.length !== foundMixables.length)
            return null;
		return foundMixables[0];
	}, [ menu, menuLoading ]);

	const getProductFromSection = useCallback((
		mixable: ProductMenuItem['mixable'],
		section_id: number,
		product_id: number
	): ProductMenuProduct | null => {
        if (!menu || menuLoading) return null;
		const foundSection = menu.sections.find(s => s.id === section_id);
		if (!foundSection) return null;
		const foundProduct = foundSection.products.find(p => p.id === product_id);
		if (!foundProduct) return null;	

		const section = JSON.parse(JSON.stringify({
			extras: foundSection.extras,
			notes: foundSection.notes,
			questions: foundSection.questions,
		})) as Pick<MenuSection, 'extras' | 'notes' | 'questions'>;

		const product = JSON.parse(JSON.stringify(foundProduct)) as MenuProduct;

		return {
			...product,
			freeNote: "",
			amount: MixableTypeUtils.switch(mixable.type_id, {
				[MixableType.COMBO]: 1,
				[MixableType.NONE]: 1,
			}) || 1/mixable.amount,
			ingredients: product.ingredients.map(s => ({
				...s,
				selected: true
			})),
			questions: section.questions.map(q => ({
				...q,
				selected: null,
				answers: q.answers.map(answer => ({
					...answer,
				}))
			})),
			notes: section.notes.map(n => ({
				...n,
				selected: false,
			})),
			extras: section.extras.map(e => ({
				...e,
				amount: 0,
			}))
		};
	}, [ menu, menuLoading ]);

	const loadFromSections = useCallback((rawInput: string) => {
		if (menuLoading || !menu) return;
		var input: any;
		try {
			input = JSON.parse(decodeBase64(rawInput));
		} catch (e: any) {
			console.log({
				message: "Erro interno do aplicativo",
				error: "input = JSON.parse(decodeBase64(rawInput));"
			});
			return menuRoute.push({});
		}
        
        if (!isProductMenuInput(input) || input.products.length === 0) {
            console.log({
                message: "Erro interno do aplicativo",
                error: "!isProductMenuInput(input) || input.items.length === 0"
            });
			return menuRoute.push({});
        }
        
        let mixable: ProductMenuItem['mixable'] = {
            type_id: MixableType.NONE,
            id: 0,
            name: "",
            description: "",
            amount: 0,
            image_id: null,
            price: 0
        };
        if (
            input.mixable_id !== null &&
            input.mixable_id >= 0
        ) {
            let foundMixable = getMixable(
                input.mixable_id,
                input.products.map(el => el.section_id)
            );
            if (foundMixable === null) {
                console.log({
                    message: "Erro interno do aplicativo",
                    error: "foundMixable === null"
                });
				return menuRoute.push({});
            }
            
            mixable = foundMixable;
        }

        const products = [] as ProductMenuProduct[];
        for (let product of input.products) {
            const found = getProductFromSection(
                mixable,
                product.section_id,
                product.product_id
            );
            if (!found) {
                console.log({
                    message: "Erro interno do aplicativo",
                    error: `not found getProductFromSection(mixable, product.section_id, product.product_id)`
                });
				return menuRoute.push({});
            }
            if (product.amount === null) {
                product.amount = 1;
            }
            for (let i=0; i<product.amount; i++) {
                products.push(JSON.parse(JSON.stringify(found)));
            }
        }

        saveItem({
            mixable,
            products,
            price: 0
        });
	}, [
		menuRoute,
		getMixable,
		getProductFromSection,
		menu,
		menuLoading,
		saveItem
	]);

	const loadFromBag = useCallback((index: number) => {
		if (bag) {
			if (bag.items.length <= index) {
				console.log({
					message: "Erro interno do aplicativo",
					error: "bag.items.length <= index"
				});
				console.log("push bag")
				return bagRoute.push({});
			}
			const newItem = JSON.parse(JSON.stringify(bag.items[index]))
			saveItem(newItem);
		}
	}, [
		bag,
		saveItem,
		bagRoute
	]);

	const selectAnswer = useCallback((product_index: number, question_id: number, answer_id: number) => {
		saveProduct(product_index, newProduct => {
			const index = newProduct.questions.findIndex(s => s.id === question_id);
			if (index === -1) {
				report(new Error("Erro interno do aplicativo"));
				return newProduct
			}
			newProduct.questions[index].selected = answer_id;
			return newProduct;
		})
	}, [ report, saveProduct ]);

	const toggleNote = useCallback((product_index: number, note_id: number) => {
		saveProduct(product_index, newProduct => {
			const index = newProduct.notes.findIndex(s => s.id === note_id);
			if (index === -1) {
				report({
                    message: "Erro interno do aplicativo",
                    error: "product note not found"
                });
				return newProduct
			}
			newProduct.notes[index].selected = !newProduct.notes[index].selected;
			return newProduct;
		})
	}, [ report, saveProduct ]);

	const changeExtraAmount = useCallback((product_index: number, extra_id: number, newAmount: number) => {
		saveProduct(product_index, newProduct => {
			const index = newProduct.extras.findIndex(s => s.id === extra_id);
			if (index === -1) {
				report({
                    message: "Erro interno do aplicativo",
                    error: "product extra not found"
                });
				return newProduct
			}
			newProduct.extras[index].amount = newAmount < 0 ? 0: newAmount;
			return newProduct;
		})
	}, [ report, saveProduct ]);

	const toggleIngredient = useCallback((product_index: number, supply_id: number) => {
		saveProduct(product_index, newProduct => {
			const index = newProduct.ingredients.findIndex(s => s.id === supply_id);
			if (index === -1) {
				report({
                    message: "Erro interno do aplicativo",
                    error: "product ingredient not found"
                });
				return newProduct
			}
			newProduct.ingredients[index].selected = !newProduct.ingredients[index].selected;
			return newProduct;
		})
	}, [ report, saveProduct ]);

	const setFreeNote = useCallback((product_index: number, note: string) => {
		if (item !== null) {
			let validation = validateFreeNote(item.products[product_index])
			if (validation !== undefined) {
				infoPopup({ message: validation })
			}
			saveProduct(product_index, newProduct => {
				newProduct.freeNote = note;
				return newProduct;
			})
		}
	}, [
		saveProduct,
		infoPopup,
		item
	]);

	const changeAmount = useCallback((amount: number) => {
		if (amount >= 1) {
			saveProduct(0, newProduct => {
				newProduct.amount = amount;
				return newProduct;
			})
		}
	}, [ saveProduct ]);

	const increaseAmount = useCallback(() => {
        if (!item)
            return report({
                message: "Erro interno do aplicativo",
                error: "item is null on increase amount"
            });
        if (item.products.length !== 1)
            return report({
                message: "Erro interno do aplicativo",
                error: "item.procuts.length !== 1 on increase amount"
            });
        changeAmount(item.products[0].amount+1);
	}, [ changeAmount, item, report ]);

	const decreaseAmount = useCallback(() => {
        if (!item)
            return report({
                message: "Erro interno do aplicativo",
                error: "item is null on decrease amount"
            });
        if (item.products.length !== 1)
            return report({
                message: "Erro interno do aplicativo",
                error: "item.length !== 1 on decrease amount"
            });
        changeAmount(item.products[0].amount-1);
	}, [ changeAmount, item, report ]);

	const clearProductMenu = useCallback(() => {
		setQuestionsNotAnswered(-1);
		setItem(null);
	}, [ ])

	const submit = useCallback(() => {
		if (item) {
			let validation = validate(item);
			if (validation !== undefined) {
				return infoPopup({ message: validation });
			}
			if (isBagItem(item)) {
				if (isAdding) {
					addToBag(item);
					menuRoute.push({});
				} else if (isEditing) {
					editFromBag(item, editRoute.params.index as number);
					bagRoute.push({});
				}
			} else {
				if (questionsNotAnswered === 0) {
					infoPopup({ message: "Responda às perguntas" });
				} else {
					if (totalQuestions === 1) {
						infoPopup({ message: "Responda à pergunta obrigatória" });
					} else if (questionsNotAnswered === 1) {
						infoPopup({ message: "Responda à última pergunta obrigatória" });
					} else if (questionsNotAnswered === totalQuestions) {
						infoPopup({ message: "Responda às perguntas obrigatórias" });
					} else {
						infoPopup({ message: `Faltam ${questionsNotAnswered} perguntas obrigatórias` });
					}
				}
			}
		}
	}, [
		item,
		addToBag,
		bagRoute,
		editFromBag,
		editRoute.params.index,
		infoPopup,
		isAdding,
		isEditing,
		menuRoute,
		questionsNotAnswered,
		totalQuestions
	]);

	const removeItem = useCallback(() => {
		openConfirmDialog({
			title: 'Remover item da sacola',
			message: 'Tem certeza que deseja remover o item da sacola?',
			buttonLabel: 'Remover',
			submit: () => {
				if (isEditing) {
					removeFromBag(editRoute.params.index as number);
					bagRoute.push({});
				}
			},
			variant: 'remove'
		});
	}, [
		bagRoute,
		editRoute.params.index,
		isEditing,
		openConfirmDialog,
		removeFromBag
	]);

	useEffect(() => {
		if (item) {
			setQuestionsNotAnswered(
				item.products.reduce((acc, product) => (
					acc + product.questions.filter(question => question.selected === null).length
				), 0 as number)
			);
		}
	}, [ item ]);

	useEffect(() => {
        if (menu && !menuLoading && isAdding && addRoute.params?.payload) {
            loadFromSections(addRoute.params.payload);
        } else if (isEditing) {
            loadFromBag(editRoute.params.index as number);
        }
	// eslint-disable-next-line
	}, [
		location.pathname,
		menuLoading,
        menu
	]);

    return {
		open,
		close,
		clearProductMenu,
		submit,
		removeItem,
		isEditing,
		isAdding,
		item,
		toggleNote,
		selectAnswer,
		changeExtraAmount,
		toggleIngredient,
		setFreeNote,
		increaseAmount,
		decreaseAmount
    }
}

export type ProductMenuStateType = ReturnType<typeof useProductMenuDialog>;