import { makeStyles, Typography } from "@material-ui/core";
import { StoreNotFoundSvg } from "../svg";

const useStyles = makeStyles(theme => ({
    container: {
        width: '100vw',
        height: '100vh',
        padding: '50px 0px',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        flexDirection: 'column'
    },
    message: {
		color: theme.palette.text.hint,
        fontWeight: theme.typography.fontWeightMedium,
    },
    svg: {
        height: `30vh`,
        maxWidth: '80vw',
        marginBottom: theme.spacing(3)
    }
}));

export function NotFound() {

    const classes = useStyles();

    return (
    <div className={classes.container}>
        <StoreNotFoundSvg
            className={classes.svg}
        />
        <Typography
            className={classes.message}
            variant="h4"
        >
            Loja não encontrada
        </Typography>
    </div>
    );
}