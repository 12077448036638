import { useState, createRef, RefObject, ReactNode } from 'react';
import { Grow, Accordion as MuiAccordion, AccordionSummary as MuiAccordionSummary, AccordionDetails as MuiAccordionDetails } from '@material-ui/core';
import { withStyles, makeStyles } from '@material-ui/core/styles';
import TouchAppIcon from '@material-ui/icons/TouchApp';
import useTheme from '../../../../providers/Theme';

const useStyles = makeStyles(theme => ({
	summaryWrapper: {
		position: 'relative',
		display: 'flex',
		flexGrow: 1
	},
	detailsWrapper: {
		display: 'flex',
		flexGrow: 1,
		flexDirection: 'column'
	},
	hintContainer: {
		position: 'absolute',
		top: 0,
		right: 0,
	},
	hint: {
		display: 'flex',
		alignItems: 'center',
		justifyContent: 'center',
		padding: `${theme.spacing(1)}px ${theme.spacing(1)}px`,
		fontSize: theme.typography.button.fontSize,
		borderRadius: `0px 0px 0px ${theme.shape.borderRadius}px`,
		backgroundColor: `rgba(0,0,0,0.2)`,
		color: theme.palette.common.white
	},
	hintIcon: {

	}
}));

const Accordion = withStyles({
	root: {
		padding: 0,
		boxShadow: 'none',
		'&:not(:last-child)': {
			borderBottom: 0,
		},
		'&:before': {
			display: 'none',
		},
		'&$expanded': {
			margin: 0,
		},
	},
	expanded: {},
})(MuiAccordion);

const AccordionSummary = withStyles({
	root: {
		margin: 0,
		padding: 0
	},
	content: {
		margin: 0,
		padding: 0,
		'&$expanded': {
			margin: 0,
		},
	},
	expanded: {
		margin: 0,
		padding: 0,
	},
})(MuiAccordionSummary);

const AccordionDetails = withStyles(theme => ({
	root: {
		padding: 0,
	},
}))(MuiAccordionDetails);


interface Item {
	header : ReactNode;
	content : ReactNode;
}

interface ProductsAccordionProps {
	items? : Item[];
	scrollContainer : RefObject<HTMLElement>;
	hint : ReactNode;
}

export function ProductsAccordion({ items, scrollContainer, hint } : ProductsAccordionProps) {
	const classes = useStyles();

	const { transitions } = useTheme();

	const [ expanded, setExpanded ] = useState(-1);

	if (!items) return null;

	const refs : RefObject<HTMLElement>[] = items.map(() => createRef());

	const scrollToIndex = (index : number) => {
        const top = refs[index].current?.offsetTop 
        if (top) {
            scrollContainer.current?.scrollTo({
                top,
                behavior: transitions ? 'smooth' : 'auto'
            });
        }
	};

	return (
	<>
		{items.map((item, index) => (
		<Accordion
			key={index}
			square
			ref={refs[index]}
			onChange={() => items.length !== 1 && setExpanded(expanded === index ? -1 : index)}
			expanded={items.length === 1 || expanded === index}
			TransitionProps={{
				onEntered: () => scrollToIndex(index)
			}}
		>
			<AccordionSummary disableRipple={false}>
				<div className={classes.summaryWrapper}>
					{item.header}
					<Grow in={items.length !== 1 && expanded !== index}>
						<div className={classes.hintContainer}>
							<div className={classes.hint}>
								<TouchAppIcon className={classes.hintIcon} />
								{hint}
							</div>
						</div>
					</Grow>
				</div>
			</AccordionSummary>
			<AccordionDetails>
				<div className={classes.detailsWrapper}>
					{item.content}
				</div>
			</AccordionDetails>
		</Accordion>
		))}
	</>
	);
}