import { BagItem, BagProduct, BagQuestion } from "../bag/types";

export type ProductMenuInputProduct = {
    section_id: number;
    product_id: number;
    amount: number | null; // only used when mixable is set to use_combo
};

export type ProductMenuInput = {
	mixable_id: number | null;
	products: ProductMenuInputProduct[];
}

export function isProductMenuInput(input: any): input is ProductMenuInput {
    if (input.mixable_id === undefined) return false;
    if (input.mixable_id !== null && !Number.isFinite(input.mixable_id)) return false;
    if (input.products === undefined || !Array.isArray(input.products)) return false;
    for (let i=0; i<input.products.length; i++) {
        if (input.products[i].section_id === undefined) return false;
        if (input.products[i].product_id === undefined) return false;
        if (input.products[i].amount === undefined) return false;
        if (input.products[i].amount !== null && !Number.isFinite(input.products[i].amount)) return false;
    }
    return true;
}

export type ProductMenuQuestion = Omit<BagQuestion, 'selected'> & {
    selected: number | null;
}

export type ProductMenuProduct = Omit<BagProduct, 'questions'> & {
    questions: ProductMenuQuestion[];
}

export type ProductMenuItem = Omit<BagItem, 'products'> & {
    products: ProductMenuProduct[];
}

export function isBagItem(item: ProductMenuItem): item is BagItem {
    for(let i=0; i<item.products.length; i++) {
        for(let j=0; j<item.products[i].questions.length; j++) {
            if (item.products[i].questions[j].selected === null) {
                return false;
            }
        }
    }
    return true;
}
