import Form, { InputProps } from '../components/Form';
import { useAddUserAddress, useUpdateUserAddress } from '../mutations';
import { MutationOptions } from '../hooks/react-query';
import { useGetUserAddress } from '../queries';
import { AddressID, ResponseMessage, ResponseMessageID, UserAddress } from '../types/domain';

const inputs = (
    address?: UserAddress
): InputProps<
    keyof UserAddress,
    UserAddress
>[] => [{
    name: "street",
    type: "text",
    label:"Rua",
    defaultValue: address?.street,
    maxChar: 50
}, {
    name: "district",
    type: "text",
    label:"Bairro",
    defaultValue: address?.district,
    maxChar: 50
}, {
    name: "number",
    type: "text",
    label:"Número",
    defaultValue: address?.number,
    maxChar: 10
}, {
    name: "complement",
    type: "text",
    label:"Complemento (opcional)",
    defaultValue: address?.complement,
    maxChar: 300
}, {
    name: "zipcode",
    type: "zipcode",
    label:"CEP (opcional)",
    defaultValue: address?.zipcode
}, {
    name: { latitude: "latitude", longitude: "longitude" },
    type: "location",
    label:"Localização do endereço",
    defaultValue: { latitude: address?.latitude, longitude: address?.longitude}
}, {
    name: "city",
    type: "text",
    label:"Cidade (opcional)",
    defaultValue: address?.city,
    maxChar: 50
}, {
    name: "state",
    type: "text",
    label:"Estado (opcional)",
    defaultValue: address?.state,
    maxChar: 50
}];

type AddProps = MutationOptions<UserAddress, ResponseMessageID>;;
export function AddUserAddressForm({ ...options }: AddProps) {
    const addUserAddress = useAddUserAddress();
    return (
    <Form
        submitLabel={"Adicionar endereço"}
        mutation={addUserAddress}
        inputs={inputs()}
        fixedValues={{}}
        {...options}
    />
    );
}

type EditProps = AddressID & 
    MutationOptions<UserAddress & AddressID, ResponseMessage>;

export function EditUserAddressForm({
    address_id,
    ...options
}: EditProps) {
    const updateUserAddress = useUpdateUserAddress();
    const { data, isLoading } = useGetUserAddress({ address_id });
    return (
    <Form
        submitLabel="Atualizar endereço"
        mutation={updateUserAddress}
        fixedValues={{ address_id }}
        loading={!data || isLoading}
        inputs={inputs(data)}
        {...options}
    />
    )
}