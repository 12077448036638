export function sendWhatsAppMessage(phone: string, content?: string) {
    
    let url = `https://wa.me/${phone}`;
    if (content !== undefined && content !== '') {
        const encodedContent = encodeURIComponent(content);
        url += `?text=${encodedContent}}`
    }

    const newWindow = window.open(
        url,
        'window',
        'noopener,noreferrer,screenX=0,screenY=0,top=0,left=0,scrollbars,width=500,height=500'
    );
    if (newWindow) newWindow.opener = null
}