import {
    Button,
    Checkbox,
    FormControl,
    FormControlLabel,
    FormHelperText,
    FormLabel,
    Grid,
    GridProps,
    makeStyles,
    MenuItem,
    Radio,
    RadioGroup,
    Select,
    Switch,
    TextField
} from '@material-ui/core';
import { Fragment, useState, memo, useEffect } from 'react';
import NumberFormat from 'react-number-format';
import { ButtonPlaceholder } from '../placeholders/ButtonPlaceholder';
import { TextFieldPlaceholder } from '../placeholders/TextFieldPlaceholder';
import useErrorHandler from '../providers/ErrorHandler';
import useFeedback from '../providers/Feedback';
import { MutationOptions, MutationResult } from '../hooks/react-query';
import ColorInput from './ColorInput';
import { MapInput } from './Map';
import MoneyInput from './MoneyInput';
import IntegerInput from './IntegerInput';
import { TextInput } from './TextInput';
import { formatCNPJ, formatPhone } from '../utils/format';

const useStyles = makeStyles(theme => ({
    form: {
        width: '100%'
    }
}))

// types
const BooleanTypes = [ 'switch', 'checkbox' ] as const;
// eslint-disable-next-line
type BooleanTypes = typeof BooleanTypes[number];

const TextTypes = [
    'current-password',
    'new-password',
    'text',
    'email',
    'cnpj',
    'phone',
    'zipcode',
    'color',
    'multiline'
] as const;
// eslint-disable-next-line
type TextTypes = typeof TextTypes[number];

const NumberTypes = [ 'money', 'integer' ] as const;
// eslint-disable-next-line
type NumberTypes = typeof NumberTypes[number];

type LocationType = 'location';
type OptionsType = 'select' | 'radio';


// GridParams
type GridParams = {
    grid?: {
        xs?: GridProps['xs'];
        sm?: GridProps['sm'];
        md?: GridProps['md'];
        lg?: GridProps['lg'];
        xl?: GridProps['xl'];
    }
}
// Params constraints
type ParamsConstraint<K extends string> = Record<K, number | boolean | string>;

// Common input props
type CommonInputProps<K extends string, Params extends ParamsConstraint<K>> = GridParams & {
    label: string;
    hidden?: (state: Params) => boolean;
}


// Location types
type LocationName<K extends string> =
    { latitude: K, longitude: K };

type LocationStateValue = {
    latitude: string,
    longitude: string
};


// Input props
type BooleanInputProps<K extends string, Params extends ParamsConstraint<K>> =
    CommonInputProps<K, Params> & {
        type: BooleanTypes;
        name: K;
        defaultValue?: boolean;
    }

type TextInputProps<K extends string, Params extends ParamsConstraint<K>> =
    CommonInputProps<K, Params> & {
            type: TextTypes;
            name: K;
            maxChar?: number;
            defaultValue?: string;
            placeholder?: string;
    }

type NumberInputProps<K extends string, Params extends ParamsConstraint<K>> =
    CommonInputProps<K, Params> & {
        type: NumberTypes;
        name: K;
        defaultValue?: number;
    }


type LocationInputProps<K extends string, Params extends ParamsConstraint<K>> =
    CommonInputProps<K, Params> & {
        type: LocationType;
        name: LocationName<K>;
        defaultValue?: Partial<LocationStateValue>;
    }

type OptionsInputProps<K extends string, Params extends ParamsConstraint<K>> =
    CommonInputProps<K, Params> & {
        type: OptionsType;
        name: K;
        options: {
            label: string;
            value: number;
        }[]
        defaultValue?: number;
    }

export type InputProps<K extends string, Params extends ParamsConstraint<K>> = 
    BooleanInputProps<K, Params> |
    TextInputProps<K, Params> |
    NumberInputProps<K, Params> |
    OptionsInputProps<K, Params> |
    LocationInputProps<K, Params>;

// type guards
function isLocationInput<K extends string, Params extends ParamsConstraint<K>>(
    input: InputProps<K, Params>
): input is LocationInputProps<K, Params> {
    return (input as LocationInputProps<K, Params>).name?.latitude !== undefined &&
        (input as LocationInputProps<K, Params>).name?.longitude !== undefined
}


function isOptionsInput<K extends string, Params extends ParamsConstraint<K>>(
    input: InputProps<K, Params>
): input is OptionsInputProps<K, Params> {
    return (input as OptionsInputProps<K, Params>).options !== undefined 
}

function isBooleanInput<K extends string, Params extends ParamsConstraint<K>>(
    input: InputProps<K, Params>
): input is BooleanInputProps<K, Params> {
    return !isLocationInput(input) && (BooleanTypes as readonly string[]).includes(input.type);
}

function isNumberInput<K extends string, Params extends ParamsConstraint<K>>(
    input: InputProps<K, Params>
): input is NumberInputProps<K, Params> {
    return !isLocationInput(input) && (NumberTypes as readonly string[]).includes(input.type);
}

function isTextInput<K extends string, Params extends ParamsConstraint<K>>(
    input: InputProps<K, Params>
): input is TextInputProps<K, Params> {
    return !isLocationInput(input) && (TextTypes as readonly string[]).includes(input.type);
}

type TextInputWrapperProps<K extends string, Params extends ParamsConstraint<K>> = {
    input: TextInputProps<K, Params>;
    value: string;
    error?: string;
    onChange: (value?: string) => void;
    isLoading: boolean;
}
function TextInputWrapper<K extends string, Params extends ParamsConstraint<K>>({
    input: { type, name, label, placeholder, maxChar },
    value,
    error,
    onChange,
    isLoading
}: TextInputWrapperProps<K, Params>) {
    switch(type) {
        case 'current-password':
            return (
            <TextInput
                fullWidth
                name={name}
                label={label}
                value={value}
                type="password"
                color="secondary"
                autoComplete="current-password"
                onChange={value => onChange(value)}
                maxChar={maxChar}
                error={error !== undefined}
                helperText={error}
                disabled={isLoading}
                placeholder={placeholder}
            />
            );
        case 'new-password':
            return (
            <TextInput
                fullWidth
                name={name}
                label={label}
                value={value}
                type="password"
                color="secondary"
                autoComplete="new-password"
                onChange={value => onChange(value)}
                maxChar={maxChar}
                error={error !== undefined}
                helperText={error}
                disabled={isLoading}
                placeholder={placeholder}
            />
            );
        case 'text':
            return (
            <TextInput
                fullWidth
                name={name}
                label={label}
                value={value}
                type="text"
                color="secondary"
                onChange={value => onChange(value)}
                maxChar={maxChar}
                error={error !== undefined}
                helperText={error}
                disabled={isLoading}
                placeholder={placeholder}
            />
            );
        case 'email':
            return (
            <TextInput
                fullWidth
                name={name}
                label={label}
                value={value}
                type="email"
                color="secondary"
                autoComplete="email"
                onChange={value => onChange(value)}
                maxChar={maxChar}
                error={error !== undefined}
                helperText={error}
                disabled={isLoading}
                placeholder={placeholder}
            />
            );
        case 'cnpj':
            return (
            <NumberFormat
                customInput={TextField}
                format={val => formatCNPJ(val)}
                isNumericString
                onValueChange={valuer => onChange(valuer.value)}
                fullWidth
                type="tel"
                color="secondary"
                name={name}
                label={label}
                value={value}
                error={error !== undefined}
                helperText={error}
                disabled={isLoading}
                placeholder={placeholder}
            />
            );
        case 'phone':
            return (
            <NumberFormat
                customInput={TextField}
                format={val => formatPhone(val)}
                isNumericString
                onValueChange={valuer => onChange(valuer.value)}
                fullWidth
                type="tel"
                color="secondary"
                autoComplete="tel"
                name={name}
                label={label}
                value={value}
                error={error !== undefined}
                helperText={error}
                disabled={isLoading}
                placeholder={placeholder}
            />
            );
        case 'zipcode':
            return (
            <NumberFormat
                customInput={TextField}
                format={'#####-###'}
                isNumericString
                onValueChange={valuer => onChange(valuer.value)}
                fullWidth
                type="tel"
                color="secondary"
                name={name}
                label={label}
                value={value}
                error={error !== undefined}
                helperText={error}
                disabled={isLoading}
                placeholder={placeholder}
            />
            );
        case 'color':
            return (
            <ColorInput
                name={name}
                label={label}
                value={value}
                onChange={newColor => onChange(newColor)}
                error={error}
                disabled={isLoading}
            />
            );
        case 'multiline':
            return (
            <TextInput
                fullWidth
                name={name}
                label={label}
                value={value}
                multiline
                rows={3}
                rowsMax={4}
                type="text"
                color="secondary"
                maxChar={maxChar}
                onChange={value => onChange(value)}
                error={error !== undefined}
                helperText={error}
                disabled={isLoading}
                placeholder={placeholder}
            />
            );
    }
}

type NumberInputWrapperProps<K extends string, Params extends ParamsConstraint<K>> = {
    input: NumberInputProps<K, Params>;
    value: number;
    error?: string;
    onChange: (value?: number) => void;
    isLoading: boolean;
}
function NumberInputWrapper<K extends string, Params extends ParamsConstraint<K>>({
    input: { type, name, label },
    value,
    error,
    onChange,
    isLoading
}: NumberInputWrapperProps<K, Params>) {
    switch(type) {
        case 'money':
            return (
            <MoneyInput
                onChange={onChange}
                value={value}
                error={error}
                disabled={isLoading}
                label={label}
                name={name}
            />
            );
        case 'integer':
            return (
            <IntegerInput
                onChange={onChange}
                value={value}
                error={error}
                disabled={isLoading}
                label={label}
                name={name}
            />
            );
    }
}

type BooleanInputWrapperProps<K extends string, Params extends ParamsConstraint<K>> = {
    input: BooleanInputProps<K, Params>;
    value: boolean;
    error?: string;
    onChange: (value?: boolean) => void;
    isLoading: boolean;
}
function BooleanInputWrapper<K extends string, Params extends ParamsConstraint<K>>({
    input: { type, name, label },
    value,
    error,
    onChange,
    isLoading
}: BooleanInputWrapperProps<K, Params>) {
    switch(type) {
        case 'switch':
            return (
            <FormControlLabel
                value="top"
                control={(
                <Switch
                    color="secondary"
                    checked={value}
                    name={name}
                    onChange={e => onChange(e.currentTarget.checked)}
                    disabled={isLoading}
                />
                )} 
                label={label}
                labelPlacement="end"
            />
            );
        case 'checkbox':
            return (
            <FormControlLabel
                value="top"
                control={(
                <Checkbox
                    color="secondary"
                    checked={value}
                    name={name}
                    onChange={e => onChange(e.currentTarget.checked)}
                    disabled={isLoading}
                />
                )} 
                label={label}
                labelPlacement="end"
            />
            );
    }
}


type OptionsInputWrapperProps<K extends string, Params extends ParamsConstraint<K>> = {
    input: OptionsInputProps<K, Params>;
    value: number;
    error?: string;
    onChange: (value?: number) => void
    isLoading: boolean;
}
function OptionsInputWrapper<K extends string, Params extends ParamsConstraint<K>>({
    input: { type, name, label, options },
    error,
    value,
    onChange,
    isLoading
}: OptionsInputWrapperProps<K, Params>) {
    const { report } = useErrorHandler();

    const parseSafeValue = (value: number) => {
        if (value === -1) return '';
        if (!options.map(option => option.value).includes(value)) return '';
        return value;
    }

    useEffect(() => {
        if (!isLoading) {
            if (options.length > 0 && !options.map(option => option.value).includes(value)) {
                if (options[0].value !== value) {
                    onChange(options[0].value);
                }
            }
        }
    // eslint-disable-next-line
    }, [ options, isLoading ]);

    switch (type) {
    case 'select':
        return (
        <FormControl
            fullWidth
            error={error !== undefined}
            color="secondary"
        >
            <FormLabel component="legend">{label}</FormLabel>
            <Select
                value={parseSafeValue(value)}
                disabled={isLoading || options.length === 0}                
                onChange={e => {
                    const newValue = e.target.value;
                    if (typeof newValue === 'number') {
                        onChange(newValue);
                    }  else {
                        report({
                            message: "Um erro interno ocorreu",
                            error: "Select form value is not number"
                        });
                    }
                }}
            >
                {options.map((option, index) => (
                <MenuItem
                    key={`${option.value}-${index}`}
                    value={option.value}
                >
                    {option.label}
                </MenuItem>
                ))}
                
            </Select>
            {error !== undefined && <FormHelperText>{error}</FormHelperText>}
        </FormControl>
        );
    case 'radio':
        return (
        <FormControl
            fullWidth
            component="fieldset"
            error={error !== undefined}
            color="secondary"
        >
            <FormLabel component="legend">{label}</FormLabel>
            <RadioGroup
                aria-label={label}
                name={name}
                value={parseSafeValue(value)}
                onChange={e => {
                    const newValue = parseInt(e.currentTarget.value);
                    if (!isNaN(newValue)) {
                        onChange(newValue);
                    } else {
                        report({
                            message: "Um erro interno ocorreu",
                            error: "Radio form value is NaN"
                        });
                    }
                }}
            >
                {options.map((options, index) => (
                <FormControlLabel
                    key={`${options.value}-${index}`}
                    value={options.value}
                    control={<Radio />}
                    label={options.label}
                />
                ))}
            </RadioGroup>
            {error !== undefined && <FormHelperText>{error}</FormHelperText>}
        </FormControl>
        );
    }
}

type LocationInputWrapperProps<K extends string, Params extends ParamsConstraint<K>> = {
    input: LocationInputProps<K, Params>;
    value: LocationStateValue;
    error: { latitude?: string, longitude?: string };
    onChange: (value: Partial<LocationStateValue>) => void;
    isLoading: boolean;
}
function LocationInputWrapper<K extends string, Params extends ParamsConstraint<K>>({
    input: { type, label },
    value,
    error,
    onChange,
    isLoading
}: LocationInputWrapperProps<K, Params>) {
    switch (type) {
        case 'location':
            return (
            <MapInput
                label={label}
                onChange={(lat, long) => onChange({ latitude: lat, longitude: long})}
                loading={isLoading}
                defaultLatitude={value.latitude}
                defaultLongitude={value.longitude}
                error={error.latitude === undefined && error.latitude === undefined ? undefined : (
                    `${error.latitude} ${error.longitude}`
                )}
            />
            )
    }
}

type GridWrapperProps = GridParams & {
    children: JSX.Element;
}
function GridWrapper({ children, grid }: GridWrapperProps) {
    return (
        <Grid
            item
            {...grid}
            xs={grid?.xs || 12}
        >
            {children}
        </Grid>
    );
}


function getDefaultState<K extends string, Params extends ParamsConstraint<K>>(
    inputs: InputProps<K,Params>[]
): Params {
    return inputs.reduce((acc, input) => {
        if (isTextInput(input)) {
            return {
                ...acc,
                [input.name]: input.defaultValue || ''
            }
        } else if (isNumberInput(input)) {
            return {
                ...acc,
                [input.name]: input.defaultValue || 0
            }
        } else if (isOptionsInput(input)) {
            return {
                ...acc,
                [input.name]: input.defaultValue || (
                    input.options.length > 0 ? input.options[0].value : -1
                )
            }
        } else if (isBooleanInput(input)) {
            return {
                ...acc,
                [input.name]: input.defaultValue || false
            }
        } else if (isLocationInput(input)) {
            return {
                ...acc,
                [input.name.latitude]: input.defaultValue?.latitude || '',
                [input.name.longitude]: input.defaultValue?.longitude || ''
            }
        }
        return acc;
    }, {} as Params)
}

function checkValidState<
    K extends string,
    Params extends ParamsConstraint<K>
>(stateValues: Partial<Params>): stateValues is Params {
    for (let stateKey in stateValues) {
        if (stateValues[stateKey] === undefined) {
            return false
        }
    }
    return true;
}

export type FormProps<
    RespBody,
    K extends string,
    Kf extends string,
    FixedParams extends ParamsConstraint<Kf>,
    Params extends ParamsConstraint<K>
> = MutationOptions<Params & FixedParams, RespBody> & {
    mutation: MutationResult<Params & FixedParams, RespBody>;
    inputs: InputProps<K, Params>[];
    submitLabel: string;
    fixedValues: Partial<FixedParams>;
    loading?: boolean;
    startContent?: JSX.Element;
}
function FormComponent<
    RespBody,
    K extends string,
    Kf extends string,
    FixedParams extends ParamsConstraint<Kf>,
    Params extends ParamsConstraint<K>
>({
    mutation,
    inputs,
    submitLabel,
    fixedValues,
    startContent,
    ...callbacks
}: FormProps<RespBody, K, Kf, FixedParams, Params>) {

    const classes = useStyles();

    const { infoPopup } = useFeedback();
    const [ formState, setFormState ] = useState(() => getDefaultState(inputs));

    return (
    <form
        className={classes.form}
        onSubmit={(e) => {
            e.preventDefault();
            if (checkValidState(formState) && checkValidState(fixedValues)) {
                mutation.mutate({
                    ...formState,
                    ...fixedValues
                }, callbacks);
            } else {
                infoPopup({
                    message: 'Por favor, preencha todos os campos'
                });
            }
        }}
    >
        <Grid container spacing={3}>
            {startContent !== undefined && (
                <GridWrapper
                    grid={{ xs: 12}}
                >
                    {startContent}
                </GridWrapper>
            )}
            {inputs.map((input, index) => {
                if (input.hidden !== undefined && input.hidden(formState)) {
                    return <Fragment key={`${index}`} />
                }
                if (isTextInput(input)) {
                    return (
                    <GridWrapper
                        key={`${index}-${input.name}`}
                        grid={input.grid}
                    >
                        <TextInputWrapper<K, Params>
                            input={input}
                            value={formState[input.name] as string}
                            error={mutation.error?.validationErrors[input.name]}
                            onChange={value => {
                                setFormState(prevState => ({
                                    ...prevState,
                                    [input.name]: value
                                }))
                            }}
                            isLoading={mutation.isLoading}
                        />
                    </GridWrapper>
                    );
                } else if (isNumberInput(input)) {
                    return (
                    <GridWrapper
                        key={`${index}-${input.name}`}
                        grid={input.grid}
                    >
                        <NumberInputWrapper<K, Params>
                            input={input}
                            value={formState[input.name] as number}
                            error={mutation.error?.validationErrors[input.name]}
                            onChange={value => setFormState(prevState => ({
                                ...prevState,
                                [input.name]: value,
                            }))}
                            isLoading={mutation.isLoading}
                        />
                    </GridWrapper>
                    );
                } else if (isBooleanInput(input)) {
                    return (
                        <GridWrapper
                            key={`${index}-${input.name}`}
                            grid={input.grid}
                        >
                            <BooleanInputWrapper<K, Params>
                                input={input}
                                value={formState[input.name] as boolean}
                                error={mutation.error?.validationErrors[input.name]}
                                onChange={value => setFormState(prevState => ({
                                    ...prevState,
                                    [input.name]: value
                                }))}
                                isLoading={mutation.isLoading}
                            />
                        </GridWrapper>
                        );
                } else if (isLocationInput(input)) {
                    return (
                    <GridWrapper
                        key={`${index}-${input.name.latitude}-${input.name.longitude}`}
                        grid={input.grid}
                    >
                        <LocationInputWrapper<K, Params>
                            input={input}
                            value={{
                                longitude: formState[input.name.longitude] as string,
                                latitude: formState[input.name.latitude] as string
                            }}
                            error={{
                                latitude: mutation.error?.validationErrors[input.name.latitude],
                                longitude: mutation.error?.validationErrors[input.name.longitude]
                            }}
                            onChange={({ latitude, longitude }) => setFormState(prevState => ({
                                ...prevState,
                                [input.name.longitude]: longitude,
                                [input.name.latitude]: latitude
                            }))}
                            isLoading={mutation.isLoading}
                        />
                    </GridWrapper>
                    );
                } else if (isOptionsInput(input)) {
                    return (
                    <GridWrapper    
                        key={`${index}-${input.name}`}
                        grid={input.grid}
                    >
                        <OptionsInputWrapper<K, Params>
                            input={input}
                            value={formState[input.name] as number}
                            error={mutation.error?.validationErrors[input.name]}
                            onChange={value => setFormState(prevState => ({
                                ...prevState,
                                [input.name]: value,
                            }))}
                            isLoading={mutation.isLoading}
                        />
                    </GridWrapper>
                    );
                }
                return (<Fragment key={`${index}`} />)
            })}
            <GridWrapper grid={{ xs: 12 }}>
                <Button
                    fullWidth
                    size="large"
                    type="submit"
                    variant="contained"
                    color="secondary"
                    disabled={mutation.isLoading}
                >
                    {submitLabel}
                </Button>
            </GridWrapper>
        </Grid>
    </form>
    );
}




function genericMemo<
    RespBody,
    K extends string,
    Kf extends string,
    FixedParams extends ParamsConstraint<Kf>,
    Params extends ParamsConstraint<K>
>(
    Component: (props: FormProps<RespBody, K, Kf, FixedParams, Params>) => JSX.Element,
    propsAreEqual?: (
        prevProps: FormProps<RespBody, K, Kf, FixedParams, Params>,
        nextProps: FormProps<RespBody, K, Kf, FixedParams, Params>
    ) => boolean
) {
    return (
        memo(Component, propsAreEqual) as unknown
    ) as (props: FormProps<RespBody, K, Kf, FixedParams, Params>) => JSX.Element;
}

const FormMemo = genericMemo(FormComponent, (prevProps, nextProps) => {

    if (prevProps.submitLabel !== nextProps.submitLabel) {
        return false;
    }
    if (prevProps.inputs.length !== nextProps.inputs.length) {
        return false;
    }
    if (prevProps.mutation.isLoading !== nextProps.mutation.isLoading) {
        return false;
    }

    const prevFixedValues = prevProps.fixedValues;
    const nextFixedValues = nextProps.fixedValues;

    for (let field in prevFixedValues) {
        if (prevFixedValues[field] !== nextFixedValues[field]) {
            return false
        }
    }


    const prevError = prevProps.mutation.error;
    const nextError = nextProps.mutation.error;

    for (let i = 0; i<prevProps.inputs.length; i++) {
        const prevInput = prevProps.inputs[i];
        const nextInput = nextProps.inputs[i];

        if (isOptionsInput(prevInput) && isOptionsInput(nextInput)) {
            if (prevInput.options.length !== nextInput.options.length) {
                return false
            }
        }

        if (isLocationInput(prevInput) && isLocationInput(nextInput)) {
            if (prevInput.defaultValue?.latitude !== nextInput.defaultValue?.latitude) {
                return false;
            }
            if (prevInput.defaultValue?.longitude !== nextInput.defaultValue?.longitude) {
                return false;
            }
            if ((
                prevError?.validationErrors[prevInput.name.latitude] !==
                nextError?.validationErrors[nextInput.name.latitude]
            ) || (
                prevError?.validationErrors[prevInput.name.longitude] !==
                nextError?.validationErrors[nextInput.name.longitude]
            )) {
                return false
            }
        } else if (isLocationInput(prevInput) || isLocationInput(nextInput)) {
            return false
        } else {
            if (prevInput.defaultValue !== nextInput.defaultValue) {
                return false
            }
            if (prevError?.validationErrors[prevInput.name] !==
                nextError?.validationErrors[nextInput.name]) {
                return false
            }
        }

    }

    return true;
});


export default function Form<
    RespBody,
    K extends string,
    Kf extends string,
    FixedParams extends ParamsConstraint<Kf>,
    Params extends ParamsConstraint<K>
>({ loading, ...forward }: FormProps<RespBody, K, Kf, FixedParams, Params>) {
    if (loading) {
        const { inputs } = forward;
        return (
        <Grid container spacing={3}>
            {inputs.map((input, index) => (
            <GridWrapper key={index} grid={input.grid}>
                <TextFieldPlaceholder />
            </GridWrapper>
            ))}
            <GridWrapper grid={{ xs: 12 }}>
                <ButtonPlaceholder />
            </GridWrapper>
        </Grid>
        );
    } else {
        return (
        <FormMemo
            {...forward}
        />
        );
    }
}