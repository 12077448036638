import { makeStyles } from '@material-ui/core/styles';
import { Typography } from '@material-ui/core';
import { ListItemCounter } from '../../components/ListItemCounter';
import { formatMoney } from '../../../../utils/format';

const useStyles = makeStyles(theme => ({
	name: {
		fontWeight: 'bold'
	},
	price: {
		fontWeight: 'bold',
		color: theme.palette.success.main
	},
}));

type ProductMixableListItemProps = {
	name: string;
	description?: string;
	changeAmount: (newAmount : number) => void;
	amount: number;
	price: number | null;
	disabledIncrease?: boolean;
	plus?: boolean;
	image_id: string | null;
}

export function ProductMixableListItem({
	name,
	description,
	changeAmount,
	amount,
	price,
	image_id,
	disabledIncrease,
	plus
}: ProductMixableListItemProps) {
	const classes = useStyles();
	return (
	<ListItemCounter
		primary={name}
		image_id={image_id}
		secondary={(
		<>
			{description}
			{price && (
			<Typography noWrap className={classes.price}>
				{`${plus ? "+ " :""}${formatMoney(price)}`}
			</Typography>
			)}
		</>
		)}
		changeAmount={changeAmount}
		disabledText={amount=== 0 && disabledIncrease}
		disabledIncrease={disabledIncrease}
		amount={amount}
	/>
	);
};