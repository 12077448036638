import { Avatar } from "@material-ui/core";
import clsx from "clsx";
import { memo } from "react";
import { placeholderAnimationClass } from "../providers/Theme";

type AvatarPlaceholderProps = {
    className?: string;
}
function Component({ className }: AvatarPlaceholderProps) {
    return (
    <Avatar
        color="inherit"
        className={clsx(
            placeholderAnimationClass,
            className
        )}
    >
        <></>
    </Avatar>
    )
}

export const AvatarPlaceholder = memo(Component, () => true);