import { ReactNode } from 'react';
import { Checkbox, ListItem, ListItemSecondaryAction, ListItemText, ListItemTextProps, makeStyles } from '@material-ui/core';
import { ListItemProps } from 'material-ui';

const useStyles = makeStyles(theme => ({
	listItemCheckbox: {
		paddingTop: theme.spacing(2),
		paddingBottom: theme.spacing(2),
	}
}));


type ListItemCheckboxProps = {
	children : ReactNode;
	listItemTextProps? : ListItemTextProps;
	listItemProps? : ListItemProps;
	toggle : () => void;
	checked : boolean;
	disabled? : boolean;
}

export function ListItemCheckbox({
	children,
	listItemTextProps,
	listItemProps,
	toggle,
	checked,
} : ListItemCheckboxProps) {
	const classes = useStyles();

	return (
	<ListItem
		onClick={() => toggle()}
		button
		className={classes.listItemCheckbox}
		{...listItemProps}
	>
		<ListItemText {...listItemTextProps}>
			{children}
		</ListItemText>
		<ListItemSecondaryAction>
			<Checkbox
				checked={checked}
				onChange={() => toggle()}
				edge="end"
			/>
		</ListItemSecondaryAction>
	</ListItem>
	);
}
