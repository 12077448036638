import { makeStyles } from "@material-ui/core";
import clsx from "clsx";
import { IconButton } from "@material-ui/core";
import { memo } from "react";
import { placeholderAnimationClass } from "../providers/Theme";

const useStyles = makeStyles(theme => ({
    iconButtonPLaceholderIcon: {
        width: theme.spacing(2),
        height: theme.spacing(2),
        display: 'flex'
    }
}));

type IconButtonPlaceholderProps = {
    className?: string;
}
function Component({ className }: IconButtonPlaceholderProps) {
    const classes = useStyles();
    return (
    <IconButton className={clsx(
        className,
        placeholderAnimationClass,
    )} disabled>
        <div className={classes.iconButtonPLaceholderIcon} />
    </IconButton>
    )
}

export const IconButtonPlaceholder = memo(Component, () => true);