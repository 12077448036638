import { createContext, ReactNode, useContext } from "react";
import { useBagProvider } from "./hooks";

const BagContext = createContext({} as ReturnType<typeof useBagProvider>);
export const useBag = () => useContext(BagContext);

type BagProviderProps = {
    children: ReactNode;
}
export function BagProvider({ children }: BagProviderProps) {
    const bagProviderActions = useBagProvider();
    return (
    <BagContext.Provider value={bagProviderActions}>
        {children}
    </BagContext.Provider>
    );
}