import { createContext, useContext, useEffect, useState } from 'react';
import { ProviderProps } from '../types/provider';
import UpdateUserPasswordForm from '../forms/UpdateUserPasswordForm';
import UpdateUserInfoForm from '../forms/UpdateUserInfoForm';
import { AddUserAddressForm, EditUserAddressForm } from '../forms/UserAddressForm';
import { ResponsiveFullscreenDialog } from '../components/ResponsiveFullscreenDialog';
import CRUDList from '../components/CRUDList';
import { useRemoveUserAddress } from '../mutations';
import { useGetUserAddresses } from '../queries';
import { formatAddress } from '../utils/format';
import { useSession } from './Session';
import PersonIcon from '@material-ui/icons/Person';
import LockIcon from '@material-ui/icons/Lock';
import { AddressIcon } from '../components/Icons';

export interface UserContextValue {
    openUserAddresses: () => void
    openUserPersonalInfo: () => void
    openUserUpdatePassword: () => void
}

const UserContext = createContext({} as UserContextValue);
const useUser = () => useContext(UserContext);
export default useUser;

export function UserProvider({ children } : ProviderProps) {

    const [ addressesVisible, setAddressesVisible ] = useState(false);
    const [ personalInfoVisible, setPersonalInfoVisible ] = useState(false);
    const [ updatePasswordVisible, setUpdatePasswordVisible ] = useState(false);
    const { loggedIn } = useSession();

    useEffect(() => {
        if (!loggedIn) {
            if (addressesVisible) {
                setAddressesVisible(false);
            }
            if (personalInfoVisible) {
                setPersonalInfoVisible(false);
            }
            if (updatePasswordVisible) {
                setUpdatePasswordVisible(false);
            }
        }
    }, [
        loggedIn,
        addressesVisible,
        personalInfoVisible,
        updatePasswordVisible
    ]);

	return (
	<UserContext.Provider value={{
        openUserAddresses: () => setAddressesVisible(true),
        openUserPersonalInfo: () => setPersonalInfoVisible(true),
        openUserUpdatePassword: () => setUpdatePasswordVisible(true)
    }}>
        {loggedIn && (<>
        <UpdatePasswordDialog
            handleClose={() => setUpdatePasswordVisible(false)}
            visible={loggedIn && updatePasswordVisible}
        />
        <AddressesDialog
            handleClose={() => setAddressesVisible(false)}
            visible={loggedIn && addressesVisible}
        />
        <PersonalInfoDialog
            handleClose={() => setPersonalInfoVisible(false)}
            visible={loggedIn && personalInfoVisible}
        />
        </>)}
		{children}
	</UserContext.Provider>
	);
}

type DialogProps = {
    handleClose: () => void;
    visible: boolean;
}

function UpdatePasswordDialog({ handleClose, visible }: DialogProps) {
    return (
    <ResponsiveFullscreenDialog
        onClose={handleClose}
        open={visible}
        title={"Alterar senha"}
        icon={<LockIcon />}
        width="sm"
        withPadding
    >
        <UpdateUserPasswordForm />
    </ResponsiveFullscreenDialog>
    );
}

function AddressesDialog({ handleClose, visible }: DialogProps) {
    const { data, isLoading } = useGetUserAddresses();
    const [ editOpen, setEditOpen ] = useState(false);
    const [ addOpen, setAddOpen ] = useState(false);
    const [ addressID, setAddressID ] = useState<number | null>(null);

    const remove = useRemoveUserAddress();

    return (
    <>
        <ResponsiveFullscreenDialog
            onClose={() => setAddOpen(false)}
            open={addOpen}
            title="Adicionar endereço"
            icon={<AddressIcon />}
            width="sm"
            withPadding
        >
            <AddUserAddressForm
                onSuccess={() => setAddOpen(false)}
            />
        </ResponsiveFullscreenDialog>
        <ResponsiveFullscreenDialog
            onClose={() => setEditOpen(false)}
            open={editOpen}
            title="Editar endereço"
            icon={<AddressIcon />}
            width="sm"
            withPadding
        >
            {addressID && <EditUserAddressForm
                address_id={addressID}
                onSuccess={() => setEditOpen(false)}
            />}
        </ResponsiveFullscreenDialog>
        <ResponsiveFullscreenDialog
            onClose={handleClose}
            open={visible}
            title="Seus endereços"
            icon={<AddressIcon />} 
            width="sm"
        >
            <CRUDList
                loading={!data || isLoading}
                controlsLoading={(
                    isLoading || !data ||
                    remove.isLoading
                )}
                openEdit={id => {
                    setAddressID(id);
                    setEditOpen(true);
                }}
                openAdd={() => setAddOpen(true)}
                addLabel={"Adicionar novo endereço"}
                remove={address_id => remove.mutate({ address_id })}
                subject="endereço"
                subjectPrefix="o"
                items={data?.map(address => ({
                    id: address.id,
                    title: formatAddress(
                        address.street,
                        address.number,
                        undefined,
                        undefined,
                        undefined,
                        undefined,
                        undefined
                    ),
                    description: formatAddress(
                        undefined,
                        undefined,
                        address.district,
                        address.city,
                        address.state,
                        address.complement,
                        address.zipcode
                    ),
                    enabled: true
                }))}
            />
        </ResponsiveFullscreenDialog>
    </>
    );
}

function PersonalInfoDialog({ handleClose, visible }: DialogProps) {
    return (
    <ResponsiveFullscreenDialog
        onClose={handleClose}
        open={visible}
        title="Informações pessoais"
        icon={<PersonIcon />}
        width="sm"
        withPadding
    >
        <UpdateUserInfoForm />
    </ResponsiveFullscreenDialog>
    );
}