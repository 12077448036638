import { Button, makeStyles } from "@material-ui/core";
import { VerticalOrderStepperContent, VerticalOrderStepperContentPlaceholder } from "../../../../components/OrderUtils";
import { OrderWrapper } from "../../../../types/domain";
import VisibilityIcon from '@material-ui/icons/Visibility';
import { useOrderRoute } from "../nav/routes";
import { OrderHeader } from "./OrderHeader";
import { CharactersPlaceholder } from "../../../../placeholders/CharactersPlaceholder";
import { ListItemPlaceholder } from "../../../../placeholders/ListItemPlaceholder";
import { Repeat } from "../../../../components/Repeat";

const useStyles = makeStyles(theme => ({
    orderSelectListItemContainer: {
        display: 'flex',
        flexDirection: 'column',
        flexGrow: 1,
        width: '100%',
        alignItems: 'start',
        borderBottom: `1px solid ${theme.palette.divider}`
    },
    orderSelectListItemTitleContainer: {
        padding: `${theme.spacing(1)}px 0px`,
        display: 'flex',
        flexGrow: 1,
        width: '100%',
        flexDirection: 'row',
        justifyContent: 'space-between',
        paddingRight: theme.spacing(2),
        borderBottom: `1px solid ${theme.palette.divider}`
    },
    orderSelectListItemTitle: {
        padding: `${theme.spacing(1)}px ${theme.spacing(3)}px`,
        backgroundColor: theme.palette.secondary.main,
        color: theme.palette.secondary.contrastText,
        fontWeight: theme.typography.fontWeightBold,
        fontSize: theme.typography.h5.fontSize,
        boxShadow: theme.shadows[1],
        borderRadius: `0px ${theme.shape.borderRadius*4}px ${theme.shape.borderRadius*4}px 0px`
    }
}));

type OrderSelectListItemProps = {
    order: OrderWrapper;
}
export function OrderSelectListItem({ order }: OrderSelectListItemProps) {
    const classes = useStyles();

    const orderRoute = useOrderRoute();

    return (
    <div className={classes.orderSelectListItemContainer}>
        <div className={classes.orderSelectListItemTitleContainer}>
            <span className={classes.orderSelectListItemTitle}>
                Pedido #{order.id}
            </span>
            <Button
                color="primary"
                variant="outlined"
                startIcon={<VisibilityIcon />}
                onClick={() => orderRoute.push({ order_id: order.id })}
            >
                Ver mais
            </Button>
        </div>
        <VerticalOrderStepperContent order={order}>
            <OrderHeader order={order} />
        </VerticalOrderStepperContent>
    </div>
    );
}

export function OrderSelectListItemPlaceholder() {
    const classes = useStyles();

    return (
    <div className={classes.orderSelectListItemContainer}>
        <div className={classes.orderSelectListItemTitleContainer}>
            <span className={classes.orderSelectListItemTitle}>
                <CharactersPlaceholder n={8} />
            </span>
        </div>
        <VerticalOrderStepperContentPlaceholder>
            <Repeat n={4}>
                <ListItemPlaceholder avatar secondaryLines={1} />
            </Repeat>
        </VerticalOrderStepperContentPlaceholder>
    </div>
    );
}