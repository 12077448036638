import { Fragment, ReactNode } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { List, Typography, ListItem, ListItemAvatar, ListItemText, Tooltip, ListItemSecondaryAction, IconButton, Divider } from '@material-ui/core';
import FiberManualRecordIcon from '@material-ui/icons/FiberManualRecord';
import ProductAvatar from '../../../../components/ProductAvatar';
import { BagItem } from './types';
import { formatMoney } from '../../../../utils/format';
import { formatBagProductDescription } from './format';
import MixableType, { MixableTypeUtils } from '../../../../constants/mixable_type';
import { useBag } from './BagProvider';
import { useBagEditRoute } from '../nav/routes';
import { BagEmptyState } from './BagEmptyState';
import EditIcon from '@material-ui/icons/Edit';

export function BagItemList() {

	const { bag } = useBag();

	const bagEditRoute = useBagEditRoute();

	return (
	<>
		{bag.items.length === 0 ? <BagEmptyState /> : (
		<List>
			{bag.items.map((item, index) => (
			<Fragment key={`bagitem-${index}`}>
				{item.products.length === 1 ? (
				<BagListItem
					name={item.products[0].name}
					description={formatBagProductDescription(item.products[0])}
					price={item.price}
					image_id={item.products[0].image_id}
					amount={item.products[0].amount}
					onClick={() => bagEditRoute.push({ index })}
				/>
				) : (
				<BagListItem
					name={item.mixable?.name || "Não identificado"}
					description={<MixableItemDescription item={item} />}
					price={item.price}
					amount={1}
					image_id={item.mixable?.image_id || null}
					onClick={() => bagEditRoute.push({ index })}
				/>)}
				<Divider />
			</Fragment>
			))}
		</List>
		)}
	</>
	);
}

const useStyles = makeStyles(theme => ({
	price: {
		color: theme.palette.success.main,
		fontWeight: theme.typography.fontWeightMedium
	},
	mixableDescriptionIcon: {
		fontSize: theme.typography.fontSize/2
	},
	bagListItem: {
		paddingTop: theme.spacing(3),
		paddingBottom: theme.spacing(3),
	}
}));


type BagListItemComponentProps = {
	onClick : () => void;
	name : string;
	description? : ReactNode;
	amount : number;
	price : number;
	image_id : string | null;
}

function BagListItem({
    onClick,
    name,
    description,
    amount,
    price,
    image_id
} : BagListItemComponentProps) {
	const classes = useStyles();
	return (
	<ListItem
		alignItems="flex-start"
		className={classes.bagListItem}
	>
		<ListItemAvatar>
			<ProductAvatar
				image_id={image_id}
				name={name}
				withAmount
				amount={amount}
			/>
		</ListItemAvatar>
		<ListItemText
			primary={name}
			secondaryTypographyProps={{ component: "div" }}
			secondary={(
			<>
				{description}
				{price !== null && (
					<Typography className={classes.price}>
						{formatMoney(price)}
					</Typography>
				)}
			</>
			)}
		/>
		<ListItemSecondaryAction>
			<Tooltip title="Editar item">
				<IconButton onClick={onClick}>
					<EditIcon />
				</IconButton>
			</Tooltip>
		</ListItemSecondaryAction>
	</ListItem>
	);
}


type MixableItemDescriptionProps = {
	item : BagItem
}

function MixableItemDescription({ item }: MixableItemDescriptionProps) {
	const classes = useStyles();
	return (
	<>
		{item.products.map((product, index) => {
			const description = formatBagProductDescription(product);
			return (
			<div key={index}>
				<span>
					<FiberManualRecordIcon 
						className={classes.mixableDescriptionIcon}
					/>
					{MixableTypeUtils.switch(item.mixable.type_id, {
						[MixableType.FAIR_PRICE]: ` 1/${item.products.length} - `,
						[MixableType.FIXED_PRICE]: ` 1/${item.products.length} - `,
						[MixableType.UNFAIR_PRICE]: ` 1/${item.products.length} - `,
						[MixableType.COMBO]: ` 1 - `,
					})}
					{product.name}
					{description.length === 0 ? "" : " -"}
					{" "}
				</span>
				{description}
			</div>
			)
		})}
	</>
	);
}


