import { UseQueryOptions } from "react-query"
import DeliveryMethod from "../../../constants/delivery_method"
import PaymentMethod from "../../../constants/payment_method"
import useRouter from "../../../providers/Router"
import { newQuery, useQuery } from "../../../hooks/react-query"
import { Company, WithID, CompanyAddress, CompanyEmail, CompanyPhone, CompanyRoute, CompanyMainOptions } from "../../../types/domain"
import HTTPError from "../../../types/httperror"
import useTheme from "../../../providers/Theme"
import { useCompany } from "../../../providers/Company"

export type ResponseCompanyFullInfo = 
    Company &
    CompanyMainOptions & {
    logo_image_id: string | null;
    background_image_id: string | null;

    uuid: string;
    name: string;
    cnpj: string;

    addresses: WithID<CompanyAddress>[];
    phones: WithID<CompanyPhone>[];
    emails: WithID<CompanyEmail>[];
    payment_methods: PaymentMethod[];
    delivery_methods: DeliveryMethod[];
}
export const QUERY_COMPANY_FULL_INFO = newQuery(
    '/stores/:route',
    { open: true }
)<
    CompanyRoute,
    ResponseCompanyFullInfo
>();
export const useGetCompanyFullInfo = (
    options?: UseQueryOptions<
        unknown,
        HTTPError<CompanyRoute>,
        ResponseCompanyFullInfo
    >
) => {
    const { subject } = useRouter();
    const { updateColors } = useTheme();
    const { setCompanyID } = useCompany();
    return useQuery(
        QUERY_COMPANY_FULL_INFO,
        { route: subject },
        {
            ...options,
            onSuccess: data => {
                setCompanyID(data.uuid);
                updateColors(
                    data.primary_color,
                    data.secondary_color
                );
            },
        }
    );
}