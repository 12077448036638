import { makeStyles } from "@material-ui/core";
import clsx from "clsx";
import { memo } from "react";
import { Repeat } from "../components/Repeat";
import { placeholderAnimationClass } from "../providers/Theme";

const useStyles = makeStyles(theme => ({
    container: {
		borderRadius: theme.shape.borderRadius,
		color: "transparent",
		display: "flex",
        flexGrow: 1
    }
}));

type LinesProps = {
    lines: number;
}
function Component({ lines }: LinesProps) {
    const classes = useStyles();
    if (lines === 0) return (<></>)
    return (
    <span className={clsx(placeholderAnimationClass, classes.container)}>
        <Repeat n={lines}><br /></Repeat>
    </span>
    );
}

export const ParagraphPlaceholder = memo(Component, () => true);