import { useRoute, useRouteString } from '../../../../hooks/route';

export const MENU_PATH = '/';
export const useMenuRoute = () => useRoute(MENU_PATH);

export const SEARCH_PATH = '/search/:query';
export const useSearchRoute = () => useRouteString(SEARCH_PATH);

export const ADD_PATH = '/add/:payload';
export const useAddRoute = () => useRouteString(ADD_PATH);

export const MIXABLE_PATH = '/mixable/:mixable_id';
export const useMixableRoute = () => useRoute(MIXABLE_PATH);

export const BAG_PATH = '/bag';
export const useBagRoute = () => useRoute(BAG_PATH);

export const CHECKOUT_PATH = '/checkout';
export const useCheckoutRoute = () => useRoute(CHECKOUT_PATH);

export const BAG_EDIT_PATH = '/edit/:index';
export const useBagEditRoute = () => useRoute(BAG_EDIT_PATH);

export const PROFILE_PATH = '/register';
export const useProfileRoute = () => useRoute(PROFILE_PATH);

export const PASSWORD_RECOVER = '/password_recover';
export const usePasswordRecoverRoute = () => useRoute(PASSWORD_RECOVER);

export const PREVIOUS_ORDERS_PATH = '/previous_orders/:page';
export const usePreviousOrdersRoute = () => useRoute(PREVIOUS_ORDERS_PATH);

export const SELECT_ORDER_PATH = '/order';
export const useSelectOrderRoute = () => useRoute(SELECT_ORDER_PATH);

export const ORDER_PATH = '/order/:order_id';
export const useOrderRoute = () => useRoute(ORDER_PATH);

export const COMPANY_INFO_PATH = '/company';
export const useCompanyInfoRoute = () => useRoute(COMPANY_INFO_PATH);