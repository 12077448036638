import { ListItem, ListItemSecondaryAction, ListItemText, Button, Collapse, Grow, makeStyles, ListItemAvatar } from "@material-ui/core";
import { ReactNode } from "react";
import AddIcon from '@material-ui/icons/Add';
import RemoveIcon from '@material-ui/icons/Remove';
import clsx from 'clsx';
import ProductAvatar from "../../../components/ProductAvatar";

const useStyles = makeStyles(theme => ({
	listItem: {
		paddingTop: theme.spacing(3),
		paddingBottom: theme.spacing(3),
	},
    listItemSecondaryAction: {
        paddingRight: theme.spacing(10),
    },
    secondaryAction: {
        display: 'absolute',
		right: theme.spacing(1)
    },
    secondaryActionInnerContainer: {
        display: 'flex',
        flexDirection: 'row',
    },
    amount: {
		display: 'flex',
		alignItems: 'center',
        justifyContent: 'center',
        borderBottom: `1px solid ${theme.palette.divider}`,
        borderTop: `1px solid ${theme.palette.divider}`,
        borderLeft: `1px solid ${theme.palette.divider}`,
		borderRadius: `${theme.shape.borderRadius}px 0px 0px ${theme.shape.borderRadius}px`,
		padding: `0px ${theme.spacing(0.5)}px`,
        fontWeight: theme.typography.fontWeightBold,
        fontSize: theme.typography.fontSize*1.3
    },
    increaseButton: {
        padding: `${theme.spacing(0.5)}px ${theme.spacing(1)}px`,
        minWidth: theme.spacing(2),
        height: theme.spacing(5),
        transition: `border-radius ${theme.transitions.easing.easeIn} ${theme.transitions.duration.short}ms`,
    },
    increaseButtonCollapsed: {
        borderRadius: '50% 50%',
    },
    increaseButtonExpanded: {
        borderRadius: `0px ${theme.shape.borderRadius}px 0px 0px`,
        borderBottom: 'none',
        borderColor: theme.palette.divider
    },
    decreaseButton: {
        padding: `${theme.spacing(0.5)}px ${theme.spacing(1)}px`,
        height: theme.spacing(5),
        minWidth: theme.spacing(2),
		borderRadius: `0px 0px ${theme.shape.borderRadius}px 0px`,
        borderColor: theme.palette.divider
    },
    buttonContainer: {
        display: 'flex',
        flexDirection: 'column',
    },
    buttonContainerExpanded: {
    }
}));


type Props = {
    primary: string;
    secondary: ReactNode;
    amount: number;
    changeAmount: (newAmount : number) => void;
    disabledIncrease?: boolean;
    disabledText?: boolean;
    loading?: boolean;
    image_id?: string | null;
}

export function ListItemCounter({
    primary,
    secondary,
    amount,
    changeAmount,
    disabledIncrease,
    disabledText,
    loading,
    image_id
} : Props) {
    const classes = useStyles();
    const expanded = amount > 0;
    return (
    <ListItem
        button
        className={classes.listItem}
        classes={{ secondaryAction: classes.listItemSecondaryAction }}
        disabled={disabledText || loading}
        onClick={() => !loading && !disabledIncrease && changeAmount(amount+1)}
    >
        {image_id !== undefined && (
        <ListItemAvatar>
			<ProductAvatar
				image_id={image_id}
				name={primary}
			/>
		</ListItemAvatar>
        )}
        <ListItemText
            primary={primary}
            secondary={secondary}
            secondaryTypographyProps={{ component: "div" }}
        />
        <ListItemSecondaryAction>
            <div className={classes.secondaryActionInnerContainer}>
                <Grow in={amount !== 0}>
                    <div className={classes.amount}>
                        {expanded ? amount : 1}
                    </div>
                </Grow>
                <div className={clsx(
                    classes.buttonContainer,
                    expanded && classes.buttonContainerExpanded
                )}>
                    <Button
                        disabled={disabledIncrease || loading}
                        variant={expanded ? "outlined" : "text"}
                        className={clsx(
                            classes.increaseButton,
                            expanded ?
                                classes.increaseButtonExpanded :
                                classes.increaseButtonCollapsed
                        )}
                        onClick={() => !loading && changeAmount(amount+1)}
                    >
                        <AddIcon />
                    </Button>
                    <Collapse in={expanded}>
                        <Button
                            variant="outlined"
                            disabled={loading}
                            className={classes.decreaseButton}
                            onClick={() => !loading && changeAmount(amount-1)}
                        >
                            <RemoveIcon />
                        </Button>
                    </Collapse>
                </div>
            </div>
        </ListItemSecondaryAction>
    </ListItem>
    );
}