import { useCallback, useEffect, useState } from "react";
import MixableType from "../../../../constants/mixable_type";
import useFeedback from "../../../../providers/Feedback";
import { MenuMixable, MenuProduct, MenuSection, useGetMenu } from "../../queries";
import { useMixableRoute, useMenuRoute } from "../nav/routes";
import { useOpenMixableProductMenu } from "../product_menu/hooks";
import { ProductMenuInput, ProductMenuInputProduct } from "../product_menu/types";

type MixableProduct = MenuProduct & {
	amount: number;
	displayPrice: number | null;
};

type MixableSection = Omit<MenuSection, 'products'> & {
	products : MixableProduct[];
};

function reduceSections(
    sectionList: MixableSection[],
    callback: (acc: number, product: MixableProduct) => number
) {
    return sectionList.reduce((acc, section) => {
        const subtotal = section.products
        .filter(product => product.amount > 0)
        .reduce((acc, product) => ({
            amount: acc.amount + product.amount,
            value: callback(acc.value, product)
        }), { value: 0, amount: 0});
        return {
            value: acc.value + subtotal.value,
            amount: acc.amount + subtotal.amount
        }
    }, { value: 0, amount: 0 });
};

function computeDisplayPrice(mixable: MenuMixable, product: MenuProduct) {
    switch (mixable.type_id) {
    case MixableType.FAIR_PRICE:
        return product.price/mixable.amount;
    case MixableType.COMBO:
    case MixableType.FIXED_PRICE:
        return null
    case MixableType.UNFAIR_PRICE:
    default:
        return product.price
    }
}

export const useOpenMixableDialog = () => {

    const mixableRoute = useMixableRoute();

    const openMixableDialog = useCallback((mixable_id: number) => {
        mixableRoute.push({ mixable_id });
    }, [ mixableRoute ]);

    return {
        openMixableDialog
    };
}

export const useMixableDialogState = () => {

	const { data: menu, isLoading: menuLoading } = useGetMenu();
    const { infoPopup } = useFeedback();

    const menuRoute = useMenuRoute();
    const menuMixableRoute = useMixableRoute();

    const { openMixableProductMenu } = useOpenMixableProductMenu();

    const [ mixable, setMixable ] = useState<MenuMixable | null>(null);
    const [ mixableSections, setMixableSections ] = useState<MixableSection[] | null>([]);

    const [ totalPrice, setTotalPrice ] = useState(0);
	const [ totalAmount, setTotalAmount ] = useState(0);

    useEffect(() => {
        if (
            menuMixableRoute.params.mixable_id &&
            !menuLoading && menu
        ) {
            const sectionsSearchResults = menu.sections.filter(section => (
                section.mixables.find(mixable => (
                    mixable.id === menuMixableRoute.params.mixable_id
                ))
            ));
            
            const foundMixable = sectionsSearchResults.length > 0 ? (
                sectionsSearchResults[0].mixables.find(mixable => (
                    mixable.id === menuMixableRoute.params.mixable_id
                )) || null
            ) : null;

            if (foundMixable !== null) {
                setMixableSections(sectionsSearchResults.map(section => ({
                    ...section,
                    products: section.products.map(product => ({
                        ...product,
                        amount: 0,
                        displayPrice: computeDisplayPrice(foundMixable, product)
                    }))
                })));
                setMixable(foundMixable);
            }
        }
    }, [
        menuMixableRoute.params.mixable_id,
        menuLoading,
        menu
    ]);

    useEffect(() => {
        if (mixable === null || mixableSections === null) {
			setTotalPrice(0);
			setTotalAmount(0);
		} else {
			var reduced: ReturnType<typeof reduceSections>;
			switch(mixable.type_id) {
			case MixableType.COMBO:
			case MixableType.FIXED_PRICE:
				reduced = reduceSections(mixableSections, () => 0);
				setTotalPrice(mixable.price);
				setTotalAmount(reduced.amount);
				break;
			case MixableType.UNFAIR_PRICE:
				reduced = reduceSections(
                    mixableSections,
                    (acc, product) => product.price > acc ? product.price : acc
                );
				setTotalPrice(reduced.value);
				setTotalAmount(reduced.amount);
				break;
			case MixableType.FAIR_PRICE:
			default:
				reduced = reduceSections(
                    mixableSections,
                    (acc, product) => acc + (product.amount*product.price/mixable.amount)
                );
				setTotalPrice(reduced.value);
				setTotalAmount(reduced.amount);
			}
		}
    }, [ mixable, mixableSections ]);

    const visible = Boolean(
        menuMixableRoute.matched &&
        menuMixableRoute.exact &&
        !menuLoading &&
        menu &&
        mixableSections !== null &&
        mixable !== null &&
        menuMixableRoute.params.mixable_id !== undefined
    );

    const title = mixable !== null ? (
        mixable.type_id === MixableType.COMBO ?
        `Escolha ${mixable.amount} produtos` :
        `Escolha ${mixable.amount} sabores`
    ) : "Escolha os sabores";

    const close = useCallback(() => menuRoute.push({}), [ menuRoute ]); 

    const clear = useCallback(() => {
        setMixable(null);
        setMixableSections(null);
        setTotalAmount(0);
        setTotalPrice(0);
    }, []);

    const changeAmount = useCallback((
        sectionIndex: number,
        productIndex: number,
        newAmount: number
    ) => {
        if (
            newAmount < 0 ||
            mixableSections === null ||
            sectionIndex >= mixableSections.length ||
            productIndex >= mixableSections[sectionIndex].products.length
        ) return;
        setMixableSections(prev => {
            if (
                newAmount < 0 ||
                prev === null ||
                sectionIndex >= prev.length ||
                productIndex >= prev[sectionIndex].products.length
            ) return prev;
            prev[sectionIndex].products[productIndex].amount = newAmount;
            return [ ...prev ];
        });
    }, [ mixableSections ]);

    const submit = useCallback(() => {
        if (mixable !== null && mixableSections !== null) {
            if (totalAmount === 0) {
                return infoPopup({
                    message: mixable.type_id === MixableType.COMBO ?
                        `Selecione ${mixable.amount} produtos` :
                        `Seleciona ${mixable.amount} sabores`
                });
            }
            if (mixable.amount - totalAmount === 1) {
                return infoPopup({
                    message: mixable.type_id === MixableType.COMBO ? 
                        "Selecione mais um produto" :
                        "Selecione mais um sabor"
                });
            }
            if (mixable.amount !== totalAmount) {
                return infoPopup({
                    message: mixable.type_id === MixableType.COMBO ?
                        `Selecione mais ${mixable.amount-totalAmount} produtos` :
                        `Selecione mais ${mixable.amount-totalAmount} sabores`
                });
            }



            const input: ProductMenuInput = {
                mixable_id: mixable.id,
                products: mixableSections.reduce((acc, s) => (
                    acc.concat(s.products.filter(p => p.amount > 0).map(p => ({
                        section_id: s.id,
                        product_id: p.id,
                        amount: p.amount
                    })))
                ), [] as ProductMenuInputProduct[])
            }

            openMixableProductMenu(input);
        }
    }, [
        mixable,
        mixableSections,
        totalAmount,
        infoPopup,
        openMixableProductMenu
    ]);

    return {
        title,
        visible,
        close,
        clear,
        submit,
        mixable,
        mixableSections,
        changeAmount,
        totalAmount,
        totalPrice
    };
}