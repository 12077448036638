import { AppBar, Container, Grow, makeStyles, Tab, Tabs } from "@material-ui/core";
import { Breakpoint } from "@material-ui/core/styles/createBreakpoints";
import clsx from "clsx";

const useStyles = makeStyles(theme => ({
    scrollTabsAppBar: {
        zIndex: theme.zIndex.appBar-1
    }
}));

type ScrollTabsItem = {
    label: string | JSX.Element;
    disabled?: boolean;
}

type ScrollTabsProps = {
    value: number | false;
    maxWidth?: Breakpoint;
    onChange?: (newValue: number) => void;
    items: ScrollTabsItem[];
    alwaysVisible?: true;
    appBarClassName?: string;
}

export function ScrollTabs({
    maxWidth,
    value,
    onChange,
    items,
    alwaysVisible,
    appBarClassName
}: ScrollTabsProps) {
    const classes = useStyles();
    const tabs = (
        <AppBar
            position="sticky"
            color="inherit"
            className={clsx(classes.scrollTabsAppBar, appBarClassName)}
        >
            <Container
                maxWidth={maxWidth}
                component="nav"
                disableGutters
            >
                <Tabs
                    value={value}
                    onChange={onChange && ((e, newIndex) => onChange(newIndex))}
                    variant="scrollable"
                    scrollButtons="on"
                >
                    {items.map((item, index) => (
                        <Tab
                            key={index}
                            value={index}
                            label={item.label}
                            disabled={item.disabled}
                        />
                    ))}
                </Tabs>
            </Container>
        </AppBar>
    );
    if (alwaysVisible) return tabs;

    return (
    <Grow in={items.length > 1} unmountOnExit>
        {tabs}    
    </Grow>
    );
}