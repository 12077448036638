import { ResponsiveFullscreenDialog } from "../../../../components/ResponsiveFullscreenDialog";
import { useProductMenuDialog } from "./hooks";
import { createRef } from "react";
import { ProductQuestion } from "./ProductQuestion";
import { ProductIngredients } from "./ProductIngredients";
import { ProductExtras } from "./ProductExtras";
import { ProductNotes } from "./ProductNotes";
import { ProductDetails } from "./ProductDetails";
import { ProductsAccordion } from "./ProductsAccordion";
import { makeStyles } from "@material-ui/core";
import { ValueButton, ValueButtonPlaceholder } from "../../components/ValueButton";
import { ProductAmount } from "./ProductAmount";
import { ProductMenuPlaceholder } from "./ProductMenuPlaceholder";
import { ItemRemoveButton } from "./ItemRemoveButton";

import EditIcon from '@material-ui/icons/Edit';
import AddCircleOutlineIcon from '@material-ui/icons/AddCircleOutline';
import AddIcon from '@material-ui/icons/Add';

const useStyles = makeStyles(theme => ({
	productMenuDialogContent: {
		display: 'flex',
		height: '100%',
		overflowY: 'auto',
		overflowX: 'hidden',
		flexDirection: 'column',
	},
	productMenuDialogValueContainer: {
        backgroundColor: theme.palette.background.paper,
		borderTop: `1px solid ${theme.palette.divider}`,
        boxShadow: theme.shadows[3],
        zIndex: theme.zIndex.modal+1,
        display: 'flex',
        flexDirection: 'row',
        padding: theme.spacing(1)
    },
	productMenuDialogExcludeContainer: {
		marginRight: theme.spacing(1)
	},
}));

export function ProductMenuDialog() {

	const classes = useStyles();

	const dialogRef = createRef<HTMLDivElement>();

    const {
        open,
		close,
		clearProductMenu,
		submit,
		removeItem,
		isEditing,
		item,
		toggleNote,
		selectAnswer,
		changeExtraAmount,
		toggleIngredient,
		setFreeNote,
		increaseAmount,
		decreaseAmount
    } = useProductMenuDialog();



    return (
    <ResponsiveFullscreenDialog
        open={open}
        title={isEditing ? "Editar item" : "Adicionar item"}
        onClose={close}
        icon={isEditing ? <EditIcon /> : <AddCircleOutlineIcon />}
		width="sm"
		onExited={clearProductMenu}
		bottomContent={!item ? <ValueButtonPlaceholder />: (
			<>
				{isEditing && (
				<div className={classes.productMenuDialogExcludeContainer}>
					<ItemRemoveButton
						onClick={removeItem}
					/>
				</div>
				)}
				<ValueButton
					onClick={submit}
					value={item?.price || 0}
					title={"Total do item"}
					actionLabel={isEditing ? "Editar" : "Adicionar"}
					icon={isEditing ? <EditIcon fontSize="large" />: <AddIcon  fontSize="large" />}
					tooltip={isEditing ? "Atualizar item na sacola" : "Adicionar à sacola"}
				/>
			</>
		)}
    >
		{!item ? <ProductMenuPlaceholder /> : (
			<ProductsAccordion
				scrollContainer={dialogRef}
				hint={<span>Adicione detalhes ao produto</span>}
				items={item.products.map((product, product_index) => ({
					header: (
					<ProductDetails
						image_id={product.image_id}
						title={product.name}
					/>
					),
					content: (
					<>
						{item?.products.length === 1 && (
							<ProductAmount
								decrease={() => decreaseAmount()}
								increase={() => increaseAmount()}
								amount={product.amount}
								remove={isEditing && removeItem}
							/>
						)}
						{product?.questions.map((question, index) => (
						<ProductQuestion
							key={index}
							question={question}
							select={selectAnswer}
							productIndex={product_index}
						/>
						))}
						<ProductIngredients
							productIndex={product_index}
							ingredients={product.ingredients}
							toggle={toggleIngredient}
						/>
						<ProductExtras
							productIndex={product_index}
							extras={product.extras}
							changeAmount={changeExtraAmount}
							amount={product.amount}
						/>
						<ProductNotes
							productIndex={product_index}
							notes={product.notes}
							freeNote={product.freeNote}
							setFreeNote={setFreeNote}
							toggle={toggleNote}
						/>
					</>
					)
				}))}
			/>
		)}
    </ResponsiveFullscreenDialog>
    );
}