import { useMutation } from "../hooks/react-query";
import { useSession } from "../providers/Session";
import { ResponseMessage } from "../types/domain";

export type Token = {
    token: string;
}

export type BodyLogin = {
    email: string;
    password: string;
};
export const useLogin = () => {
    const { login } = useSession();   
    
    
    return useMutation<BodyLogin, ResponseMessage & Token>(
        'POST',
        ({ email, password }) => ({
            url: `/session/login`,
            body: { email, password }
        }), {
            onSuccess: ({ token }) => {
                login(token);
            }
        }
    )
};

export type BodyGoogleLogin = {
    token: string;
};
export const useGoogleLogin = () => {
    const { login } = useSession();   
    return useMutation<BodyGoogleLogin, ResponseMessage & Token>(
        'POST',
        ({ token }) => ({
            url: `/session/google_login`,
            body: { token }
        }), {
            onSuccess: ({ token }) => {
                login(token);
            }
        }
    )
};

export type BodyRegister = {
    email: string;
    password: string;
    password_confirm: string;
    name: string;
    phone: string;
};
export const useRegister = () => {
    const { login } = useSession();   

    return useMutation<BodyRegister, ResponseMessage & Token>(
        'POST',
        body => ({
            url: `/session/register`,
            body
        }), {
            onSuccess: ({ token }) => {
                login(token);
            }
        }
    )
};


export type BodySendPasswordRecover = {
    email: string;
};
export const useSendPasswordRecover = () => useMutation<
    BodySendPasswordRecover,
    ResponseMessage
>(
    'POST',
    body => ({
        url: `/session/send_password_recover`,
        body
    })
);

export type BodyPasswordRecover = {
    email: string;
    password: string;
    password_confirm: string;
    recover_token: string;
};
export const usePasswordRecover = () => useMutation<
    BodyPasswordRecover,
    ResponseMessage
>(
    'POST',
    body => ({
        url: `/session/password_recover`,
        body
    })
);