import Form, { InputProps } from '../components/Form';
import { BodyUpdateUserInfo, useUpdateUserInfo } from '../mutations';
import { useGetUserInfo } from '../queries';

const inputs = (defaultValues?: BodyUpdateUserInfo): InputProps<
    keyof BodyUpdateUserInfo,
    BodyUpdateUserInfo
>[] => [{
    name: 'name',
    type: 'text',
    label: 'Nome',
    defaultValue: defaultValues?.name,
    maxChar: 50
}, {
    name: 'phone',
    type: 'phone',
    label: 'Telefone/Celular',
    defaultValue: defaultValues?.phone
}];

export default function UpdateUserInfoForm() {
    const { data, isLoading } = useGetUserInfo();
    const updateUserInfo = useUpdateUserInfo();
    return (
    <Form
        submitLabel='Atualizar informações'
        mutation={updateUserInfo}
        fixedValues={{}}
        loading={!data || isLoading}
        inputs={inputs(data)}
    />
    );
}