import { createContext, useContext, useEffect, useState } from 'react';
import useFeedback from './Feedback';
import { ProviderProps } from '../types/provider';

export interface OfflineContextType {
    isOffline: boolean;
}

const Context = createContext({} as OfflineContextType);
const useOffline = () => useContext(Context);
export default useOffline;

export function OfflineProvider({ children } : ProviderProps) {

    const { infoPopup } = useFeedback();

    const [ isOffline, setIsOffline ] = useState<boolean>(false);

    function handleOnline() {
        setIsOffline(false);
        infoPopup({
            message: "Você está online!"
        });
    }
    function handleOffline() {
        setIsOffline(true);
        infoPopup({
            message: "Você está offline"
        });
    }

    useEffect(() => {
        window.addEventListener('online', handleOnline);
        window.addEventListener('offline', handleOffline);
        return () => {
            window.removeEventListener('online', handleOnline);
            window.removeEventListener('offline', handleOffline);
        }
    // eslint-disable-next-line
    }, []);

	return (
	<Context.Provider value={{ isOffline }}>
		{children}
	</Context.Provider>
	);
}
