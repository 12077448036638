import { BagProduct } from "./types";

export const formatBagProductDescription = (product : BagProduct) => (
    `${product.questions.reduce((acc, q) => (
        `${q.answers.find(a => a.id === q.selected)?.description}; `+acc
    ), "")}${product.extras.filter(e => e.amount > 0).reduce((acc, e) => (
        `C/ ${e.amount > 1 ? `${e.amount}x ` : ""}${e.name}; `+acc
    ), "")}${product.ingredients.filter(s => !s.selected).reduce((acc, s) => (
        `S/ ${s.name}; `+acc
    ), "")}${product.notes.filter(n => n.selected).reduce((acc, n) => (
        `${n.description}; `+acc
    ), "")}${product.freeNote.length === 0 ? "" : `${product.freeNote};`}`
)