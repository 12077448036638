import { createContext, useCallback, useContext, useEffect, useState } from "react";
import { QUERY_USER_ADDRESS, QUERY_USER_ADDRESSES, QUERY_USER_INFO } from "../queries";
import { ProviderProps } from "../types/provider";
import { invalidateQueries } from "./ReactQuery";

const SESSION_KEY = "app-session";

const useSessionProviderState = () => {
    
    const [ token, setToken ] = useState<null | string>(
        () => localStorage.getItem(SESSION_KEY)
    );
    const [ loggedIn, setLoggedIn ] = useState(token !== null);



    const logout = useCallback(() => {
        setToken(null);
        invalidateQueries([
            QUERY_USER_INFO.invalidate(),
            QUERY_USER_ADDRESSES.invalidate(),
            QUERY_USER_ADDRESS.invalidate(),
        ]);
    }, [ ]);

    const login = (newToken: string) => {
        setToken(newToken);
    }


    useEffect(() => {
        if (token !== null) {
            localStorage.setItem(SESSION_KEY, token)
            setLoggedIn(true);    
        } else {
            localStorage.removeItem(SESSION_KEY)
            setLoggedIn(false);
        }
        
    }, [ token ])

    return {
        token,
        login,
        logout,
        loggedIn,
    }
}

const SessionContext = createContext({} as ReturnType<typeof useSessionProviderState>);
export const useSession = () => useContext(SessionContext);

export function SessionProvider({ children }: ProviderProps) {
    const sessionProviderValue = useSessionProviderState();
    return (
    <SessionContext.Provider value={sessionProviderValue}>
        {children}
    </SessionContext.Provider>
    );
} 