import { ResponsiveFullscreenDialog } from "../../../../components/ResponsiveFullscreenDialog";
import ReceiptIcon from '@material-ui/icons/Receipt';
import { useMenuRoute, useOrderRoute, usePreviousOrdersRoute, useSelectOrderRoute } from "../nav/routes";
import { useGetCompanyFullInfo, useGetUserActiveOrders, useGetUserOrder } from "../../queries";
import { usePersistentParam } from "../../../../hooks/dialog";
import { OrderHeader } from "./OrderHeader";
import { OrderItemList, OrderStepper, OrderStepperPlaceholder } from "../../../../components/OrderUtils";
import { Map } from "../../../../components/Map";
import { Divider, List, makeStyles, Typography, Button, Box } from "@material-ui/core";
import clsx from "clsx";
import { useMemo } from "react";
import { placeholderAnimationClass } from "../../../../providers/Theme";
import { CharactersPlaceholder } from "../../../../placeholders/CharactersPlaceholder";
import { Repeat } from "../../../../components/Repeat";
import { ListItemPlaceholder } from "../../../../placeholders/ListItemPlaceholder";
import { WhatsAppIcon } from "../../../../components/Icons";
import { sendWhatsAppMessage } from "../../../../hooks/whatsapp";
import { generateCostumerToCompanyWhatsAppMessage } from "./hooks";

const useStyles = makeStyles(theme => ({
    orderDialogMapHeader: {
        display: 'flex',
    },
    orderDialogMapHeaderVertical: {
        flexDirection: 'row',
    },
    orderDialogMapHeaderHorizontal: {
        flexDirection: 'column',
    },
    orderDialogMap: {
        flexGrow: 1,
        zIndex: theme.zIndex.modal+1
    },
    orderDialogMapContainer: {
        flexGrow: 1,
        padding: `${theme.spacing(1)}px 0px ${theme.spacing(1)}px ${theme.spacing(1)}px`,
        display: 'flex'
    },
    orderDialogSubtitle: {
        paddingLeft: theme.spacing(1)
    },
    orderDialogHeaderContainer: {
        paddingTop: theme.spacing(1),
        paddingBottom: theme.spacing(2)
    },
    orderDialogPlaceholderMap: {
        flexGrow: 1,
        marginBottom: theme.spacing(2),
        borderRadius: theme.shape.borderRadius,
    }
}));


export function OrderDialog() {

    const classes = useStyles();

    const orderRoute = useOrderRoute();
    const menuRoute = useMenuRoute();
    const previousOrdersRoute = usePreviousOrdersRoute();
    const selectOrderRoute = useSelectOrderRoute();


    const {
        data: company,
        isLoading: companyLoading
    } = useGetCompanyFullInfo();

    const whatsAppPhone = useMemo(() => {
        if (companyLoading || !company || company.whatsapp_phone_id === null)
            return null;
        return company.phones.find(p => p.id === company.whatsapp_phone_id) || null;
    }, [ company, companyLoading ]);

    const {
        value: orderID,
        clear: clearOrderID
    } = usePersistentParam(orderRoute.params.order_id);


    const {
        data: order,
        isLoading: orderLoading
    } = useGetUserOrder(orderID);

    const {
        data: activeOrders,
        isLoading: activeOrdersLoading
    } = useGetUserActiveOrders();

    const onClose = () => {
        if (order === undefined) {
            return menuRoute.push({});
        }
        if (
            order.finished_at !== null ||
            order.rejected_at !== null ||
            order.expired_at !== null
        ) {
            return previousOrdersRoute.push({ page: 1 });
        }
        if (activeOrders === undefined || activeOrdersLoading || activeOrders.length <= 1) {
            return menuRoute.push({});
        }
        return selectOrderRoute.push({});
    };

    return (
    <ResponsiveFullscreenDialog
        open={orderRoute.matched}
        onClose={onClose}
        onExited={() => clearOrderID()}
        title={`Pedido${orderID === undefined ? "" : ` #${orderID}`}`}
        icon={<ReceiptIcon />}
        width="sm"
    >
        {!order || orderLoading ? <OrderDialogPlaceholder /> : (
        <>
            {order.order.address?.latitude && order.order.address?.longitude ? (
                <div className={clsx(
                    classes.orderDialogMapHeader,
                    classes.orderDialogMapHeaderVertical,
                )}>
                    <div className={classes.orderDialogMapContainer}>
                        <Map
                            latitude={order.order.address.latitude}
                            longitude={order.order.address.longitude}
                            className={classes.orderDialogMap}
                        />
                    </div>
                    <OrderStepper order={order} orientation="vertical" />
                </div>
            ) : (
                <OrderStepper order={order} orientation="horizontal" />
            )}
            {whatsAppPhone && (
            <Box p={1}>
                <Button
                    size="large"
                    startIcon={<WhatsAppIcon />}
                    fullWidth
                    color="secondary"
                    variant="contained"
                    onClick={() => sendWhatsAppMessage(
                        whatsAppPhone.phone,
                        generateCostumerToCompanyWhatsAppMessage(order)
                    )}
                >
                    Conversar sobre o pedido
                </Button>
            </Box>
            )}
            <Typography
                variant="h4"
                color="textSecondary"
                className={classes.orderDialogSubtitle}
                gutterBottom
            >
                Pedido
            </Typography>
            <Divider />
            <div className={classes.orderDialogHeaderContainer}>
                <OrderHeader order={order} />
            </div>
            <Typography
                variant="h4"
                color="textSecondary"
                className={classes.orderDialogSubtitle}
                gutterBottom
            >
                Itens do pedido
            </Typography>
            <Divider />
            <OrderItemList order={order} />
        </>
        )}
    </ResponsiveFullscreenDialog>
    );
}

function OrderDialogPlaceholder() {
    const classes = useStyles();

    return (
    <>
        <div className={clsx(
            classes.orderDialogMapHeader,
            classes.orderDialogMapHeaderVertical,
        )}>
            <div className={classes.orderDialogMapContainer}>
                <div className={clsx(placeholderAnimationClass, classes.orderDialogPlaceholderMap)} />
            </div>
            <OrderStepperPlaceholder orientation="vertical" />
        </div>
        <Typography
            variant="h4"
            color="textSecondary"
            className={classes.orderDialogSubtitle}
            gutterBottom
        >
            <CharactersPlaceholder n={10} />
        </Typography>
        <Divider />
        <List className={classes.orderDialogHeaderContainer}>
            <Repeat n={4}>
                <ListItemPlaceholder avatar secondaryLines={1} />
            </Repeat>
        </List>
        <Typography
            variant="h4"
            color="textSecondary"
            className={classes.orderDialogSubtitle}
            gutterBottom
        >
            <CharactersPlaceholder n={10} />
        </Typography>
        <Divider />
        <List className={classes.orderDialogHeaderContainer}>
            <Repeat n={4}>
                <ListItemPlaceholder avatar secondaryLines={1} />
            </Repeat>
        </List>
    </>
    );
}