import { newCompanyQuery, useCompanyQuery } from "../../../hooks/react-query";
import { useCompany } from "../../../providers/Company";
import { useGetCompanyStatus } from "../../../queries/company";
import { Extra, Mixable, Note, Product, Question, QuestionAnswer, Section, Supply, WithID, WithImageID } from "../../../types/domain"

export type MenuMixable = WithID<WithImageID<Mixable>>;
export type MenuQuestion = WithID<Question> & {
    answers: WithID<QuestionAnswer>[];
};
export type MenuProduct = WithImageID<WithID<Product>> & {
    ingredients: WithID<Supply>[];
};
export type MenuNote = WithID<Note>;
export type MenuExtra = WithID<Extra>;


export type MenuSection = WithID<Section> & {
    products: MenuProduct[];
    questions: MenuQuestion[];
    notes: MenuNote[];
    extras: MenuExtra[];
    mixables: MenuMixable[];
}

export type Menu = {
    menu_version: number;
    menu_generated_at: number;
    sections: MenuSection[];
}

export const QUERY_MENU = newCompanyQuery(
    '/store/:company_id/menu',
    { open: true }
)<
    {},
    Menu
>();
export const useGetMenu = () => {

    const query = useCompanyQuery(
        QUERY_MENU,
        {},
        { enabled: false }
    );

    const { companyID } = useCompany();
    useGetCompanyStatus({
        onSuccess: status => {
            if (companyID && !query.data) {
                query.refetch();
            } else if (
                query.data &&
                companyID &&
                (
                    query.data.menu_generated_at < status.menu_updated_at ||
                    query.data.menu_version < status.app_version
                )
            ) {
                query.refetch();
            }
        }
    });

    return query;
}