import { Divider, FormControlLabel, Grow, makeStyles, Switch } from "@material-ui/core";
import MoneyInput from "../../../../components/MoneyInput";
import Subtitle from "../../../../components/Subtitle";

const useStyles = makeStyles(theme => ({
    moenyChangePickerContainer: {
        padding: theme.spacing(2),
        display: 'flex',
        flexDirection: 'row'
    },
    label: {
        fontSize: theme.typography.fontSize*0.8,
        textOverflow: 'ellipses',
        whiteSpace: 'nowrap'
    },
    formControlLabel: {
        flexGrow: 1,
        margin: 0
    },
    divider: {
        marginRight: theme.spacing(2),
        marginLeft: theme.spacing(2),
    }
}));

type Props = {
    needChangeFor: boolean;
    changeFor: number;
    onChangeNeedChange: (needChange: boolean) => void;
    onChangeChangeFor: (newValue: number) => void;
};

export function MoneyChangePicker({
    needChangeFor,
    changeFor,
    onChangeNeedChange,
    onChangeChangeFor
}: Props) {
    const classes = useStyles();
    return (
    <Grow in>
        <div>
            <Subtitle divider>Troco</Subtitle>
            <div className={classes.moenyChangePickerContainer}>
                <FormControlLabel
                    control={<Switch
                        checked={needChangeFor}
                        onChange={(_, checked) => onChangeNeedChange(checked)}
                    />}
                    labelPlacement="top"
                    label="Precisa de troco?"
                    classes={{
                        label: classes.label
                    }}
                    className={classes.formControlLabel}
                />
                <Divider orientation="vertical" flexItem className={classes.divider} />
                <MoneyInput
                    onChange={value => onChangeChangeFor(value)}
                    value={changeFor}
                    label="Troco para quanto?"
                    disabled={!needChangeFor}
                />
            </div>
        </div>
    </Grow>
    );
}