import { Button, makeStyles, Typography } from "@material-ui/core";
import { ProductsSearchSvg } from "../../../../svg";
import { useMenuRoute } from "../nav/routes";

const useStyles = makeStyles(theme => ({
    bagEmptyStateContainer: {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        padding: theme.spacing(2)
    },
    bagEmptyStateTypography: {
        marginTop: theme.spacing(2),
        marginBottom: theme.spacing(2)
    },
    bagEmptyStateSVG: {
        opacity: 0.5,
        width: '100%',
        maxHeight: '30vh',
    }
}));

export function SearchEmptyState() {
    const classes = useStyles();
    const menuRoute = useMenuRoute();
    return (
    <div className={classes.bagEmptyStateContainer}>
        <ProductsSearchSvg className={classes.bagEmptyStateSVG} />
        <Typography
            className={classes.bagEmptyStateTypography}
            color="textSecondary"
            variant="h5"
            align="center"
            gutterBottom
        >
            Nenhum produto encontrado
        </Typography>
        <Button
            onClick={() => menuRoute.push({})}
            color="primary"
            variant="contained"
            size="large"
        >
            Voltar ao cardápio
        </Button>
    </div>
    );
}