import generateConstantsUtils from './wrapper';

export enum AppModeEnum {
    NORMAL = 1,
    TABLE = 2,
}

// eslint-disable-next-line
export type AppModeType = typeof AppModeEnum[keyof typeof AppModeEnum];


type AppModeProperties = {
    description: string,
}

const properties = {
    
    [AppModeEnum.NORMAL]: {
        description: "Modo normal",
    } as AppModeProperties,

    [AppModeEnum.TABLE]: {
        description: "Modo mesa",
    } as AppModeProperties,

} as { [key in AppModeType ]: AppModeProperties}

export const AppModeUtils = generateConstantsUtils(properties);