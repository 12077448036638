import { useCallback, useEffect, useState } from "react"

export const useDialog = () => {
    const [ visible, setVisible ] = useState(false);
    const open = useCallback(() => setVisible(true), []);
    const close = useCallback(() => setVisible(false), []);
    return {
        visible,
        open,
        close
    };
}

export const useDialogWithData = <T>() => {
    const { visible, open: dialogOpen, close } = useDialog();
    const [ data, setData ] = useState<T | null>(null);

    const open = useCallback((new_id: T) => {
        setData(new_id);
        dialogOpen();
    } , [ dialogOpen ]);

    return {
        visible,
        close,
        data,
        open
    }
}

export const usePersistentParam = <T>(paramValue: T | undefined) => {

    const [ value, setValue ] = useState(paramValue);

    useEffect(() => {
        if (paramValue !== undefined) {
            setValue(paramValue);
        }
    }, [ paramValue ]);


    const clear = () => setValue(undefined);

    return {
        value,
        clear
    };
}