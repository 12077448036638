import StoreIcon from '@material-ui/icons/Store';
import LocalLibraryIcon from '@material-ui/icons/LocalLibrary';
import WarningIcon from '@material-ui/icons/Warning';
import generateConstantsUtils from './wrapper';
import MotorcycleIcon from '@material-ui/icons/Motorcycle';

const DeliveryMethod = {
    NONE: 1,
    LOCAL: 2,
    DELIVERY: 3,
    TABLE: 4
} as const;

// eslint-disable-next-line
type DeliveryMethod = typeof DeliveryMethod[keyof typeof DeliveryMethod];

export default DeliveryMethod;

type DeliveryMethodProperties = {
    description: string,
    icon: JSX.Element
}

const properties = {
    [DeliveryMethod.NONE]: {
        description: "Nenhum método de entrega selecionado",
        icon: <WarningIcon />
    } as DeliveryMethodProperties,

    [DeliveryMethod.LOCAL]: {
        description: "Retirada no local",
        icon: <StoreIcon />
    } as DeliveryMethodProperties,

    [DeliveryMethod.DELIVERY]: {
        description: "Delivery",
        icon: <MotorcycleIcon />
    } as DeliveryMethodProperties,

    [DeliveryMethod.TABLE]: {
        description: "Consumir na mesa",
        icon: <LocalLibraryIcon />
    } as DeliveryMethodProperties,

} as { [key in DeliveryMethod ]: DeliveryMethodProperties}

export const DeliveryMethodUtils = generateConstantsUtils(properties);