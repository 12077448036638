import { ProductsSearchSvg } from '../../../../svg';
import { BaseEmptyState } from '../../../../components/BaseEmptyState';

export default function MenuEmptyState() {
	return (
	<BaseEmptyState
		message={`Nenhum produto encontrado`}
		SvgComponent={ProductsSearchSvg}
	/>
	);
}
