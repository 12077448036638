import { createContext, useContext } from 'react';
import { ProviderProps } from '../types/provider';
import DomainDialog from '../components/DomainDialog';
import { Button } from '@material-ui/core';
import DeleteIcon from '@material-ui/icons/Delete';
import { useDialogWithData } from '../hooks/dialog';

type DialogState = {
    title: string;
    message: string;
    buttonLabel: string;
    submit: () => void;
    variant: 'remove';
}

const useConfirmDialogProviderState = () => {
    const {
        visible,
        close,
        data,
        open
    } = useDialogWithData<DialogState>();

    return {
        openConfirmDialog: open,
        visible,
        close,
        data
    }
}

export type ConfirDialogContextValue = 
    Pick<ReturnType<typeof useConfirmDialogProviderState>, 'openConfirmDialog'>;

const ConfirmDialogContext = createContext({} as ConfirDialogContextValue);
export const useConfirmDialog = () => useContext(ConfirmDialogContext);

export function ConfirmDialogProvider({ children } : ProviderProps) {

    const {
        openConfirmDialog,
        visible,
        close,
        data: state
    } = useConfirmDialogProviderState();

	return (
	<ConfirmDialogContext.Provider value={{ openConfirmDialog }}>
        {state !== null && <ConfirmDialog
            state={state}
            visible={visible}
            close={close}
        />}
		{children}
	</ConfirmDialogContext.Provider>
	);
}

type DialogProps = {
    state: DialogState;
    visible: boolean;
    close: () => void;
}

function ConfirmDialog({
    state: {
        title,
        message,
        buttonLabel,
        submit,
        variant
    },
    visible,
    close
}: DialogProps) {

    return (
    <DomainDialog
        onClose={close}
        open={visible}
        title={title}
        icon={<IconByVariant variant={variant} />}
        maxWidth="xs"
        action={(
        <Button
            onClick={() => {
                submit();
                close();
            }}
            color="primary"
            variant="outlined"
            startIcon={<IconByVariant variant={variant} />}
        >
            {buttonLabel}
        </Button>
        )}
    >
        {message}
    </DomainDialog>
    );
}

function IconByVariant({ variant }: Pick<DialogState, 'variant'>) {
    switch (variant) {
        case 'remove':
            return <DeleteIcon />;
    }
}