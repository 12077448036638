import { useCallback, useState } from "react";

export const useMenuState = () => {
    const [ anchorEl, setAnchorEl ] = useState<any>(null);
    const handleClick = useCallback((event: any) => setAnchorEl(event.currentTarget), []);
    const handleClose = useCallback(() => setAnchorEl(null), []);
    return {
        handleClick,
        handleClose,
        anchorEl,
        open: anchorEl !== null
    };
}