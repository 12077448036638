import { Button, makeStyles, Tooltip } from "@material-ui/core";
import DeleteIcon from '@material-ui/icons/Delete';

const useStyles = makeStyles(theme => ({
    itemRemoveButton: {
        display: 'flex',
        height: '100%',
        borderRadius: theme.shape.borderRadius*4,
    },
    itemRemoveButtonContent: {
        display: 'flex',
        flexDirection: 'column',
        textTransform: 'none',
        lineHeight: '120%',
        padding: `${theme.spacing(1)}px 0px`,
        alignItems: 'center',
    },
    itemRemoveButtonLabel: {
        display: 'flex',
        alignItems: 'center',
        flexWrap: 'wrap',
        fontSize: theme.typography.caption.fontSize
    }
}));

type ItemRemoveButtonProps = {
    onClick: () => void;
}

export function ItemRemoveButton({ onClick }: ItemRemoveButtonProps) {
    const classes = useStyles();
    return (
    <Tooltip title={"Remover item da sacola"}>
        <Button
            color="primary"
            className={classes.itemRemoveButton}
            onClick={onClick}
        >
            <div className={classes.itemRemoveButtonContent}>
                <DeleteIcon fontSize="large" />
                <div className={classes.itemRemoveButtonLabel}>
                    REMOVER
                </div>
            </div>
        </Button>
    </Tooltip>
    );
}