import { ReactNode } from "react";
import { Provider } from '../types/provider';

interface Props {
	list: Provider[],
	children: ReactNode
}

export function Wrapper({ list, children } : Props) : JSX.Element {
	return list.reduceRight((acc, Component : Provider) => (
		<Component>{acc}</Component>
	), children as JSX.Element);
}