import { makeStyles } from '@material-ui/core/styles';
import { Typography, TypographyProps } from '@material-ui/core';
import clsx from 'clsx';
import SVGProps from '../svg/interfaces/props';

const useStyles = makeStyles(theme => ({
	container: {
		display: 'flex',
		justifyContent: 'center',
		alignItems: 'center',
		flexDirection: 'column',
		padding: `${theme.spacing(4)}px ${theme.spacing(1)}px ${theme.spacing(4)}px ${theme.spacing(1)}px`
	},
	svg: {
		maxHeight: '30vh',
		maxWidth: '80vw',
		marginBottom: theme.spacing(3),
		opacity: 0.5
	},
	message: {
		color: theme.palette.text.hint,
		fontWeight: theme.typography.fontWeightMedium
	}
}));

type BaseEmptyStateProps = {
	message : string,
	SvgComponent : (props : SVGProps) => JSX.Element,
	containerClassName? : string,
	typographyVariant? : TypographyProps<"div", {component: "div"}>['variant']
}

export function BaseEmptyState({
	message,
	SvgComponent,
	containerClassName,
	typographyVariant
}: BaseEmptyStateProps) {
	const classes = useStyles();
	return (
	<div className={clsx(
		classes.container,
		containerClassName
	)}>
		<SvgComponent className={classes.svg} />
		<Typography
			component="div"
			variant={typographyVariant || "h5"}
			className={classes.message}
			align="center"
		>
			{message}
		</Typography>
	</div>
	);
}