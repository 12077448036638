import { List, ListItem, ListItemSecondaryAction, ListItemText, Radio, makeStyles, Typography, Divider  } from '@material-ui/core';
import { ProductMenuStateType } from './hooks';
import { ProductMenuQuestion } from './types';

const useStyles = makeStyles(theme => ({
	productQuestionListItem: {
		paddingTop: theme.spacing(2),
		paddingBottom: theme.spacing(2),
	},
	productQuestionSubtitle: {
		paddingLeft: theme.spacing(1),
		marginTop: theme.spacing(2)

	}
}));

type ProductQuestionProps = {
	question: ProductMenuQuestion;
	productIndex: number;
	select: ProductMenuStateType['selectAnswer'];
};

export function ProductQuestion({
    question,
    productIndex,
    select,
}: ProductQuestionProps) {
	const classes = useStyles();

	if (question.answers.length <= 0) return null;
	return (
	<>
		<Typography
			variant="h5"
			gutterBottom
			color="textSecondary"
			className={classes.productQuestionSubtitle}
		>
			{question.description}
		</Typography>
		<Divider />
		<List disablePadding>
			{question.answers.map((answer, index) => (
			<ListItem
				button
				key={index}
				onClick={() => select(productIndex, question.id, answer.id)}
				className={classes.productQuestionListItem}
			>
				<ListItemText primary={answer.description} />
				<ListItemSecondaryAction>
					<Radio
						edge="end"
						checked={question.selected === answer.id}
						onChange={() => select(productIndex, question.id, answer.id)}
					/>
				</ListItemSecondaryAction>
			</ListItem>
			))}
		</List>
	</>
	);
}