import { ResponsiveFullscreenDialog } from "../../../../components/ResponsiveFullscreenDialog";
import ReceiptIcon from '@material-ui/icons/Receipt';
import { useMenuRoute, useSelectOrderRoute } from "../nav/routes";
import { useGetUserActiveOrders } from "../../queries";
import { OrderSelectListItem, OrderSelectListItemPlaceholder } from "./OrderSelectListItem";
import { Repeat } from "../../../../components/Repeat";

export function OrderActiveDialog() {

    const selectOrderRoute = useSelectOrderRoute();
    const menuRoute = useMenuRoute();

    const {
        data: activeOrders,
        isLoading: activeOrdersLoading
    } = useGetUserActiveOrders();

    return (
    <ResponsiveFullscreenDialog
        open={selectOrderRoute.matched && selectOrderRoute.exact}
        onClose={() => menuRoute.push({})}
        title="Selecione um pedido"
        icon={<ReceiptIcon />}
        width="sm"
    >
        {!activeOrders || activeOrdersLoading ? (
            <Repeat n={2}>
                <OrderSelectListItemPlaceholder />
            </Repeat>
        ) :
            activeOrders.map((order, index) => (
                <OrderSelectListItem key={index} order={order} />
            ))
        }
    </ResponsiveFullscreenDialog>
    );
}