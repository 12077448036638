import { match, useHistory, useLocation, useRouteMatch } from "react-router";
import PathPlaceholders from "../types/path";
import useRouter from '../providers/Router';
import { encodeBase64 } from "../utils/format";

type ConvertedParams<U extends string> = Record<PathPlaceholders<U>, number>;
type RawParams<U extends string> = Record<PathPlaceholders<U>, string | undefined>;

function parseURLParams<U extends string,>(
    url: `${string}${U}`,
    params: ConvertedParams<U> | RawParams<U>,
    query?: string
): string {
    let parsedURL: string = url;
    if (params) {
        for (let paramName in params) {
            parsedURL = parsedURL.replace(
                `:${paramName}`,
                (params[(paramName as PathPlaceholders<U>)] as any).toString()
            );
        }
    }
    if (query) {
        parsedURL =`${parsedURL}?${encodeBase64(query)}`;
    }
    return parsedURL;
}

function convertMatchParamsToNumber<U extends string>(
    match: match<RawParams<U>> | null
): {
    params: Partial<ConvertedParams<U>>,
    matched: boolean;
} {
    if (match === null) {
        return {
            params: {},
            matched: false,
        };
    }
    let matched = true;

    const { params } = match;
    const convertedParams: Partial<ConvertedParams<U>> = {}
    for (let paramName in params) {
        const parsedParamName = (paramName as PathPlaceholders<U>);
        const stringValue: string | undefined = params[parsedParamName];
        if (stringValue === undefined) {
            console.error(`Param ":${parsedParamName}" is undefined`)
            convertedParams[parsedParamName] = 0;
            matched = false;
        } else {
            const convertedValue = parseInt(stringValue);
            if (isNaN(convertedValue)) {
                convertedParams[parsedParamName] = 0;
                console.error(`Param ":${parsedParamName}" is not integer`)
                matched = false
            } else {
                convertedParams[parsedParamName] = convertedValue;
            }
        }        
    }
    return {
        params: convertedParams,
        matched
    };
}


export type Route<U extends string> = {
    path: `${string}${U}`;
    push: (params: ConvertedParams<U>, query?: string) => void;
    matched: boolean;
    params: Partial<ConvertedParams<U>>;
    parseString: (params: ConvertedParams<U>, query?: string) => string;
    exact: boolean;
}

export function useRoute<U extends string>(path: U): Route<U> {
    const { pathPrefix } = useRouter();
    const pathWithPrefix = `${pathPrefix}${path}` as `${string}${U}`;
    const match = useRouteMatch<RawParams<U>>({
        path: pathWithPrefix,
    });
    const history = useHistory();
    const conversion = convertMatchParamsToNumber(match);
    const location = useLocation();
    return {
        path: pathWithPrefix,
        push: (params, query) => history.push(parseURLParams(pathWithPrefix, params, query)+location.hash),
        parseString: (params, query) => parseURLParams(pathWithPrefix, params, query)+location.hash,
        matched: conversion.matched,
        params: conversion.params,
        exact: match?.isExact || false
    }
}

export type RouteString<U extends string> = {
    path: `${string}${U}`;
    push: (params: RawParams<U>, query?: string) => void;
    
    matched: boolean;
    params?: Partial<RawParams<U>>;
    exact: boolean;
    parseString: (params: RawParams<U>, query?: string) => string;
}

export function useRouteString<U extends string>(path: U): RouteString<U> {
    const { pathPrefix } = useRouter();
    const pathWithPrefix = `${pathPrefix}${path}` as `${string}${U}`;
    const match = useRouteMatch<RawParams<U>>({
        path: pathWithPrefix,
    });
    const location = useLocation();
    const history = useHistory();
    return {
        path: pathWithPrefix,
        push: (params, query) => history.push(parseURLParams(pathWithPrefix, params, query)+location.hash),
        parseString: (params, query) => parseURLParams(pathWithPrefix, params, query)+location.hash,
        matched: match !== null,
        params: match?.params,
        exact: match?.isExact || false
    }
}