import { Fab, Tooltip, Typography } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import RemoveIcon from '@material-ui/icons/Remove';
import AddIcon from '@material-ui/icons/Add';
import { placeholderAnimationClass } from '../../../../providers/Theme';
import { CharactersPlaceholder } from '../../../../placeholders/CharactersPlaceholder';
import DeleteIcon from '@material-ui/icons/Delete';
import clsx from 'clsx';

const useStyles = makeStyles(theme => ({
	productAmountMainContainer: {
		display: 'flex',
		flexDirection: 'row',
		paddingBottom: theme.spacing(6),
		paddingTop: theme.spacing(6)
	},
	productAmountItemContainer: {
		display: 'flex',
		flexGrow: 1,
		alignItems: 'center',
		justifyContent: 'center'
	},
	productAmountDecreaseFab: {
		width: theme.spacing(10),
		height: theme.spacing(10),
	},
	productAmountDecreaseFabEnabled: {
		color: theme.palette.error.contrastText,
		backgroundColor: theme.palette.error.main,
		"&:hover":{
			backgroundColor: theme.palette.error.dark
		},
	},
	productAmountIncreaseFab: {
		color: theme.palette.success.contrastText,
		backgroundColor: theme.palette.success.main,
		width: theme.spacing(10),
		height: theme.spacing(10),
		"&:hover":{
			backgroundColor: theme.palette.success.dark
		},
	}
}));

type ProductAmountProps = {
	amount: number;
	decrease: () => void;
	increase: () => void;
	remove?: (() => void) | false;
}

export function ProductAmount({
	amount,
	decrease,
	increase,
	remove
} : ProductAmountProps) {
	const classes = useStyles();

	
	return (
		<div className={classes.productAmountMainContainer}>
			<div className={classes.productAmountItemContainer}>
				{remove && amount <= 1 ? (
				<Tooltip title="Remover item da sacola">
					<Fab
						size="large"
						onClick={() => amount === 1 && remove()}
						className={clsx(
							classes.productAmountDecreaseFab,
							classes.productAmountDecreaseFabEnabled
						)}
					>
						<DeleteIcon />
					</Fab>
				</Tooltip>
				) : (
				<Fab
					size="large"
					onClick={() => amount > 1 && decrease()}
					disabled={amount <= 1}
					className={clsx(
						classes.productAmountDecreaseFab,
						amount > 1 && classes.productAmountDecreaseFabEnabled,
					)}
				>
					<RemoveIcon />
				</Fab>
				)}
			</div>
			<div className={classes.productAmountItemContainer}>
				<Typography variant="h4">
					{amount}
				</Typography>
			</div>
			<div className={classes.productAmountItemContainer}>
				<Fab
					size="large"
					onClick={() => increase()}
					className={classes.productAmountIncreaseFab}
				>
					<AddIcon />
				</Fab>
			</div>
		</div>
	);
}

export function ProductAmountPlaceholder() {
	const classes = useStyles();

	return (
		<div className={classes.productAmountMainContainer}>
			<div className={classes.productAmountItemContainer}>
				<Fab
					size="large"
					className={placeholderAnimationClass}
					disabled
				><></></Fab>
			</div>
			<div className={classes.productAmountItemContainer}>
				<CharactersPlaceholder n={3} />
			</div>
			<div className={classes.productAmountItemContainer}>
				<Fab
					size="large"
					className={placeholderAnimationClass}
					disabled
				><></></Fab>
			</div>
		</div>
	);
}
