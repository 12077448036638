import { ResponsiveFullscreenDialog } from "../../../../components/ResponsiveFullscreenDialog";
import { useSearchDialogState } from "./hooks";
import SearchIcon from '@material-ui/icons/Search';
import { ScrollTabs } from "../../components/ScrollTabs";
import { useVerticalScroll } from "../../../../hooks/verticalScroll";
import useTheme from "../../../../providers/Theme";
import {
    Divider,
    List,
    makeStyles,
    Typography,
    useTheme as useMuiTheme
} from "@material-ui/core";
import { ProductListItem } from "../menu/ProductListItem";
import { useOpenSingleProductMenu } from "../product_menu/hooks";
import { useOpenMixableDialog } from "../mixable/hooks";
import { SearchEmptyState } from "./SearchEmptyState";
import MixableType from "../../../../constants/mixable_type";
import { CharactersPlaceholder } from "../../../../placeholders/CharactersPlaceholder";
import { Repeat } from "../../../../components/Repeat";
import { ListItemPlaceholder } from "../../../../placeholders/ListItemPlaceholder";

const useStyles = makeStyles(theme => ({
    searchSectionName: {
        marginTop: theme.spacing(6),
        paddingLeft: theme.spacing(2)
    },
}));

export function SearchDialog() {

    const classes = useStyles();

    const { openMixableDialog } = useOpenMixableDialog();

    const {
        searchResults,
		visible,
		close,
		query,
        clear
    } = useSearchDialogState();

    const { transitions } = useTheme();
    const { spacing: themeSpacing } = useMuiTheme();

    const {
        scrollHandler,
        sectionsRefs,
        containerRef,
        selectSection,
        activeIndex
    } = useVerticalScroll<HTMLDivElement, HTMLDivElement>({
        smoothScrolling: transitions,
        sectionsLength: searchResults?.length || 0,
        scrollOffset: themeSpacing(17)
    });

    const { openSingleProductMenu } = useOpenSingleProductMenu();

    return (
    <ResponsiveFullscreenDialog
        open={visible}
        onClose={close}
        icon={<SearchIcon />}
        title={`Pesquisa "${query}"`}
        width="sm"
        onExited={() => clear()}
        onScroll={scrollHandler}
        ref={containerRef}
    >
        {searchResults === null ? <SearchDialogPlaceholder /> : 
            searchResults.length === 0 ? <SearchEmptyState /> : (
        <>
            <ScrollTabs
                value={activeIndex}
                onChange={newIndex => selectSection(newIndex)}
                items={searchResults.map(section => ({ label: section.name }))}
            />
            {searchResults.map((section, section_index) => (
            <div key={`sec-${section_index}`} ref={sectionsRefs[section_index]}>
                <Typography
                    className={classes.searchSectionName}
                    color="textSecondary"
                    gutterBottom
                    variant="h5"
                >
                    {section.name}
                </Typography>
                <Divider />
                <List >
                    {section.mixables.map((mixable, index) => (
                        <ProductListItem
                            key={`${section_index}-mix-${index}`}
                            onClick={() => openMixableDialog(mixable.id)}
                            name={mixable.name}
                            description={mixable.description}
                            price={(
                                mixable.type_id === MixableType.FIXED_PRICE ||
                                mixable.type_id === MixableType.COMBO ?
                                mixable.price : null
                            )}
                            image_id={mixable.image_id}
                        />
                    ))}
                    {section.products.map((product, index) => (
                    <ProductListItem
                        key={`${section_index}-${index}`}
                        onClick={() => openSingleProductMenu(section.id, product.id)}
                        name={product.name}
                        description={product.description}
                        price={product.price}
                        image_id={product.image_id}
                    />
                    ))}
                </List>
            </div>
            ))}
        </>
        )}
    </ResponsiveFullscreenDialog>
    );
}

function SearchDialogPlaceholder() {
    const classes = useStyles();

    return (
    <>
        <ScrollTabs
            value={false}
            alwaysVisible
            items={Array(5).fill(null).map(() => ({
                label: <CharactersPlaceholder n={8} />,
                disabled: true
            }))}
        />
        <Repeat n={2}>
            <Typography
                className={classes.searchSectionName}
                color="textSecondary"
                gutterBottom
                variant="h5"
            >
                <CharactersPlaceholder n={10} />
            </Typography>
            <Divider />
            <List>
                <Repeat n={5}>
                    <ListItemPlaceholder avatar secondaryLines={1} />
                </Repeat>
            </List>
        </Repeat>
    </>
    );
}