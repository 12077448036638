import { FormControl, FormHelperText, FormLabel, makeStyles } from "@material-ui/core";
import clsx from "clsx";
import { Marker as MarkerType } from "leaflet";
import { useEffect, useLayoutEffect, useMemo, useRef, useState } from "react";
import { MapContainer, Marker, Popup, TileLayer } from "react-leaflet";
import useGeoposition, { Position } from "../providers/Geoposition";
import { placeholderAnimationClass } from "../providers/Theme";

const useStyles = makeStyles(theme => ({
    mapPlaceholder: {
        width: '100%',
        height: '100%',
        minHeight: '180px',
        padding: theme.spacing(2),
        color: `${theme.palette.text.primary} !important`
    },
    mapContainer: {
        height: '100%',
        width: '100%',
        minHeight: '180px',
    },
    label: {
        color: theme.palette.text.secondary
    },
    outerContainer: {
        borderRadius: theme.shape.borderRadius,
        marginTop: theme.spacing(0.75),
        marginBottom: theme.spacing(1),
        boxShadow: theme.shadows[4],
        overflow: 'hidden'
    },
}))

function isDefinedPosition(
    position: Partial<Position>
): position is Position {
    return position.latitude !== undefined &&
        position.longitude !== undefined
}

type DraggableMarkerProps = {
    position: Position,
    onChange: (position: Position) => void
}
function DraggableMarker({
    position,
    onChange
}: DraggableMarkerProps) {

    const markerRef = useRef<MarkerType<any>>(null);
    const eventHandlers = useMemo(
        () => ({
            dragend() {
                const marker = markerRef.current
                if (marker != null) {
                    let latlng = marker.getLatLng()
                    onChange({
                        latitude: latlng.lat,
                        longitude: latlng.lng
                    })
                }
            },
        }),
        // eslint-disable-next-line
        [],
    )
  
    return (
    <Marker
        draggable={true}
        eventHandlers={eventHandlers}
        position={[ position.latitude, position.longitude ]}
        ref={markerRef}
    >
        <Popup minWidth={90}>
            Você pode arrastar o marcador
        </Popup>
    </Marker>
    )
}

type MapProps = {
    latitude?: string;
    longitude?: string;
    loading?: boolean;
    className?: string;
};
export function Map({
    latitude,
    longitude,
    loading,
    className
}: MapProps) {
    const classes = useStyles();
    const [ position, setPosition ] = useState<Partial<Position>>({
        latitude: undefined,
        longitude: undefined
    });
    

    useLayoutEffect(() => {
        setPosition({
            latitude: latitude === undefined || isNaN(Number(latitude)) ? undefined : parseFloat(latitude),
            longitude: longitude === undefined || isNaN(Number(longitude)) ? undefined : parseFloat(longitude),
        })
    }, [ latitude, longitude ]);

    return (
    <div className={clsx(classes.outerContainer, className)}>
        {loading ? (
            <div className={clsx(placeholderAnimationClass, classes.mapPlaceholder)} />
        ) : !isDefinedPosition(position) ? (
        <div className={clsx(placeholderAnimationClass, classes.mapPlaceholder)}>
            Impossível encontrar a posição no mapa
        </div>
        ) : (
        <MapContainer
            className={classes.mapContainer}
            center={[
                position.latitude,
                position.longitude
            ]}
            zoom={13}
        >
            <TileLayer
                url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
            />
            <Marker
                position={[ position.latitude, position.longitude ]}
            />
        </MapContainer>
        )}
    </div>
    );
}

type InputProps = {
    defaultLongitude: string;
    defaultLatitude: string;
    loading: boolean;
    onChange: (latitude: string, longitude: string) => void,
    label: string;
    error?: string;
    className?: string;
}
export function MapInput({
    defaultLatitude,
    defaultLongitude,
    onChange,
    loading,
    label,
    error,
    className
}: InputProps) {

    const [ parseError, setParseError ] = useState(false);
    const [ position, setPosition ] = useState<Partial<Position>>({
        latitude: undefined,
        longitude: undefined
    });
    const {
        geopositionAPIAvailable,
        permissionsAPIAvailable,
        geopositionCurrentPermission,
        getPosition
    } = useGeoposition();

    function syncPosition(newPosition: Position) {
        setPosition(newPosition);
        onChange(
            newPosition.latitude.toString(),
            newPosition.longitude.toString()
        );
    }

    function getNotDefinedLabel(): string {
        if (parseError) {
            return "Impossível encontrar a posição";
        }
        if (!geopositionAPIAvailable) {
            return "Seu navegador não suporta geolocalização...";
        }
        if (permissionsAPIAvailable) {
            switch (geopositionCurrentPermission) {
                case 'prompt':
                    return "Conceda ao aplicativo a permissão de acessar sua geolocalização...";
                case 'unread':
                case 'granted':
                    return "Carregando posição...";
                case 'denied':
                case 'unknown':
                    return "Você bloqueou o acesso do aplicativo à sua geolocalização...";
            }
        } else {
            return "Permita o site acessar sua localização...";
        }
    }
    
    useEffect(() => {
        if (isNaN(Number(defaultLatitude)) || isNaN(Number(defaultLongitude))) {
            setParseError(true);
        } else {
            setParseError(false);
        }
        let defaultLatitudeValue = parseFloat(defaultLatitude);
        let defaultLongitudeValue = parseFloat(defaultLongitude);
        if (
            defaultLatitude !== "" &&
            defaultLongitude !== "" &&
            Number.isFinite(defaultLatitudeValue) &&
            Number.isFinite(defaultLongitudeValue) 
        ) {
            syncPosition({
                latitude: defaultLatitudeValue,
                longitude: defaultLongitudeValue
            });
        } else {
            getPosition().then(newPosition => (
                syncPosition(newPosition)
            ));
                
        }
    // eslint-disable-next-line
    }, [ geopositionCurrentPermission, getPosition ]);

    const classes = useStyles();

    return (
    <FormControl
        fullWidth
        error={error !== undefined}
    >
        <FormLabel component="legend">{label}</FormLabel>
        <div className={clsx(classes.outerContainer, className)}>
            {loading ? (
            <div className={clsx(
                placeholderAnimationClass,
                classes.mapPlaceholder
            )} />
            ) : !isDefinedPosition(position) ? (
            <div className={clsx(
                placeholderAnimationClass,
                classes.mapPlaceholder
            )}>
                {getNotDefinedLabel()}
            </div>
            ) : (
            <MapContainer
                className={classes.mapContainer}
                center={[
                    position.latitude,
                    position.longitude
                ]}
                zoom={13}
            >
                <TileLayer
                    attribution='&copy; <a href="http://osm.org/copyright">OpenStreetMap</a> contributors'
                    url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
                />
                <DraggableMarker
                    position={position}
                    onChange={(newPosition) => {
                        syncPosition(newPosition)
                    }}
                />
            </MapContainer>
            )}
        </div>
        {error !== undefined && <FormHelperText>{error}</FormHelperText>}
    </FormControl>
    );
}