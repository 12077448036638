import { Fab, makeStyles, Tooltip } from "@material-ui/core";
import { GlobalProfileMenuButton } from "../../../../containers/GlobalProfileMenuButton";
import { useSession } from "../../../../providers/Session";
import { usePreviousOrdersRoute } from "../nav/routes";
import { useProfile } from "./ProfileProvider";

const useStyles = makeStyles(theme => ({
    connectButton: {
        padding: `0px ${theme.spacing(2)}px !important`,
        flexShrink: 0, 
    }
}));

export function ProfileButton() {
    const { loggedIn } = useSession();

    if (loggedIn) {
        return <LoggedIn />
    }
    return <LoggedOut />
}

function LoggedIn() {
    const previousOrderRoute = usePreviousOrdersRoute();
    return (
        <GlobalProfileMenuButton
            openPreviousOrders={() => previousOrderRoute.push({ page: 1 })}
        />
    );
}

function LoggedOut() {
    const classes = useStyles();
    const { openConnect } = useProfile();
    return (
    <Tooltip title="Conectar ou cadastrar na plataforma">
        <Fab
            classes={{
                sizeSmall: classes.connectButton
            }}
            color="secondary"
            variant="extended"
            size="small"
            onClick={() => openConnect()}
        >
            Entrar
        </Fab>
    </Tooltip>
    );
}