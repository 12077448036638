import { useCompany } from "../providers/Company";
import { getURLAddress } from "../utils/url";

type ImageSource = {
    small: string;
    medium: string;
    large: string;
}

export function useImageSource(imageID: string | null | undefined): ImageSource {
    const { companyID } = useCompany();
    if (imageID === null || imageID === undefined || companyID === null || imageID.length === 0) return {
        small: '',
        medium: '',
        large: ''
    };
    return {
        small: `${getURLAddress()}/gallery/${companyID}/${imageID}_small.png`,
        medium: `${getURLAddress()}/gallery/${companyID}/${imageID}_medium.png`,
        large: `${getURLAddress()}/gallery/${companyID}/${imageID}_large.png`,
    };
}