import { makeStyles, useMediaQuery, useTheme } from '@material-ui/core';
import MuiPagination from '@material-ui/lab/Pagination';

const useStyles = makeStyles(theme => ({
    paginationContainer: {
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'end',
        flexGrow: 1,
        padding: `${theme.spacing(1)}px ${theme.spacing(1)}px`
    },
    paginationGrow: {
        flexGrow: 1
    }
}))

type PaginationProps = {
    onChange: (newPage: number) => void;
    page: number;
    count: number;
}

export function Pagination({ count, page, onChange }: PaginationProps) {
    const classes = useStyles();
    const theme = useTheme();
    const smallScreen = useMediaQuery(theme.breakpoints.down("xs"));

    if (count === 1 || page > count) {
        return null;
    }

    return (
    <div className={classes.paginationContainer}>
        <div className={classes.paginationGrow} />
        <MuiPagination
            color="secondary"
            size={smallScreen ? "small" : "medium"}
            siblingCount={smallScreen ? 1 : 2}
            count={count}
            page={page}
            onChange={(_, newPage) => onChange(newPage)}
        />
    </div>
    );
}