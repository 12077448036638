import Brightness5Icon from '@material-ui/icons/Brightness5';
import VisibilityOffIcon from '@material-ui/icons/VisibilityOff';
import VisibilityIcon from '@material-ui/icons/Visibility';
import Brightness2Icon from '@material-ui/icons/Brightness2';
import Brightness6Icon from '@material-ui/icons/Brightness6';
import useTheme from '../providers/Theme';
import { ConfigIcon } from '../components/Icons';
import {
    Divider,
    IconButton,
    ListItemIcon,
    ListItemText,
    ListSubheader,
    makeStyles,
    Menu,
    MenuItem,
    Tooltip
} from '@material-ui/core';
import { useMenuState } from '../hooks/menu';

const useStyles = makeStyles(theme => ({
    appConfigMenuDivider: {
        // marginTop: theme.spacing(1)
    }
}));

export function GlobalAppConfigMenuButton() {
    const classes = useStyles();

    const {
		enableDarkThemeType,
		enableLightThemeType,
		enableSystemThemeType,
		isDarkThemeType,
		isLightThemeType,
		isSystemThemeType,
		transitions,
		enableTransitions,
		disableTransitions
	} = useTheme();

    const {
        handleClick,
        handleClose,
        open,
        anchorEl
    } = useMenuState();

    return (<>
        <Tooltip title="Configurações do aplicativo">
            <span>
                <IconButton
                    onClick={handleClick}
                    color="inherit"
                >
                    <ConfigIcon />
                </IconButton>
            </span>
        </Tooltip>
        <Menu
            anchorOrigin={{
                vertical: 'bottom',
                horizontal: 'right'
            }}
            transformOrigin={{
                vertical: 'top',
                horizontal: 'right'
            }}
            keepMounted
            open={open}
            onClose={handleClose}
            anchorEl={anchorEl}
            getContentAnchorEl={null}
        >
            {[{
                description: 'Cor do tema',
                items: [{
                    label: "Usar modo noturno",
                    icon: <Brightness2Icon />,
                    onClick: () => isDarkThemeType || enableDarkThemeType(),
                    selected: isDarkThemeType
                },{
                    label: "Usar modo claro",
                    icon: <Brightness5Icon />,
                    onClick: () => isLightThemeType || enableLightThemeType(),
                    selected: isLightThemeType
                },{
                    label: "Usar modo do sistema",
                    icon: <Brightness6Icon />,
                    onClick: () => isSystemThemeType || enableSystemThemeType(),
                    selected: isSystemThemeType
                }]
            }, {
                description: 'Animações',
                items: [{
                    label: "Ativar animações",
                    icon: <VisibilityIcon />,
                    onClick: () => enableTransitions(),
                    selected: transitions
                }, {
                    label: "Desativar animações",
                    icon: <VisibilityOffIcon />,
                    onClick: () => disableTransitions(),
                    selected: !transitions
                }]
            }].map((section, section_index) => (<div key={section_index}>
            <ListSubheader>{section.description}</ListSubheader>
            <Divider className={classes.appConfigMenuDivider} />
            {section.items.map((item, index) => (
            <MenuItem
                selected={item.selected}
                key={`${section_index}-${index}`}
                button
                onClick={() => {
                    item.onClick();
                    handleClose();
                }}
            >
                <ListItemIcon>{item.icon}</ListItemIcon>
                <ListItemText>{item.label}</ListItemText>
            </MenuItem>
            ))}
            </div>))}
        </Menu>
    
    </>);
}
