import { UseQueryOptions } from "react-query";
import { newQuery, useQuery } from "../hooks/react-query";
import { OrderWrapper } from "../types/domain";
import HTTPError from "../types/httperror";


type QueryOrderToPrintParams = {
    order_id: string;
    company_id: string;
}
export const QUERY_ORDER_TO_PRINT = newQuery('/portal/:company_id/order/:order_id')<
    QueryOrderToPrintParams,
    OrderWrapper
>();
export const useGetOrderToPrint = (
    params: QueryOrderToPrintParams,
    options?: UseQueryOptions<unknown, HTTPError<QueryOrderToPrintParams>, OrderWrapper>
) => useQuery(
    QUERY_ORDER_TO_PRINT,
    params,
    options
);