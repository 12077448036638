import {
    makeStyles,
    Tooltip,
    IconButton,
    Menu,
    Badge,
    Avatar,
    MenuItem,
    Divider,
    ListItemText,
    ListItemIcon,
    ListSubheader,
    Chip
} from "@material-ui/core";
import { useGetUserAddresses, useGetUserInfo } from '../queries/user';
import useUser from "../providers/User";
import { ParagraphPlaceholder } from "../placeholders/ParagraphPlaceholder";
import { AvatarPlaceholder } from "../placeholders/AvatarPlaceholder";
import { ListItemPlaceholder } from "../placeholders/ListItemPlaceholder";
import { AddressIcon, LogoutIcon, MailIcon, PasswordIcon, PhoneIcon, ProfileIcon, UserIcon } from "../components/Icons";
import { useMenuState } from "../hooks/menu";
import { Repeat } from "../components/Repeat";
import { useSession } from "../providers/Session";
import ReceiptIcon from '@material-ui/icons/Receipt';

const useStyles = makeStyles(theme => ({
    profileCardContainer: {
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center',
        padding: theme.spacing(2),
    },
    profileCardEmailChip: {
        marginTop: theme.spacing(2),
        maxWidth: '100%',
        textOverflow: 'ellipsis',
        overflow: 'hidden',
        whiteSpace: 'nowrap',
        display: 'flex',
        flex: 'nowrap'
    },
    profileCardAvatar: {
        width: 80,
        height: 80,
        backgroundColor: theme.palette.primary.main,
        boxShadow: theme.shadows[1],
    },
    profileCardAvatarIcon: {
        fontSize: 65,
        color: theme.palette.primary.contrastText
    },
    profileMenu: {
        width: '300px',
    },
    profileMenuDivider: {
        marginTop: theme.spacing(1)
    }
}));

type GlobalProfileMenuButtonProps = {
    hiddenPersonalData?: boolean;
    openPreviousOrders?: () => void;
}
export function GlobalProfileMenuButton({
    hiddenPersonalData,
    openPreviousOrders
}: GlobalProfileMenuButtonProps) {
    
    const classes = useStyles();

    const {
        logout
    } = useSession();

    const {
        openUserAddresses,
        openUserPersonalInfo,
        openUserUpdatePassword,
    } = useUser();

    const {
        handleClick,
        handleClose,
        anchorEl,
        open
    } = useMenuState();

    const { loggedIn } = useSession();

    const { data: userInfo, isLoading: userInfoLoading} = useGetUserInfo()
    const { data: addresses, isLoading: addressesLoading} = useGetUserAddresses()

    return (
    <>
        <Tooltip title="Seu perfil">
            <span>
                <IconButton
                    onClick={handleClick}
                    color="inherit"
                    disabled={userInfoLoading || !loggedIn}
                >
                    <ProfileIcon />
                </IconButton>
            </span>
        </Tooltip>
        <Menu
            anchorOrigin={{
                vertical: 'bottom',
                horizontal: 'right'
            }}
            transformOrigin={{
                vertical: 'top',
                horizontal: 'right'
            }}
            keepMounted
            open={open}
            onClose={handleClose}
            anchorEl={anchorEl}
            getContentAnchorEl={null}
            classes={{
                paper: classes.profileMenu
            }}
        >
            <div className={classes.profileCardContainer}>
                {userInfoLoading || !userInfo ? (
                <AvatarPlaceholder className={classes.profileCardAvatar} />
                ) : (
                <Avatar className={classes.profileCardAvatar}>
                    <UserIcon className={classes.profileCardAvatarIcon} />
                </Avatar>
                )}
                <Tooltip title={userInfo?.email || ''}>
                    <Chip
                        className={classes.profileCardEmailChip}
                        avatar={<MailIcon />}
                        size="small"
                        label={(
                            userInfo && !userInfoLoading ? 
                                userInfo.email :
                                <ParagraphPlaceholder lines={1} />
                        )}
                    />
                </Tooltip>
            </div>
            {!hiddenPersonalData && (<div>
                <Divider className={classes.profileMenuDivider} />
                <ListSubheader disableSticky>Informações pessoais</ListSubheader>
                {userInfoLoading || !userInfo || addressesLoading || !addresses ? (
                <Repeat n={2}>
                    <ListItemPlaceholder avatar />
                </Repeat>
                ) : (<div>
                    {openPreviousOrders !== undefined && (
                        <MenuItem
                            button
                            onClick={() => {
                                openPreviousOrders(); 
                                handleClose();
                            }}
                        >
                            <ListItemIcon>
                                <ReceiptIcon />
                            </ListItemIcon>
                            <ListItemText>Pedidos anteriores</ListItemText>
                        </MenuItem>
                    )}
                    <MenuItem
                        button
                        onClick={() => {
                            openUserPersonalInfo(); 
                            handleClose();
                        }}
                    >
                        <ListItemIcon>
                            <PhoneIcon />
                        </ListItemIcon>
                        <ListItemText>Informações pessoais</ListItemText>
                    </MenuItem>
                    <MenuItem
                        button
                        onClick={() => {
                            openUserAddresses();
                            handleClose();
                        }}
                    >
                        <ListItemIcon>
                            <Badge
                                color="secondary"
                                badgeContent={addresses.length || "+"}
                            >
                                <AddressIcon />
                            </Badge>
                        </ListItemIcon>
                        <ListItemText>Endereços</ListItemText>
                    </MenuItem>
                </div>)}
            </div>)}
            <Divider className={classes.profileMenuDivider} />
            <ListSubheader disableSticky>Sua conta</ListSubheader>
            {userInfoLoading || !userInfo ? <ListItemPlaceholder avatar /> : (
            <MenuItem
                button
                onClick={() => {
                    openUserUpdatePassword();
                    handleClose();
                }}
            >
                <ListItemIcon><PasswordIcon /></ListItemIcon>
                <ListItemText>Alterar senha</ListItemText>
            </MenuItem>
            )}
            <MenuItem
                button
                onClick={() => {
                    logout();
                    handleClose();
                }}
            >
                <ListItemIcon><LogoutIcon /></ListItemIcon>
                <ListItemText>Sair</ListItemText>
            </MenuItem>
        </Menu>
    </>
    );
};