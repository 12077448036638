import { AppBar, Container, makeStyles, Toolbar } from "@material-ui/core";

const useStyles = makeStyles(theme => ({
    topBar: {
        position: 'fixed',
        top: 0,
        left: 0,
        width: '100vw'
    }
}));

type TopBarProps = {
    children: JSX.Element[];
};
export function TopBar({ children }: TopBarProps) {
    const classes = useStyles();
    return (
    <>
        <Toolbar />
        <AppBar
            position="static"
            className={classes.topBar}
        >
            <Container maxWidth="md">
                <Toolbar disableGutters>
                    {children}
                </Toolbar>
            </Container>
        </AppBar>
    </>
    );
}

export function TopBarPlaceholder({ children }: TopBarProps) {
    const classes = useStyles();
    return (
    <>
        <Toolbar />
        <AppBar
            position="static"
            color="inherit"
            className={classes.topBar}
        >
            <Container maxWidth="md">
                <Toolbar disableGutters>
                    {children}
                </Toolbar>
            </Container>
        </AppBar>
    </>
    );
}