import { Avatar, Divider, List, ListItem, ListItemAvatar, ListItemSecondaryAction, ListItemText, makeStyles, Tooltip, Typography } from "@material-ui/core";
import { ReactNode, useMemo } from "react";
import { AddressIcon, MailIcon, PhoneIcon, StoreIcon, WhatsAppIcon } from "../../../../components/Icons";
import { ResponsiveFullscreenDialog } from "../../../../components/ResponsiveFullscreenDialog";
import { useGetCompanyFullInfo } from "../../queries";
import { useCompanyInfoRoute, useMenuRoute } from "../nav/routes";
import { formatAddress, formatMoney, formatPhone } from "../../../../utils/format";
import AttachMoneyIcon from '@material-ui/icons/AttachMoney';
import DeliveryMethod from "../../../../constants/delivery_method";
import MotorcycleIcon from '@material-ui/icons/Motorcycle';
import ChatBubbleOutlineIcon from '@material-ui/icons/ChatBubbleOutline';
import { sendWhatsAppMessage } from '../../../../hooks/whatsapp';
import { CharactersPlaceholder } from "../../../../placeholders/CharactersPlaceholder";
import { ParagraphPlaceholder } from "../../../../placeholders/ParagraphPlaceholder";
import { Repeat } from "../../../../components/Repeat";
import { ListItemPlaceholder } from "../../../../placeholders/ListItemPlaceholder";

const useStyles = makeStyles(theme => ({
    companyInfoDialogAvatar: {
        backgroundColor: theme.palette.secondary.main,
        color: theme.palette.secondary.contrastText,
    },
    companyInfoTypography: {
        padding: `0px ${theme.spacing(2)}px`,
        margin: `${theme.spacing(2)}px 0px ${theme.spacing(1)}px 0px`
    }
}));


type DialogProps = {
    children: ReactNode;
    title: string;
}
function Dialog({ children, title }: DialogProps) {
    const companyInfoRoute = useCompanyInfoRoute();
    const menuRoute = useMenuRoute();

    return (
    <ResponsiveFullscreenDialog
        open={companyInfoRoute.matched}
        onClose={() => menuRoute.push({})}
        title={title}
        icon={<StoreIcon />}
        width="sm"
    >
        {children}
    </ResponsiveFullscreenDialog>
    );
}

export function CompanyInfoDialog() {

    const classes = useStyles();

    const {
        data: company,
        isLoading: companyLoading
    } = useGetCompanyFullInfo();

    const whatsAppPhone = useMemo(() => {
        if (companyLoading || !company || company.whatsapp_phone_id === null)
            return null;
        return company.phones.find(p => p.id === company.whatsapp_phone_id) || null;
    }, [ company, companyLoading ]);

    const mainPhone = useMemo(() => {
        if (companyLoading || !company || company.main_phone_id === null || company.main_phone_id === company.whatsapp_phone_id)
            return null;
        return company.phones.find(p => p.id === company.main_phone_id) || null;
    }, [ company, companyLoading ]);

    const otherPhones = useMemo(() => {
        if (companyLoading || !company)
            return [];
        return company.phones.filter(p => p.id !== company.main_phone_id && p.id !== company.whatsapp_phone_id);
    }, [ company, companyLoading ]);

    const mainAddress = useMemo(() => {
        if (companyLoading || !company || company.main_address_id === null)
            return null;
        return company.addresses.find(a => a.id === company.main_address_id) || null;
    }, [ company, companyLoading ]);

    const otherAddresses = useMemo(() => {
        if (companyLoading || !company)
            return [];
        return company.addresses.filter(a => a.id !== company.main_address_id);
    }, [ company, companyLoading ]);

    const mainEmail = useMemo(() => {
        if (companyLoading || !company || company.main_email_id === null)
            return null;
        return company.emails.find(e => e.id === company.main_email_id) || null;
    }, [ company, companyLoading ]);

    const otherEmails = useMemo(() => {
        if (companyLoading || !company)
            return [];
        return company.emails.filter(e => e.id !== company.main_email_id);
    }, [ company, companyLoading ]);

    if (companyLoading || !company) {
        return (
        <Dialog title={"Informações da empresa"}>
            <CompanyInfoDialogPlaceholder />
        </Dialog>
        );
    }

    return (
    <Dialog title={company.name}>
        {company.description.length > 0 && (
        <>
            <Typography
                variant="h5"
                className={classes.companyInfoTypography}
            >
                A empresa
            </Typography>
            <Divider variant="fullWidth" />
            <Typography className={classes.companyInfoTypography}>
                {company.description}
            </Typography>
        </>
        )}
        <Typography
            variant="h5"
            className={classes.companyInfoTypography}
        >
            Informações da empresa
        </Typography>
        <Divider variant="fullWidth" />
        <List>
            {company.min_order_price > 0 && (
            <ListItem>
                <ListItemAvatar>
                    <Avatar className={classes.companyInfoDialogAvatar}>
                        <AttachMoneyIcon />
                    </Avatar>
                </ListItemAvatar>
                <ListItemText
                    primary={formatMoney(company.min_order_price)}
                    secondary={"Pedido mínimo"}
                />
            </ListItem>
            )}
            {company.delivery_tax > 0 &&
                company.delivery_methods.includes(DeliveryMethod.DELIVERY) && (
            <ListItem>
                <ListItemAvatar>
                    <Avatar className={classes.companyInfoDialogAvatar}>
                        <MotorcycleIcon />
                    </Avatar>
                </ListItemAvatar>
                <ListItemText
                    primary={formatMoney(company.delivery_tax)}
                    secondary={"Taxa de entrega"}
                />
            </ListItem>
            )}
            {whatsAppPhone !== null && (
            <Tooltip title={"Conversar com a loja no WhatsApp"}>
                <ListItem button onClick={() => sendWhatsAppMessage(whatsAppPhone.phone)}>
                    <ListItemAvatar>
                        <Avatar className={classes.companyInfoDialogAvatar}>
                            <WhatsAppIcon />
                        </Avatar>
                    </ListItemAvatar>
                    <ListItemText
                        primary={formatPhone(whatsAppPhone.phone)}
                        secondary={"Telefone WhatsApp"}
                    />
                    <ListItemSecondaryAction>
                        <ChatBubbleOutlineIcon />
                    </ListItemSecondaryAction>
                </ListItem>
            </Tooltip>
            )}
            {mainPhone !== null && (
            <ListItem
                button={mainPhone.whatsapp as any}
                onClick={!mainPhone.whatsapp ? undefined : () => sendWhatsAppMessage(mainPhone.phone)}
            >
                <ListItemAvatar>
                    <Avatar className={classes.companyInfoDialogAvatar}>
                        <PhoneIcon />
                    </Avatar>
                </ListItemAvatar>
                <ListItemText
                    primary={formatPhone(mainPhone.phone)}
                    secondary={mainPhone.name}
                />
                {mainPhone.whatsapp && (
                <ListItemSecondaryAction>
                    <ChatBubbleOutlineIcon />
                </ListItemSecondaryAction>
                )}
            </ListItem>
            )}
            {mainAddress !== null && (
            <ListItem>
                <ListItemAvatar>
                    <Avatar className={classes.companyInfoDialogAvatar}>
                        <AddressIcon />
                    </Avatar>
                </ListItemAvatar>
                <ListItemText
                    primary={formatAddress(
                        mainAddress.street,
                        mainAddress.number,
                    )}
                    secondary={formatAddress(
                        undefined,
                        undefined,
                        mainAddress.district,
                        mainAddress.city,
                        mainAddress.state,
                        mainAddress.complement,
                        mainAddress.zipcode
                    )}
                />
            </ListItem>
            )}
            {mainEmail !== null && (
            <ListItem>
                <ListItemAvatar>
                    <Avatar className={classes.companyInfoDialogAvatar}>
                        <MailIcon />
                    </Avatar>
                </ListItemAvatar>
                <ListItemText
                    primary={mainEmail.email}
                    secondary={mainEmail.name}
                />
            </ListItem>
            )}
        </List>
        {otherPhones.length > 0 && (
        <>
            <Typography
                variant="h5"
                className={classes.companyInfoTypography}
            >
                Outros telefones
            </Typography>
            <Divider variant="fullWidth" />
            <List>
                {otherPhones.map((p, index) => (
                <ListItem
                    key={index}
                    button={p.whatsapp as any}
                    onClick={!p.whatsapp ? undefined : () => sendWhatsAppMessage(p.phone)}
                >
                    <ListItemAvatar>
                        <Avatar className={classes.companyInfoDialogAvatar}>
                            {p.whatsapp ? <WhatsAppIcon /> : <PhoneIcon />}
                        </Avatar>
                    </ListItemAvatar>
                    <ListItemText
                        primary={formatPhone(p.phone)}
                        secondary={p.name}
                    />
                    {p.whatsapp && (
                    <ListItemSecondaryAction>
                        <ChatBubbleOutlineIcon />
                    </ListItemSecondaryAction>
                    )}
                </ListItem>
                ))}
            </List>
        </>
        )}
        {otherAddresses.length > 0 && (
        <>
            <Typography
                variant="h5"
                className={classes.companyInfoTypography}
            >
                Outros endereços
            </Typography>
            <Divider variant="fullWidth" />
            <List>
                {otherAddresses.map((a, index) => (
                <ListItem key={index}>
                    <ListItemAvatar>
                        <Avatar className={classes.companyInfoDialogAvatar}>
                            <MailIcon />
                        </Avatar>
                    </ListItemAvatar>
                    <ListItemText
                        primary={formatAddress(
                            a.street,
                            a.number,
                        )}
                        secondary={formatAddress(
                            undefined,
                            undefined,
                            a.district,
                            a.city,
                            a.state,
                            a.complement,
                            a.zipcode
                        )}
                    />
                </ListItem>
                ))}
            </List>
        </>
        )}
        {otherEmails.length > 0 && (
        <>
            <Typography
                variant="h5"
                className={classes.companyInfoTypography}
            >
                Outros e-mails
            </Typography>
            <Divider variant="fullWidth" />
            <List>
                {otherEmails.map((e, index) => (
                <ListItem key={index}>
                    <ListItemAvatar>
                        <Avatar className={classes.companyInfoDialogAvatar}>
                            <MailIcon />
                        </Avatar>
                    </ListItemAvatar>
                    <ListItemText
                        primary={e.email}
                        secondary={e.name}
                    />
                </ListItem>
                ))}
            </List>
        </>
        )}
    </Dialog>
    );
}

export function CompanyInfoDialogPlaceholder() {

    const classes = useStyles();

    return (
    <>
        <Typography
            variant="h5"
            className={classes.companyInfoTypography}
        >
            <CharactersPlaceholder n={12} />
        </Typography>
        <Typography>
            <ParagraphPlaceholder lines={8} />
        </Typography>
        <Typography
            variant="h5"
            className={classes.companyInfoTypography}
        >
            <CharactersPlaceholder n={22} />
        </Typography>
        <Divider variant="fullWidth" />
        <List>
            <Repeat n={5}>
                <ListItemPlaceholder avatar secondaryLines={1} />
            </Repeat>
        </List>
        <Repeat n={2}>
            <Typography
                variant="h5"
                className={classes.companyInfoTypography}
            >
                <CharactersPlaceholder n={18} />
            </Typography>
            <Divider variant="fullWidth" />
            <List>
                <Repeat n={2}>
                    <ListItemPlaceholder avatar secondaryLines={1} />
                </Repeat>
            </List>
        </Repeat>
    </>
    );
}