
function getDefaultMessage(status : number) {
    switch(status){
        case 500:
            return "Erro interno do servidor";
        case 400:
            return "As informações fornecidas são inválidas";
        case 401:
            return "Você precisa estar autenticado";
        case 403:
            return "Você não ter permissão";
        default:
            return "Erro interno do servidor"
    }
}

export type HTTPErrorConfig = {
    response?: Response;
    offline?: boolean;
    body?: any;
    fetch?: boolean;
    error?: any;
    message?: string;
}

export default class HTTPError<ReqVars> {
    validationErrors: { [K in keyof ReqVars]?: string};
    offline: boolean;
    fetch: boolean;
    code: number;
    message: string;
    error?: any;

    constructor(config: HTTPErrorConfig) {
        this.validationErrors = {};
        this.code = 0;
        this.message = config.message || "";
        this.error = config.error;

        this.offline = config.offline !== undefined && config.offline === true;
        if (this.offline) {
            this.message = "Você está offline";
        }

        this.fetch = config.fetch !== undefined && config.fetch === true;
        if (this.fetch) {
            this.message = "Verifique sua rede";
        }

        if (config.response !== undefined) {
            this.code = config.response.status;
            if (config.body) {
                if (config.body.message) {
                    this.message = config.body.message
                }
                if (this.code === 400 && config.body.validation_errors) {
                    this.validationErrors = config.body.validation_errors;
                    if (this.message === "") {
                        for (const key in this.validationErrors) {
                            if (this.validationErrors[key] !== undefined && (
                                    typeof this.validationErrors[key] === 'string' ||
                                    this.validationErrors[key] instanceof String
                            )) {
                                this.message = this.validationErrors[key] as string;
                                break;
                            }
                        }
                    }
                }
            }
        }

        if (this.message === "") {
            this.message = getDefaultMessage(this.code)
        }
    }

}