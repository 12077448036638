import { UseQueryOptions } from "react-query";
import {
    useQuery as rqUseQuery,
    useMutation as rqUseMutation,
    UseMutationOptions,
    UseMutationResult,
} from 'react-query';
import useFeedback from '../providers/Feedback';
import useErrorHandler from '../providers/ErrorHandler';
import HTTPError from '../types/httperror';
import PathPlaceholders from '../types/path';
import useOffline from '../providers/Offline';
import { useSession } from '../providers/Session';
import { useCompany } from "../providers/Company";
import { getURLAddress } from "../utils/url";

export type Method = "POST" | "PUT" | "DELETE" | "GET";

export type MutationResult<Vars, RespBody> = UseMutationResult<RespBody, HTTPError<Vars>, Vars, any>;
export type MutationOptions<Vars, RespBody> = Pick<UseMutationOptions<RespBody, HTTPError<Vars>, Vars, any>, 'onMutate' | 'onSuccess' | 'onError' | 'onSettled'>

export type ContentType = 'application/json' | 'multipart/form-data'; 


function apiRequest<ReqBody, RespBody extends (FormData | any)>(
    url: string,
    method: Method,
    token: string | null,
    body?: ReqBody,
    contentType: ContentType = 'application/json'
) {
    return new Promise<RespBody>((resolve, reject) => {
        if (!navigator.onLine) {
            reject(new HTTPError<ReqBody>({ offline: true }))
            return
        }

        if (contentType === 'multipart/form-data' && !(body instanceof FormData)) {
            reject(new HTTPError<ReqBody>({
                fetch: true,
                error: "content-type is multipart/form-data but body isn't FormData",
            }))
            return
        }

        let processedBody: string | FormData | undefined = undefined;
        if (body && method !== 'GET') {
            if (body instanceof FormData) {
                processedBody = body
            } else {
                processedBody = JSON.stringify(body);
            }
        }


        fetch(`${getURLAddress()}/api${url}`, {
            method: method,
            credentials: "include",
            headers: new Headers({
                'Accept': 'application/json',
                ...(contentType === 'multipart/form-data' ? {} : { 'Content-Type': contentType }),
                ...(token === null ? {} : { Authorization: `Bearer ${token}`})
            }),
            ...(processedBody && { body: processedBody })
        }).then(response => (
            response.text().then((text: string) => {
                if (text) {
                    const parsedResponse = JSON.parse(text)
                    if (!response.ok) {
                        reject(new HTTPError<ReqBody>({
                            response,
                            body: parsedResponse
                        }));
                        return;
                    }
                    return parsedResponse;
                } else {
                    if (!response.ok) {
                        reject(new HTTPError<ReqBody>({
                            response,
                            body: undefined
                        }));
                        return;
                    }
                    return undefined;
                }
            }).then(json => resolve(json))
        )).catch(err => reject(new HTTPError<ReqBody>({
            fetch: true,
            error: err,
        })))
    })
}

type CompanyID = { company_id: string };

// USE MUTATION
export function useMutation<Vars, RespBody>(
    method: Method,
    info: (vars: Vars) => { url: string, body?: Partial<Vars>, contentType?: ContentType },
    options?: MutationOptions<Vars, RespBody>
) : MutationResult<Vars, RespBody> {

    const { report } = useErrorHandler();
    const { successPopup } = useFeedback()
    const { token } = useSession();


    return rqUseMutation<RespBody, HTTPError<Vars>, Vars, any>(
        data => {
            const reqInfo = info(data);
            return apiRequest(reqInfo.url, method, token, reqInfo.body, reqInfo.contentType)
        }, {
            onSuccess: (data, vars, context) => {
                if (data !== undefined && (data as any).message !== undefined) {
                    successPopup({ message: (data as any).message });
                }
                if (options?.onSuccess)
                    options.onSuccess(data, vars, context)
            },
            onError: (err, vars, ctx) => {
                report(err)
                if (options?.onError)
                    options.onError(err, vars, ctx)
            },
            onSettled: (data, err, vars, ctx) => {
                if (options?.onSettled)
                    options.onSettled(data, err, vars, ctx)
            },
            onMutate: vars => {
                if (options?.onMutate)
                    options.onMutate(vars)
            }
        }
    )
};

export function useMutationWithCompany<Vars, RespBody>(
    method: Method,
    info: (vars: Vars, company_id: string) => { url: string, body?: Partial<Vars>, contentType?: ContentType },
    options?: MutationOptions<Vars & CompanyID, RespBody>
) : MutationResult<Vars, RespBody> {

    const { report } = useErrorHandler();
    const { successPopup } = useFeedback()
    const { companyID } = useCompany();
    const { token } = useSession();

    if (companyID === null) {
        let err = new HTTPError({
            message: "Erro interno no aplicativo",
            error: new Error("company not defined on required mutation")
        })
        report(err);
        throw err;
    }

    return rqUseMutation<RespBody, HTTPError<Vars & CompanyID>, Vars, any>(
        data => {
            const reqInfo = info(data, companyID || '');
            return apiRequest(reqInfo.url, method, token, reqInfo.body, reqInfo.contentType)
        }, {
            onSuccess: (data, vars, context) => {
                if (data !== undefined && (data as any).message !== undefined) {
                    successPopup({
                        message: (data as any).message
                    });
                }
                if (options?.onSuccess)
                    options.onSuccess(
                        data,
                        { ...vars, company_id: companyID || '' },
                        context
                    )
            },
            onError: (err, vars, ctx) => {
                report(err)
                if (options?.onError)
                    options.onError(
                        err,
                        { ...vars, company_id: companyID || '' },
                        ctx
                    )
            },
            onSettled: (data, err, vars, ctx) => {
                if (options?.onSettled)
                    options.onSettled(
                        data,
                        err,
                        { ...vars, company_id: companyID || '' },
                        ctx
                    )
            },
            onMutate: vars => {
                if (options?.onMutate)
                    options.onMutate({ ...vars, company_id: companyID || '' })
            }
        }
    )
};


type QueryParams = Record<string, string | number | boolean>;
type QueryParamsWithoutCompany = Record<Exclude<string, 'company_id'>, string | number | boolean>;

function parseURLParams(url: string, params: QueryParams): string {
    if (params) {
        for (let paramName in params) {
            url = url.replace(`:${paramName}`, params[paramName].toString());
        }
    }
    return url;
}

type QueryInvalidate<T extends QueryParams> = { url: string, params?: Partial<T> }

// eslint-disable-next-line
type Query<T extends QueryParams, RespBody> = {
    url: string;
    parse: (params: T) => string;
    invalidate: (params?: Partial<T>) => QueryInvalidate<T>;
    open: boolean;
}

export function newQuery<U extends string >(url: U, options?: { open: boolean; }) {
    return function<
        Params extends Record<PathPlaceholders<U>, number|string|boolean>,
        RespBody
    >(): Query<Params, RespBody> {
        return {
            url,
            parse: (params: Params) => parseURLParams(url, params),
            invalidate: (params?: Partial<Params>) => ({ url, params }),
            open: options === undefined ? false : options.open
        }
    }   
}

export function newCompanyQuery<U extends `${string}/:company_id${string}`>(url: U, options?: { open: boolean }) {
    return function<
        Params extends Record<Exclude<PathPlaceholders<U>, 'company_id'>, number | string | boolean>,
        RespBody
    >(): Query<Params & CompanyID, RespBody> {
        return {
            url,
            parse: (params: Params & CompanyID) => parseURLParams(url, params),
            invalidate: (params?: Partial<Params & CompanyID>) => ({ url, params }),
            open: options === undefined ? false : options.open
        }
    }
}

// USE QUERY
export function useQuery<Params extends QueryParams, RespBody>(
    query: Query<Params, RespBody>,
    params: Params,
    options?: UseQueryOptions<unknown, HTTPError<Params>, RespBody>
) {
    const { report } = useErrorHandler();
    const { loggedIn, token } = useSession();
    const { isOffline } = useOffline();

    return rqUseQuery<unknown, HTTPError<Params>, RespBody>(
        [ query.url, params ],
        () => {
            return apiRequest<void, RespBody>(query.parse(params), 'GET', token)
        }, {
            enabled: !isOffline && (query.open || loggedIn) && options?.enabled,
            onSuccess: data => {
                if (options?.onSuccess)
                    options.onSuccess(data)
            },
            onError: err => {
                report(err)
                if (options?.onError)
                    options.onError(err)
            },
            onSettled: (data, err) => {
                if (options?.onSettled)
                    options.onSettled(data, err)
            },
        }
    )
}

export function useCompanyQuery<
    Params extends QueryParamsWithoutCompany,
    RespBody
>(
    query: Query<Params & CompanyID, RespBody>,
    params: Params,
    options?: UseQueryOptions<unknown, HTTPError<Params & CompanyID>, RespBody>
) {
    const { companyID } = useCompany();
    return useQuery(query, { ...params, company_id: companyID || '' }, {
        enabled: companyID !== null && options?.enabled,
        ...options
    })
}
