import {
    AppBar,
    Dialog,
    Divider,
    IconButton,
    makeStyles,
    Slide,
    Grow,
    Toolbar,
    Typography,
    useMediaQuery,
    useTheme
} from "@material-ui/core";
import ArrowBackIosIcon from '@material-ui/icons/ArrowBackIos';
import CloseIcon from '@material-ui/icons/Close';
import clsx from "clsx";
import React, { forwardRef, ReactNode, UIEvent } from "react";
import { TransitionProps } from '@material-ui/core/transitions';

const SmallScreenTransition = forwardRef(function SmallScreenTransition(
    props: TransitionProps & { children?: React.ReactElement<any, any> },
    ref: React.Ref<unknown>,
) {
    return <Slide direction="right" ref={ref} {...props} />;
});

const LargeScreenTransition = forwardRef(function LargeScreenTransition(
    props: TransitionProps & { children?: React.ReactElement<any, any> },
    ref: React.Ref<unknown>,
) {
    return <Grow ref={ref} {...props} />;
});


const useStyles = makeStyles(theme => ({
    responsiveFullscreenDialogTitle: {
        flexGrow: 1,
        textOverflow: 'ellipsis',
        whiteSpace: 'nowrap',
        overflow: 'hidden'
    },
    responsiveFullscreenDialogIcon: {
        marginRight: theme.spacing(1),
        display: 'flex',
        justifyContent: 'center',
    },
    responsiveFullscreenDialogBackButtonDivider: {
        marginRight: theme.spacing(2)
    },
    responsiveFullscreenDialogWithPadding: {
        padding: theme.spacing(2)
    },
    responsiveFullscreenDialogContent: {
        maxHeight: '100%',
        overflowY: 'auto',
        overflowX: 'hidden',
    },
    responsiveFullscreenDialogContentLargePaddingBottom: {
        paddingBottom: `50vh`
    },
    responsiveFullscreenDialogContentSmallPaddingBottom: {
        paddingBottom: theme.spacing(5)
    },
    responsiveFullscreenDialogValueContainer: {
        backgroundColor: theme.palette.background.paper,
        boxShadow: theme.shadows[3],
        borderTop: `1px solid ${theme.palette.divider}`,
        zIndex: theme.zIndex.modal+1,
        display: 'flex',
        flexDirection: 'row',
        padding: theme.spacing(1)
    },
    responsiveFullscreenDialogContentWithValueContainer: {
        flexGrow: 1,
        height: '100%',
        overflowX: 'hidden',
        overflowY: 'auto',
    }
}));

type ResponsiveFullscreenDialogProps = {
    open: boolean;
    onClose?: () => void;
    children: ReactNode | JSX.Element | JSX.Element[] | undefined;
    title: string;
    icon: JSX.Element;
    width?: 'xs' | 'sm' | 'md' | 'lg';
    withPadding?: boolean;
    bottomContent?: JSX.Element | JSX.Element[] | false;
    onExited?: () => void;
    onScroll?: (e: UIEvent<HTMLDivElement>) => void;
}
export const ResponsiveFullscreenDialog = forwardRef(({
    open,
    onClose,
    children,
    title,
    icon,
    width = 'md',
    withPadding,
    bottomContent,
    onExited,
    onScroll
}: ResponsiveFullscreenDialogProps, ref: React.Ref<HTMLDivElement>) => {

    const classes = useStyles();

    const theme = useTheme();
    const largeScreen = useMediaQuery(theme.breakpoints.up(width));

    return (
    <Dialog
        open={open}
        onClose={onClose}
        fullScreen={!largeScreen}
        fullWidth={largeScreen ? true : undefined}
        maxWidth={largeScreen ? width : undefined}
        onExited={onExited}
        TransitionComponent={largeScreen ? LargeScreenTransition : SmallScreenTransition}
    >
        <AppBar
            position="static"
            color="primary"
        >
            <Toolbar>
                {!largeScreen && onClose && (
                <>
                    <IconButton
                        onClick={onClose}
                        color="inherit"
                    >
                        <ArrowBackIosIcon />
                    </IconButton>
                    <Divider
                        orientation="vertical"
                        className={classes.responsiveFullscreenDialogBackButtonDivider}
                    />
                </>
                )}
                <div className={classes.responsiveFullscreenDialogIcon}>
                    {icon}
                </div>
                <Typography
                    variant="h6"
                    className={classes.responsiveFullscreenDialogTitle}
                >
                    {title}
                </Typography>
                {largeScreen && onClose && (
                <IconButton
                    onClick={onClose}
                    color="inherit"
                >
                    <CloseIcon />
                </IconButton>
                )}
            </Toolbar>
        </AppBar>
        {bottomContent ? (
        <>
            <div
                ref={ref}
                onScroll={onScroll}
                className={clsx(
                    classes.responsiveFullscreenDialogContentWithValueContainer,
                    withPadding && classes.responsiveFullscreenDialogWithPadding,
                    !largeScreen ?
                        classes.responsiveFullscreenDialogContentLargePaddingBottom :
                        classes.responsiveFullscreenDialogContentSmallPaddingBottom
                )}
            >
                {children}
            </div>
            <div className={classes.responsiveFullscreenDialogValueContainer}>
                {bottomContent}
            </div>
        </>
        ) : (
            <div
                ref={ref}
                onScroll={onScroll}
                className={clsx(
                    classes.responsiveFullscreenDialogContent,
                    withPadding && classes.responsiveFullscreenDialogWithPadding,
                    !largeScreen ?
                        classes.responsiveFullscreenDialogContentLargePaddingBottom :
                        classes.responsiveFullscreenDialogContentSmallPaddingBottom
                )}
            >
                {children}
            </div>
        )}
    </Dialog>
    );
});